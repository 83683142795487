import React, { Fragment } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getTrustedAccessDevice } from '_redux/devices';
import { getTrustedAccessConfigIds } from '_redux/trustedAccessConfigs';
import { TrustedAccessProfileCard } from './_components/TrustedAccessProfileCard';
import { DeviceName } from 'portal-dashboard/devices/_components/DeviceName';
import { EditDeviceButton } from './_components/EditDeviceButton';
import { DeleteDeviceButton } from './_components/DeleteDeviceButton';
import { Helmet } from 'react-helmet';
import { SYSTEM_TYPES } from '_utils/deviceUtils';

export const TrustedAccessDevicePage = ({ match }) => {
  const { deviceId, networkId, userId } = match.params;

  const device = useSelector(state => getTrustedAccessDevice(state, deviceId));
  const profileIds = useSelector(getTrustedAccessConfigIds);

  const { name, trusted_access_connections, system_type } = device;

  const installedConnections = trusted_access_connections.filter(
    conn =>
      !!conn.scep_completed_at ||
      (device.system_type === SYSTEM_TYPES.ANDROID_PASSPOINT && !!conn.downloaded_at),
  );
  const installedConfigIds = installedConnections.map(conn => conn.trusted_access_config_id);
  const unusedProfileIds = profileIds.filter(id => !installedConfigIds.includes(id));
  const isBrowserWindows = device.system_type === SYSTEM_TYPES.WINDOWS;

  return (
    <Fragment>
      <Helmet>
        <title>Devices - SSP</title>
      </Helmet>
      <Container className="py-4">
        <Row className="justify-content-between align-items-center mb-4">
          <Col>
            <DeviceName type={system_type}>{name}</DeviceName>
          </Col>
          <Col>
            <Row className="justify-content-end text-right">
              <EditDeviceButton deviceId={deviceId} name={name} />
              <DeleteDeviceButton deviceId={deviceId} />
            </Row>
          </Col>
        </Row>
        {isBrowserWindows && (
          <Row className="mb-4">
            <Alert variant="warning" className="w-100 mx-3">
              To download, renew, or remove Trusted Access profiles, use the Windows Trusted Access
              app installed on this device.
            </Alert>
          </Row>
        )}
        <Row>
          {profileIds.length === 0 && (
            <Alert variant="warning" className="w-100 mx-3">
              No available Wi-Fi networks. Contact your network administrator.
            </Alert>
          )}
          {installedConnections.map(connection => (
            <TrustedAccessProfileCard
              key={connection.trusted_access_config_id}
              connection={connection}
              deviceId={deviceId}
              configId={connection.trusted_access_config_id}
              networkId={networkId}
              userId={userId}
            />
          ))}
          {unusedProfileIds.map(id => (
            <TrustedAccessProfileCard
              key={id}
              deviceId={deviceId}
              configId={id}
              networkId={networkId}
              userId={userId}
            />
          ))}
        </Row>
      </Container>
    </Fragment>
  );
};
