import React from 'react';

export const PrivacyPolicyNotice = () => {
  return (
    <p>
      By enrolling in Systems Manager you acknowledge that you have read and understood the terms of
      our{' '}
      <a href={'https://meraki.cisco.com/meraki-support/policies/#privacy-policy'}>
        Privacy Policy
      </a>
      .
    </p>
  );
};
