import React, { Fragment, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ManagedDevicePaneTabs } from './ManagedDevicePaneTabs';
import { DeviceName } from '../../_components/DeviceName';
import { SummaryRoot } from './summary';
import { ToolsRoot } from './tools';
import { AppsRoot } from './apps';
import { DeviceTypes } from '_redux/constants';
import { fetchManagedDevice, getManagedDevice, getManagedDeviceFriendlyName } from '_redux/devices';
import { Helmet } from 'react-helmet';

const MdmDeviceName = ({ id }) => {
  const { system_type } = useSelector(state => getManagedDevice(state, id));
  const name = useSelector(state => getManagedDeviceFriendlyName(state, id));
  return <DeviceName type={system_type}>{name}</DeviceName>;
};

export const MdmDeviceRoot = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchManagedDevice(match.params.deviceId));
  }, [dispatch, match.params.deviceId]);

  const device = useSelector(state => getManagedDevice(state, match.params.deviceId));

  if (!device) return <Redirect to={`/${match.params.networkId}/${match.params.userId}/devices`} />;
  if (!device.complete) return null; // new device, hasn't finished fetching full record

  const baseUrl = `/${match.params.networkId}/${match.params.userId}`;
  const deviceUrl = `${baseUrl}/devices/${DeviceTypes.MDM}/${match.params.deviceId}`;
  const devicePath = `/:networkId/:userId/devices/${DeviceTypes.MDM}/:deviceId`;
  return (
    <Fragment>
      <Helmet>
        <title>Devices - SSP</title>
      </Helmet>
      <Container className="py-4">
        <Row className="mb-4 mx-1">
          <MdmDeviceName id={match.params.deviceId} />
        </Row>
        <ManagedDevicePaneTabs deviceUrl={deviceUrl} />
        <Switch>
          <Redirect exact from={devicePath} to={`${deviceUrl}/summary`} />
          <Route path={`${devicePath}/summary`} component={SummaryRoot} />
          <Route path={`${devicePath}/tools`} component={ToolsRoot} />
          <Route path={`${devicePath}/apps`} component={AppsRoot} />
        </Switch>
      </Container>
    </Fragment>
  );
};
