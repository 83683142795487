import React from 'react';
import { useMutation } from 'react-query';
import sspApi from '_utils/network';

import { LoadingSpinnerFullscreen } from '_components/loadingSpinner/LoadingSpinner';
import { LoginError } from 'shared/login/LoginError';
import { RegularLoginForm } from 'shared/login/RegularLoginForm';

import { RedirectToPlatformEnrollmentPage } from './RedirectToPlatformEnrollmentPage';

export function EnrollmentRegularLogin({ authType, enrollmentCode }) {
  const searchParams = new URLSearchParams(window.location.search);
  const pcc_machine_id = searchParams.get('pcc_machine_id');

  const loginMutation = useMutation(
    'login',
    async ({ email, password }: { email: string; password: string }) => {
      const response = await sspApi.post(`${enrollmentCode}/login`, {
        body: new URLSearchParams({
          email,
          password,
          access_type: 'enrollment',
        }),
        headers: { accept: 'application/json' },
      });
      const responseBody = await response.json();
      if (response.status !== 200)
        throw new Error(responseBody.error || 'Login failed due to unknown server error');
      return responseBody;
    },
  );
  if (loginMutation.isLoading) {
    return <LoadingSpinnerFullscreen show />;
  } else if (loginMutation.isSuccess) {
    if (Boolean(pcc_machine_id)) {
      window.location.assign(
        `${
          window.location.origin
        }/ssp/${enrollmentCode}/ade/profile?pcc_machine_id=${encodeURIComponent(pcc_machine_id)}`,
      );
      return null;
    } else {
      return (
        <RedirectToPlatformEnrollmentPage
          authToken={loginMutation.data.token}
          enrollmentCode={enrollmentCode}
        />
      );
    }
  } else {
    return (
      <React.Fragment>
        {loginMutation.isError && loginMutation.error && (
          // @ts-ignore
          <LoginError error={loginMutation.error.message} />
        )}
        <RegularLoginForm
          authType={authType}
          enrollmentCode={enrollmentCode}
          onSubmit={({ email, password }) => {
            loginMutation.mutate({ email, password });
          }}
        />
      </React.Fragment>
    );
  }
}
