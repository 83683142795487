import React from 'react';
import { Button } from 'react-bootstrap';

/*
 * Authentication flow:
 * 1. User clicks login button.
 * 2. Redirects to IdP login window.
 * 3. User authenticates with SAML IdP.
 * 4. Rails backend redirects to /loginsuccess route with saml_success param appended upon successful authentication and error_description if any.
 * 5. Continue with the login process from LoginSuccess component.
 */
export const SamlLoginForm = ({ authEndpoint }: { authEndpoint: string }) => {
  function handleLoginButtonClick(event) {
    event.preventDefault();
    window.location.assign(authEndpoint);
  }

  return (
    <Button
      block
      variant="primary"
      type="submit"
      onClick={handleLoginButtonClick}
      data-testid="login"
    >
      <span>Log in with SAML</span>
    </Button>
  );
};
