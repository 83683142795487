import React, { useState } from 'react';
import { getWindowsEnrollmentUrls } from 'enroll/api';
import { Button } from 'react-bootstrap';
import SMLogo from 'images/SM_logo@2x.png';
import { Page } from 'enroll/_components/Page';
import { WaitWithSpinner } from 'enroll/_components/WaitWithSpinner';
import { useLocation } from 'react-router-dom';

export const WindowsEnrollmentPage = ({ match, history }) => {
  const isWindows10 = /Windows NT 10.0/.test(navigator.userAgent);
  const [shardUrl, setShardUrl] = useState('');
  const [agentUrl, setAgentUrl] = useState('');
  const searchParams = new URLSearchParams(useLocation().search);

  if (!agentUrl) {
    return (
      <WaitWithSpinner
        asyncFn={getWindowsEnrollmentUrls(match.params.networkId)}
        onSuccess={({ agent_url, shard_url }) => {
          setAgentUrl(agent_url);
          setShardUrl(shard_url);
        }}
        onFail={(error: Error) => {
          if (error.message === 'Not logged in') {
            history.push(`/${match.params.networkId}`);
          } else {
            history.push('/error', {
              error: 'An error occurred when fetching Windows enrollment info. Please try again.',
            });
          }
        }}
      />
    );
  } else {
    const agentUrlParams = new URLSearchParams();
    if (searchParams.get('wifi_mac')) {
      agentUrlParams.append('wifi_mac', searchParams.get('wifi_mac'));
    }
    if (searchParams.get('wired_mac')) {
      agentUrlParams.append('wired_mac', searchParams.get('wired_mac'));
    }
    let agentUrlWithParams = agentUrl;
    if (agentUrlParams.toString() !== '') {
      agentUrlWithParams += `?${agentUrlParams.toString()}`;
    }
    return (
      <Page
        title="Instructions: Systems Manager enrollment"
        header="How to enroll your machine"
        iconSrc={SMLogo}
      >
        {isWindows10 && <h3>1. Install the Systems Manager agent</h3>}
        <p>
          Download the Meraki Systems Manager agent to allow your administrator to securely manage
          the settings on this machine.
        </p>
        <Button href={agentUrlWithParams}>Download</Button>
        <p>Click “Run” and then follow the prompts.</p>
        {isWindows10 && (
          <div>
            <h3>2. Add Workplace Settings</h3>
            <p>
              Open Workplace Settings. Enter your <b>company or school email address</b>.
            </p>
            <Button href={`ms-device-enrollment:?mode=mdm&servername=${shardUrl}`}>
              Open Workplace Settings
            </Button>
            <p>
              On the next screen, enter your Network ID and login again. Follow the prompts and then
              you are done.
            </p>
          </div>
        )}
      </Page>
    );
  }
};
