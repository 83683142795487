import React from 'react';
import { Route } from 'react-router-dom';
import { MdmDeviceRoot } from './deviceId';
import { DeviceTypes } from '_redux/constants';

export const MdmRoot = () => {
  return (
    <Route
      path={`/:networkId/:userId/devices/${DeviceTypes.MDM}/:deviceId`}
      component={MdmDeviceRoot}
    />
  );
};
