import React, { useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { WaitWithSpinner } from 'enroll/_components/WaitWithSpinner';
import { getAppleEnrollmentUrl } from 'enroll/api';
import { useLocation } from 'react-router-dom';
import MacInstruction from 'images/enrollment-screens/Mac-instructions-1@2x.png';
import IosInstruction from 'images/enrollment-screens/iOS-instructions-image1@2x.png';
import IosSetting from 'images/enrollment-screens/iOS-instructions-settings@2x.png';
import { sspApi } from '_utils/network';

/*
 * How to determine enrollment success:
 * 1. An enrolling token is generated in the /apple_enrollment_profile_url endpoint.
 * 2. The MDM profile returned by the profile download link includes the token.
 * 3. When the profile is installed on the device, the device checks in to our
 * SCEP server and a new PccMachine is created with its profile_mdm_id set to
 * the token.
 * 4. We poll /enrollment_status until it finds a PccMachine with the given
 * token or until we hit the maximum number of retries.
 */
export const MAX_ENROLLMENT_STATUS_RETRIES = 10;
export const CHECK_ENROLLMENT_STATUS_INTERVAL = 2000;
function pollEnrollmentStatus(enrollmentCode, enrollingToken, onEnrollmentSuccess) {
  let i = 0;
  const intervalId = setInterval(async () => {
    if (i++ === MAX_ENROLLMENT_STATUS_RETRIES) clearInterval(intervalId);
    try {
      const response = await sspApi.get(`${enrollmentCode}/enrollment_status`, {
        searchParams: new URLSearchParams({ enrolling_token: enrollingToken }),
      });
      const { is_enrolled } = await response.json();
      if (is_enrolled) {
        onEnrollmentSuccess();
      }
    } catch (e) {
      console.error(`An error occurred when fetching enrollment status: ${e.message}`);
    }
  }, CHECK_ENROLLMENT_STATUS_INTERVAL);
  return intervalId;
}

export const MdmProfileEnrollment = ({
  enrollmentCode,
  ios,
  mac,
  history,
}: {
  enrollmentCode: string;
  ios?: boolean;
  mac?: boolean;
  history: any;
}) => {
  const [profileUrl, setProfileUrl] = useState('');
  const [enrollingToken, setEnrollingToken] = useState('');
  const [downloadButtonClicked, setDownloadButtonClicked] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const searchParams = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (downloadButtonClicked && !intervalId) {
      setIntervalId(
        pollEnrollmentStatus(enrollmentCode, enrollingToken, () =>
          history.push(`/${enrollmentCode}/success`),
        ),
      );
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [downloadButtonClicked, intervalId, enrollmentCode, enrollingToken, history]);

  if (!profileUrl) {
    return (
      <WaitWithSpinner
        asyncFn={getAppleEnrollmentUrl(
          enrollmentCode,
          searchParams.get('user_enrollment') === 'true',
          searchParams.get('tags') || '',
        )}
        onSuccess={(data: { url: string; enrolling_token: string }) => {
          setProfileUrl(data.url);
          setEnrollingToken(data.enrolling_token);
        }}
        onFail={error => {
          if (error.message === 'Not logged in') {
            history.push(`/${enrollmentCode}`);
          } else {
            history.push('/error', { error: error.message });
          }
        }}
      />
    );
  } else {
    let profileUrlWithParams = profileUrl;
    if (searchParams.get('wifi_mac')) {
      profileUrlWithParams += `&wifi_mac=${searchParams.get('wifi_mac')}`;
    }
    if (searchParams.get('wired_mac')) {
      profileUrlWithParams += `&wired_mac=${searchParams.get('wired_mac')}`;
    }
    return (
      <React.Fragment>
        <h3>1. Download the configuration profile</h3>
        <p>
          A configuration profile allows your admin to securely manage the settings on this device.
        </p>
        <Button href={profileUrlWithParams} onClick={() => setDownloadButtonClicked(true)}>
          Download
        </Button>
        <h3>2. Install the profile</h3>
        {mac && (
          <React.Fragment>
            <p>
              Open the .mobileconfig file that was downloaded, then visit "System Settings → Privacy
              & Security → Profiles" to install the enrollment profile.
            </p>
            <Image fluid src={MacInstruction} />
          </React.Fragment>
        )}
        {ios && (
          <React.Fragment>
            <p>
              <Image className="mr-4" src={IosSetting} />
              Go to the Settings app and look for a "Profile Downloaded" area.
            </p>
            <Image className="my-3 w-50" src={IosInstruction} />
            <p>Click "Install" and follow the rest of the prompts. Then you are done!</p>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
};
