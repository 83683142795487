import React from 'react';
import { useSelector } from 'react-redux';

import { Icon } from '_components/Icon';
import {
  DeviceInfoTable,
  DeviceInfoTableRow,
} from 'portal-dashboard/devices/_components/DeviceInfoTable';
import { getManagedDevice } from '_redux/devices';

import { formatDate, formatOSName } from '_utils/deviceUtils';

export const DeviceInfo = props => {
  const {
    ssid,
    last_checkin_time,
    os_name,
    os_version,
    cpu_id,
    friendly_system_model,
  } = useSelector(state => getManagedDevice(state, props.id));

  if (props.id === undefined) return null;

  return (
    <DeviceInfoTable header="Basics">
      <DeviceInfoTableRow label="Connected to">
        <Icon glyph="wifi" title={ssid} /> {ssid}
      </DeviceInfoTableRow>
      <DeviceInfoTableRow label="Last check in">
        {formatDate(last_checkin_time, true)}
      </DeviceInfoTableRow>
      <DeviceInfoTableRow label="Model">{friendly_system_model}</DeviceInfoTableRow>
      <DeviceInfoTableRow label="Serial">{cpu_id}</DeviceInfoTableRow>
      <DeviceInfoTableRow label="OS version">{`${formatOSName(
        os_name,
      )} ${os_version}`}</DeviceInfoTableRow>
    </DeviceInfoTable>
  );
};
