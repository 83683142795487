import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Button, Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import {
  downloadMobileConfig,
  trustedAccessConfigDownloadErrorExists,
  getTrustedAccessConfigDownloadError,
} from '_redux/trustedAccessConfigs';

import { DeviceInstructions } from 'portal-dashboard/devices/trusted-access/deviceId/download/configId/_components/DeviceInstructions';
import {
  EnrollmentCard,
  EnrollmentCardHeader,
  EnrollmentCardBody,
  EnrollmentCardFooter,
  EnrollmentCardBodyText,
} from 'portal-dashboard/enroll/_components/EnrollmentCard';
import { SYSTEM_TYPES } from '_utils/deviceUtils';
import {
  currentDevicePlatform,
  currentDeviceBrowser,
} from 'enroll/networkId/_components/currentDevicePlatform';

const TrustedAccessDownloadError = {
  AlreadyDownloaded: 'Device has already downloaded this config',
  OutOfScope: 'User is not scoped for this mobile config',
};

export const TrustedAccessDownloadPage = ({ device, config, baseUrl }) => {
  const dispatch = useDispatch();

  const hasErrors = useSelector(trustedAccessConfigDownloadErrorExists);
  const error = useSelector(getTrustedAccessConfigDownloadError);
  const platform = currentDevicePlatform();
  const browser = currentDeviceBrowser();

  const incompatibleBrowser =
    device.system_type === SYSTEM_TYPES.IOS && platform === 'ios' && browser !== 'safari';

  const connection = device.trusted_access_connections.filter(
    conn => conn.trusted_access_config_id === config.id,
  )[0];
  const hasInstalled =
    !!connection &&
    (!!connection.scep_completed_at ||
      (device.system_type === SYSTEM_TYPES.ANDROID_PASSPOINT && !!connection.downloaded_at));

  let canDownload = true;
  let errorMessage;
  if (hasErrors) {
    if (error === TrustedAccessDownloadError.OutOfScope) {
      errorMessage = 'You do not have the proper permissions to download this profile.';
      canDownload = false;
    } else {
      errorMessage =
        'There was an error downloading this profile. Try re-downloading the profile or contact your network administrator.';
    }
  }

  useEffect(() => {
    if (canDownload && !hasInstalled && !incompatibleBrowser) {
      dispatch(downloadMobileConfig(device.id, config.id, 1500));
    }
  }, [dispatch, device, config, canDownload, hasInstalled, incompatibleBrowser]);

  return (
    <EnrollmentCard>
      <Helmet>
        <title>Get Wi-Fi access - SSP</title>
      </Helmet>
      <EnrollmentCardHeader>Get Wi-Fi access</EnrollmentCardHeader>
      <EnrollmentCardBody>
        {hasErrors && <Alert variant="danger">{errorMessage}</Alert>}
        {incompatibleBrowser && (
          <Alert variant="danger">
            Apple/iOS does not support this browser for configuration installs, please try
            downloading this profile again in Safari.
          </Alert>
        )}
        {hasInstalled && (
          <Alert variant="warning">
            It looks like you've already installed this profile. You should only re-install it if
            you are experiencing issues.
          </Alert>
        )}
        <EnrollmentCardBodyText>
          Installing this profile on your device will give you wireless network access.
        </EnrollmentCardBodyText>
        <DeviceInstructions
          systemType={device.system_type}
          profileName={config.payload_display_name}
        />
        <EnrollmentCardBodyText>
          After completing these steps, you should be able to connect to the Wi-Fi network{' '}
          <b>{config.ssid_name}</b> without authenticating.
        </EnrollmentCardBodyText>
        {canDownload && !incompatibleBrowser && (
          <span className="d-flex align-items-center">
            Having issues?
            <Button
              variant="link"
              onClick={() => dispatch(downloadMobileConfig(device.id, config.id))}
            >
              Re-download the profile
            </Button>
          </span>
        )}
      </EnrollmentCardBody>
      <EnrollmentCardFooter>
        <LinkContainer to={`${baseUrl}/${device.id}`}>
          <Button variant="link">Cancel</Button>
        </LinkContainer>
        <LinkContainer to={`${baseUrl}/${device.id}`}>
          <Button variant="primary" data-testid="doneBtn">
            Done
          </Button>
        </LinkContainer>
      </EnrollmentCardFooter>
    </EnrollmentCard>
  );
};
