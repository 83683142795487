import React from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { getTrustedAccessDevice } from '_redux/devices';
import { getTrustedAccessConfig } from '_redux/trustedAccessConfigs';
import { DeviceTypes } from '_redux/constants';
import { SYSTEM_TYPES } from '_utils/deviceUtils';
import { TrustedAccessDownloadPage } from './TrustedAccessDownloadPage';

export const TrustedAccessDownloadRoot = ({ match }) => {
  const deviceId = match.params.deviceId;
  const configId = match.params.configId;
  const baseUrl = `/${match.params.networkId}/${match.params.userId}`;

  const config = useSelector(state => getTrustedAccessConfig(state, configId));
  const device = useSelector(state => getTrustedAccessDevice(state, deviceId));

  if (!config || !device) return null;

  if (device.system_type === SYSTEM_TYPES.ANDROID) {
    return <Redirect to={`${baseUrl}/error`} />;
  }

  return (
    <TrustedAccessDownloadPage
      device={device}
      config={config}
      baseUrl={`${baseUrl}/devices/${DeviceTypes.TA}`}
    />
  );
};
