import React from 'react';
import { SYSTEM_TYPES } from '_utils/deviceUtils';
import { MacInstructions } from './MacInstructions';
import { IOSInstructions } from './IOSInstructions';

export const DeviceInstructions = ({ systemType, profileName }) => {
  switch (systemType) {
    case SYSTEM_TYPES.MAC:
      return <MacInstructions profileName={profileName} />;
    case SYSTEM_TYPES.IOS:
      return <IOSInstructions />;
    default:
      return null;
  }
};
