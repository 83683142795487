import { createSlice, createSelector } from 'redux-starter-kit';
import { SliceNames, NetworkKeys } from './constants';
import { startGet, endWithResponse, endRejected } from './network';
import { sspApi } from '_utils/network';

const initialState = { user_access_devices: {}, managed_devices: {} };

const enrollSlice = createSlice({
  slice: SliceNames.Enroll,
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    resetAndInsert: (_state, { payload }) => ({ ...initialState, ...payload }),
  },
});

export default enrollSlice.reducer;

// actions
export const { resetAndInsert, reset } = enrollSlice.actions;

export const fetchEnrollPermissions = () => async dispatch => {
  const key = NetworkKeys.FetchEnrollPermissions;
  dispatch({ ...startGet(key, true), ...reset() });

  try {
    const response = await sspApi.get(`devices/enrollment_permissions`, {
      headers: {
        accept: 'application/json',
      },
    });
    const defaultAction = endWithResponse(key, response);

    if (response.ok) {
      const data = await response.json();
      dispatch({ ...defaultAction, ...resetAndInsert(data) });
    } else {
      dispatch(defaultAction);
    }
  } catch (e) {
    dispatch(endRejected(key, e)); // TODO: what do we do if rejected?
  }
};

// selectors
export const getEnrollStatus = state => state[SliceNames.Enroll];

export const getEnrollAvailability = createSelector([getEnrollStatus], slice => {
  // @ts-ignore
  return Object.entries(slice).reduce((obj, [k, { limit = 0, count = 0 }]) => {
    obj[k] = limit - count;
    return obj;
  }, {});
});

export const getHasSomeEnrollAvailability = createSelector([getEnrollAvailability], enrollTypes => {
  return Object.values(enrollTypes).some(availability => availability > 0);
});
