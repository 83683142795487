import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import warningImage from 'images/warning.svg';
import { Helmet } from 'react-helmet';

export const DisclaimerPage = () => {
  return (
    <Container className="my-5 px-auto">
      <Helmet>
        <title>Disclaimer - SSP</title>
      </Helmet>
      <Row className="justify-content-center text-center">
        <Col xs={4} md={2}>
          <Image src={warningImage} />
        </Col>
      </Row>

      <Row className="justify-content-center text-center">
        <Col>
          <h1>What can my administrator see or do?</h1>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs={12} md={6}>
          <h2>Systems Manager devices</h2>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs={12} md={6}>
          <div>
            Your administrator <span className="font-weight-bold">can</span>
          </div>
          <ul>
            <li>View device information as seen in your self-service portal</li>
            <li>Access your self-service tool kit and run these tools remotely</li>
            <li>Push app updates and security updates to your device</li>
            <li>
              View a log of your device events, including tools that have been run, check-in's, and
              app store activity
            </li>
          </ul>
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <div>
            Your administrator <span className="font-weight-bold">cannot</span>
          </div>
          <ul>
            <li>View your web history</li>
            <li>
              Use your personal or device information for purposes outside of IT administration
            </li>
          </ul>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs={12} md={6}>
          <h2>Trusted Access devices</h2>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs={12} md={6}>
          <div>
            Your administrator <span className="font-weight-bold">can</span>
          </div>
          <ul>
            <li>View device information as seen in your self-service portal</li>
            <li>Revoke ability to connect via Trusted Access</li>
          </ul>
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <div>
            Your administrator <span className="font-weight-bold">cannot</span>
          </div>
          <ul>
            <li>Push app updates and security updates to your device</li>
            <li>Send device management commands</li>
            <li>View your web history</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};
