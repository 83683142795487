import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Nav } from 'react-bootstrap';
import { Route, Switch, Redirect } from 'react-router-dom';
import DevicesPage from './devices';
import { SettingsRoot } from './settings';
import { ErrorRoot } from './error';
import { getLogoutStatus, wipeStore } from '../_redux';
import { fetchApps } from '_redux/apps';
import { LogoutFailureModal } from './devices/_components/LogoutFailureModal';
import { DisclaimerPage } from './disclaimer';
import { LinkContainer } from 'react-router-bootstrap';
import { Footer } from '_components/footer/Footer';
import { fetchDevices } from '_redux/devices';
import { DashNavbar } from './DashNavbar';
import { EnrollRoot } from './enroll';
import { HomePageRoot } from './homePage';

export const DashboardRoot = ({ match, location }) => {
  const baseUrl = `/${match.params.networkId}/${match.params.userId}`;

  const dispatch = useDispatch();
  const logoutSuccess = useSelector(getLogoutStatus);
  const [loggingOut, setLoggingOut] = useState(false);

  useEffect(() => {
    dispatch(fetchApps());
    dispatch(fetchDevices());
  }, [dispatch]);

  // Redirect needs to be rendered (returned) twice in order to actually
  // redirect to the specified URL. Because the wipeStore action clears
  // our redux store, logoutSuccess will immediately be set to false on the
  // next render, resulting in the redirect only being rendered once. To get
  // around this, we use the loggingOut state to ensure redirect is rendered
  // twice if a logout occurs, regardless of the current state.
  if (logoutSuccess || loggingOut) {
    if (!loggingOut) {
      setLoggingOut(true);
      dispatch(wipeStore());
    }
    return <Redirect to={`/${match.params.networkId}`} />;
  }

  const disclaimerLink = location.pathname.includes('disclaimer') ? (
    <Nav.Link className="px-0">What can my administrator see?</Nav.Link>
  ) : (
    <LinkContainer to={`${baseUrl}/disclaimer`}>
      <Nav.Link className="px-0">What can my administrator see?</Nav.Link>
    </LinkContainer>
  );

  return (
    <div className="d-flex flex-column min-vh-100">
      <DashNavbar baseUrl={baseUrl} />

      <Switch>
        <Route path="/:networkId/:userId/homepage" component={HomePageRoot} />
        <Redirect exact from="/:networkId/:userId" to={`${baseUrl}/devices`} />
        <Route path="/:networkId/:userId/devices" component={DevicesPage} />
        <Route path="/:networkId/:userId/disclaimer" component={DisclaimerPage} />
        <Route path="/:networkId/:userId/enroll" component={EnrollRoot} />
        <Route exact path="/:networkId/:userId/settings" component={SettingsRoot} />
        <Route path="/:networkId/:userId/error" component={ErrorRoot} />

        <Redirect to={`${baseUrl}/error`} />
      </Switch>
      {logoutSuccess === false && <LogoutFailureModal />}
      <Footer>{disclaimerLink}</Footer>
    </div>
  );
};
