import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import sspApi from '_utils/network';

import { Page } from 'enroll/_components/Page';
import { LoadingSpinnerFullscreen } from '_components/loadingSpinner/LoadingSpinner';
import { EnrollmentRegularLogin } from './_components/EnrollmentRegularLogin';
import { EnrollmentOAuthLogin } from './_components/EnrollmentOAuthLogin';
import { EnrollmentSamlLogin } from './_components/EnrollmentSamlLogin';

import SMLogo from 'images/SM_logo@2x.png';
import { RedirectToPlatformEnrollmentPage } from './_components/RedirectToPlatformEnrollmentPage';
import { updateCustomSettings } from '_redux/networkInfo';
import { LoginError } from 'shared/login/LoginError';
import { isWindowsAutoPilot } from './_components/currentDevicePlatform';

enum AuthTypes {
  Meraki = 'meraki',
  AD = 'ad',
  Google = 'google',
  Azure = 'azure',
  OpenId = 'openid',
  Saml = 'saml',
  None = 'none',
}

interface NetworkSettings {
  name: string;
  auth_type: AuthTypes;
  oauth_settings: {
    client_id?: string;
    tenant_id?: string;
    nonce: string;
    endpoint?: string;
    google_auth_domain?: string;
  };
  saml_endpoint: string;
  style_settings: string;
  error?: string;
  auth_token?: string;
}

const UpdateCustomSettings = styleSettings => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateCustomSettings(styleSettings['styleSettings']));
  }, [dispatch, styleSettings]);
  return null;
};

export function LoginPage({ match, history }) {
  const { networkId } = match.params;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const errorDesc = params.get('error_description');
  const networkSettingsQ = useQuery(
    ['networkSettings', networkId],
    async (): Promise<NetworkSettings> => {
      const response = await sspApi.get(`${networkId}/network_settings`);
      if (response.status !== 200)
        throw new Error('Failed to fetch enrollment settings for network');
      return await response.json();
    },
    {
      initialData: {
        name: null,
        auth_type: null,
        oauth_settings: null,
        saml_endpoint: null,
        style_settings: null,
      },
      enabled: !!networkId,
    },
  );
  if (networkSettingsQ.isIdle || networkSettingsQ.isFetching) {
    return <LoadingSpinnerFullscreen show />;
  } else if (networkSettingsQ.isError) {
    return <Redirect to={{ pathname: '/', state: { invalidNetworkId: networkId } }} />;
  } else if (networkSettingsQ.isSuccess && isWindowsAutoPilot()) {
    return (
      <Redirect to={{ pathname: `/${networkId}/windowsAutopilot`, search: params.toString() }} />
    );
  } else if (networkSettingsQ.isSuccess && networkSettingsQ.data.auth_type === AuthTypes.None) {
    return (
      <RedirectToPlatformEnrollmentPage
        enrollmentCode={networkId}
        authToken={networkSettingsQ.data.auth_token}
      />
    );
  } else if (networkSettingsQ.isSuccess) {
    const {
      name,
      auth_type: authType,
      oauth_settings: oAuthSettings,
      saml_endpoint: samlEndpoint,
      style_settings: styleSettings,
    } = networkSettingsQ.data;

    return (
      <Page
        title="Login: Systems Manager enrollment"
        iconSrc={SMLogo}
        header={
          history && history.action === 'PUSH'
            ? 'Log in to your network'
            : 'Enroll in Systems Manager'
        }
      >
        <LoginError error={errorDesc}></LoginError>
        {styleSettings && <UpdateCustomSettings styleSettings={styleSettings} />}
        <p>
          Use the {authType === AuthTypes.Meraki ? 'email address' : 'username'} and password of
          your company or school to access the <strong>{name}</strong> network.
        </p>
        {(authType === AuthTypes.Meraki || authType === AuthTypes.AD) && (
          <EnrollmentRegularLogin authType={authType} enrollmentCode={networkId} />
        )}
        {(authType === AuthTypes.Google ||
          authType === AuthTypes.Azure ||
          authType === AuthTypes.OpenId) && (
          <EnrollmentOAuthLogin
            authType={authType}
            enrollmentCode={networkId}
            oAuthSettings={oAuthSettings}
          />
        )}
        {authType === AuthTypes.Saml && <EnrollmentSamlLogin samlEndpoint={samlEndpoint} />}
      </Page>
    );
  } else {
    return <h1>wot</h1>;
  }
}
