import React, { useState, Fragment } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ButtonWithModalProps {
  title?: string;
  children?: any;
  buttonComponent?: any;
  buttonLabel?: string;
  description?: string;
  showDescription?: boolean;
  icon?: string;
  [propname: string]: any;
}

export const ButtonWithModal = (props: ButtonWithModalProps) => {
  const [isOpen, setOpen] = useState(false);

  const { title, children, buttonComponent, buttonLabel, ...buttonProps } = props;
  const ButtonComponent = buttonComponent || Button;

  return (
    <Fragment>
      <Modal show={isOpen} onHide={() => setOpen(false)} centered keyboard={false}>
        <Modal.Header closeButton />
        <Modal.Body className="py-4 px-4">
          {typeof children == 'function' ? children(() => setOpen(false)) : children}
        </Modal.Body>
      </Modal>
      <ButtonComponent onClick={() => setOpen(true)} title={title} {...buttonProps}>
        {buttonLabel}
      </ButtonComponent>
    </Fragment>
  );
};
