import React, { useEffect } from 'react';
import {
  WPNModalResetConfirmation,
  WPNModalSuccess,
  WPNModalSpinner,
  WPNModalError,
} from './WPNModalStates';
import sspApi from '_utils/network';
import { useMutation, useQueryClient } from 'react-query';
import { Ssid } from '_types/ssid';

const usePskGroupMutation = (ssidId, method) => {
  const queryClient = useQueryClient();
  const setNewSsid = data => {
    queryClient.setQueryData('ssids', (ssids: [Ssid]) =>
      ssids.map(ssid => (ssid.id === data.id ? data : ssid)),
    );
  };
  return useMutation(
    async () => {
      const res = await sspApi(`ssids/${ssidId}/psk_group`, { method });
      if (res.status === 200) return await res.json();
      throw Error('failed');
    },
    { onSuccess: setNewSsid },
  );
};

export const WPNPskGroupReset = ({ closeCb, ssidId }) => {
  const mutationQ = usePskGroupMutation(ssidId, 'put');

  const resetAndClose = () => {
    mutationQ.reset();
    closeCb();
  };

  switch (mutationQ.status) {
    case 'error':
      return <WPNModalError onAcknowledge={resetAndClose} />;
    case 'success':
      return <WPNModalSuccess onAcknowledge={resetAndClose} />;
    case 'idle':
      return <WPNModalResetConfirmation onClose={closeCb} onConfirm={() => mutationQ.mutate()} />;
    case 'loading':
    default:
      return <WPNModalSpinner />;
  }
};

export const WPNPskGroupCreate = ({ closeCb, ssidId }) => {
  const mutationQ = usePskGroupMutation(ssidId, 'post');

  useEffect(() => {
    mutationQ.mutate();
    //eslint-disable-next-line
  }, []);

  const resetAndClose = () => {
    mutationQ.reset();
    closeCb();
  };

  switch (mutationQ.status) {
    case 'error':
      return <WPNModalError onAcknowledge={resetAndClose} />;
    case 'success':
      return <WPNModalSuccess onAcknowledge={resetAndClose} />;
    case 'idle':
    case 'loading':
    default:
      return <WPNModalSpinner />;
  }
};
