import React, { Fragment } from 'react';
import { Spinner } from 'react-bootstrap';
import styles from './LoadingSpinner.module.css';
import { connect } from 'react-redux';

export const LoadingSpinnerBox = ({ children = null }) => {
  return (
    <div className={styles.contentContainer}>
      <Spinner animation="border" variant="light" />
      {children}
    </div>
  );
};

export const LoadingSpinnerFullscreen = ({ children = null, show = false }) => {
  return (
    <Fragment>
      {children}
      <div style={{ display: show ? null : 'none' }}>
        <div className={styles.fullscreenContainer}>
          <LoadingSpinnerBox />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({ spinner }) => {
  const show = Object.values(spinner).some(v => !!v);
  return { show };
};

export const ConnectedLoadingSpinnerFullscreen = connect(mapStateToProps)(LoadingSpinnerFullscreen);
