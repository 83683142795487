import React from 'react';
// @ts-ignore:
import { useAccordionToggle } from 'react-bootstrap';

import { Icon } from '_components/Icon';

import { ReactComponent as Checkmark } from 'images/outline_green_checkmark_icon.svg';
import { ReactComponent as YellowExclamationMark } from 'images/yellow_exclamation_mark.svg';
import { ReactComponent as RedExclamationMark } from 'images/red_exclamation_mark.svg';

import styles from './DeviceStatusPaneToggle.module.scss';

export const STATUS_SUCCESS = 'success';
export const STATUS_WARNING = 'warning';
export const STATUS_DANGER = 'danger';

export const DeviceStatusPaneToggle = ({ eventKey, children, status }) => {
  const handleClick = useAccordionToggle(eventKey);

  let IconComponent;
  switch (status) {
    case STATUS_SUCCESS:
      IconComponent = Checkmark;
      break;
    case STATUS_WARNING:
      IconComponent = YellowExclamationMark;
      break;
    case STATUS_DANGER:
      IconComponent = RedExclamationMark;
      break;
  }

  return (
    <button className={styles.deviceStatusPaneToggle} onClick={handleClick}>
      <IconComponent className="pr-3" style={{ width: '40px' }} />
      {children}
      <Icon glyph="caret-down" className="ml-2" />
    </button>
  );
};
