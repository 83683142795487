import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { DashboardRoot } from 'portal-dashboard';
import { useSelector, useDispatch } from 'react-redux';
import { loginErrorExists, getUser, fetchUser, getLoginStatus } from '_redux/login';
import { ConnectedLoginConfirmation } from './LoginConfirmation';
import { fetchMetaUser } from '_redux/metaUser';
import { createNetworkSelectorFromKey } from '_redux/network';
import { NetworkKeys } from '_redux/constants';

export const UserIdVerificationAndRoutes = ({ match, location }) => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const loginError = useSelector(loginErrorExists);
  const loggedIn = useSelector(getLoginStatus);
  const getUserRequest = useSelector(createNetworkSelectorFromKey(NetworkKeys.FetchUser));
  // user verification
  const userId = decodeURIComponent(match.params.userId);
  useEffect(() => {
    // dispatch update and get new data
    if (userId && userId.length) {
      dispatch(fetchUser());
      dispatch(fetchMetaUser());
    }
  }, [userId, dispatch]);

  if (user && (user.pcc_enrollment_code || user.enrollment_string)) {
    const networkId = match.params.networkId.toLowerCase();
    const userNetworkMatchesNetworkId =
      user.pcc_enrollment_code === networkId || user.enrollment_string?.toLowerCase() === networkId;

    if (!userNetworkMatchesNetworkId) {
      return <Redirect push to={`/${user.enrollment_string || user.pcc_enrollment_code}`} />;
    }
    if (user.id !== userId) {
      return <Redirect push to={`/${match.params.networkId}`} />;
    }
  }

  // If there was an error, redirect to the login page
  if (loginError || getUserRequest.status === 401) {
    return <Redirect push to={`/${match.params.networkId}`} />;
  }
  // If the user isn't logged in, redirect to the login page
  // Maintain state
  else if (loggedIn === false) {
    return (
      <Redirect
        to={{
          pathname: `/${match.params.networkId}`,
          state: { referer: location },
        }}
      />
    );
  }
  // If there is no error but also no user information, the request is pending
  else if (Object.entries(user).length === 0) {
    return <div> "Pending..." </div>;
  }

  return (
    <React.Fragment>
      <ConnectedLoginConfirmation match={match} location={location} />
      <DashboardRoot match={match} location={location} />
    </React.Fragment>
  );
};
