import React from 'react';
import { Helmet } from 'react-helmet';

import {
  CardContainer,
  Card,
  CardIcon,
  CardHeader,
  CardBody,
} from 'enroll/_components/CardContainer';
import infoIcon from 'images/info.svg';

export const EnrollDisclaimer = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Error: Systems Manager enrollment</title>
      </Helmet>
      <CardContainer>
        <Card>
          <CardIcon src={infoIcon} />
          <CardHeader>What can my administrator see or do?</CardHeader>
          <CardBody>
            <div>
              Your administrator <b>can</b>
              <ul>
                <li>View device info, like serial numbers</li>
                <li>Limit some device features</li>
                <li>Push app updates and security updates</li>
                <li>
                  View a log of your device events, including tools that have been run, check-in’s,
                  and app store activity
                </li>
              </ul>
            </div>
            <div>
              Your administrator <b>cannot</b>
              <ul>
                <li>View your web history</li>
                <li>View your email</li>
                <li>View text messages</li>
                <li>View other app’s data</li>
                <li>
                  Use your personal or device information for purposes outside of IT administration
                </li>
              </ul>
            </div>
          </CardBody>
        </Card>
      </CardContainer>
    </React.Fragment>
  );
};
