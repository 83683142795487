import React, { useCallback, useEffect } from 'react';
import { ToolButtonWithDescription, ToolButton } from '../_components/ToolButton';
import lost_mode_enabled_tool from 'images/tools/lost_mode_enabled_tool.svg';
import { ButtonWithModal } from 'portal-dashboard/devices/_components/ButtonWithModal';
import { useSelector, useDispatch } from 'react-redux';
import { getToolRequestState, sendToolRequest } from '_redux/tools';
import { NetworkKeys } from '_redux/constants';
import { removeKeyFromNetwork } from '_redux/network';
import { useFormik } from 'formik';
import { SuccessDisplay } from 'portal-dashboard/devices/_components/ButtonWithConfirmationModal';
import { Row, Button, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const getLostModeState = state => getToolRequestState(state, NetworkKeys.LostModeEnabled);

const initialValues = {
  message: '',
  phoneNumber: '',
  footnote: null,
};

const LostModeModal = ({ setOpen }) => {
  const { success, error } = useSelector(getLostModeState);
  const dispatch = useDispatch();
  const { deviceId } = useParams();
  const cleanup = useCallback(() => {
    dispatch(removeKeyFromNetwork(NetworkKeys.LostModeEnabled));
  }, [dispatch]);
  useEffect(() => cleanup, [cleanup]); // cleanup on unmount
  const handleSuccess = useCallback(() => {
    setOpen(false);
    cleanup();
  }, [setOpen, cleanup]);
  const { handleSubmit, values, handleChange } = useFormik({
    initialValues,
    onSubmit: values => {
      dispatch(sendToolRequest(NetworkKeys.LostModeEnabled, deviceId, values));
    },
  });

  if (success) {
    return <SuccessDisplay successText="Enabling Lost Mode" onClose={handleSuccess} />;
  } else {
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-2" controlId="formMessage">
          <h3 className="mb-3">Lockscreen Message</h3>
          <Form.Control
            as="textarea"
            name="message"
            value={values.message}
            onChange={handleChange}
            placeholder="Enter a message"
            isInvalid={!!error}
          />
        </Form.Group>

        <Form.Group className="mb-2" controlId="phoneNumber">
          <h3 className="mb-3">Contact Number</h3>
          <Form.Control
            type="tel"
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={handleChange}
            placeholder="123-4567"
            isInvalid={!!error}
          />
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>

        <Row className="justify-content-center mt-3">
          <Button variant="secondary" className="m-2" onClick={() => setOpen(false)}>
            Close
          </Button>
          <Button variant="primary" className="m-2" type="submit">
            Enable Lost Mode
          </Button>
        </Row>
      </Form>
    );
  }
};

export const EnableLostMode = ({ showDescription = false }) => (
  <ButtonWithModal
    title="Enable Lost Mode"
    description="Lost Mode locks your phone and displays a custom message"
    showDescription={showDescription}
    icon={lost_mode_enabled_tool}
    buttonComponent={showDescription ? ToolButtonWithDescription : ToolButton}
  >
    {setOpen => <LostModeModal setOpen={setOpen} />}
  </ButtonWithModal>
);
