import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';

import { ButtonWithModal } from '../../../_components/ButtonWithModal';
import { ToolButton, ToolButtonWithDescription } from '../_components/ToolButton';
import { SuccessDisplay } from '../../../_components/ButtonWithConfirmationModal';
import { NetworkKeys } from '_redux/constants';
import { getToolRequestState, sendToolRequest } from '_redux/tools';
import { removeKeyFromNetwork } from '_redux/network';

import message_tool from 'images/tools/message_tool.svg';

const ModalDisplay = props => {
  const { setOpen, success, error, sendMessage, handleSuccess, match } = props;
  const { deviceId } = match.params;
  return (
    <Fragment>
      {success && <SuccessDisplay successText="Message sent." onClose={handleSuccess} />}
      {!success && (
        <Formik
          initialValues={{ message: '' }}
          onSubmit={({ message }) => sendMessage(message, deviceId)}
        >
          {({ handleChange, handleSubmit, values }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-2" controlId="formMessage">
                <h3 className="mb-3">Send message</h3>
                <Form.Control
                  as="textarea"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  placeholder="Enter a message"
                />
                {error}
              </Form.Group>
              <Row className="justify-content-center mt-3">
                <Button variant="secondary" className="m-2" onClick={() => setOpen(false)}>
                  Close
                </Button>
                <Button variant="primary" className="m-2" type="submit">
                  Send message
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </Fragment>
  );
};

export const SendMessage = ({ showDescription = false }) => {
  return (
    <ButtonWithModal
      showDescription={showDescription}
      title="Send Message"
      description="Send a message to your device"
      icon={message_tool}
      buttonComponent={showDescription ? ToolButtonWithDescription : ToolButton}
    >
      {setOpen => <ConnectedModalDisplay setOpen={setOpen} />}
    </ButtonWithModal>
  );
};

const msp = state => getToolRequestState(state, NetworkKeys.SendMessage);

const mdp = (dispatch, { setOpen }) => ({
  sendMessage: (message, deviceId) =>
    dispatch(sendToolRequest(NetworkKeys.SendMessage, deviceId, { msg: message })),
  handleSuccess: () => {
    setOpen(false);
    dispatch(removeKeyFromNetwork(NetworkKeys.SendMessage));
  },
});

const ConnectedModalDisplay = connect(msp, mdp)(withRouter(ModalDisplay));
