import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { ButtonWithConfirmationModal } from '../../../_components/ButtonWithConfirmationModal';
import { sendToolRequest, getToolRequestState } from '_redux/tools';
import { removeKeyFromNetwork } from '_redux/network';

import styles from './ToolButton.module.scss';

interface ToolButtonProps {
  title: string;
  icon: string;
  onClick?(event: React.MouseEvent): void;
}

interface ToolButtonWithDescriptionProps {
  title: string;
  icon: string;
  description: string;
  onClick?(event: React.MouseEvent): void;
}

export const ToolButton = (props: ToolButtonProps) => (
  <div className={styles.toolButton} onClick={props.onClick}>
    <img alt={props.title} src={props.icon} className="w-80" />
    <h4 className={styles.toolButtonText}>{props.title}</h4>
  </div>
);

export const ToolButtonWithDescription = (props: ToolButtonWithDescriptionProps) => (
  <div className={styles.toolButtonWithDescription} onClick={props.onClick}>
    <Row className="align-items-center justify-content-center">
      <Col xs={3} sm={12} className="p-0">
        <img alt={props.title} src={props.icon} className={styles.icon} />
      </Col>
      <Col xs={9} sm={12} className="p-0 pr-1">
        <h4 className={styles.toolButtonText}>{props.title}</h4>
        <div className={styles.description}>
          <hr />
          {props.description}
        </div>
      </Col>
    </Row>
  </div>
);

export const ToolButtonWithConfirmationModalContainer = props => {
  const {
    match,
    title,
    description,
    showDescription,
    svgIcon,
    successText,
    helperDescription,
    buttonText,
    toolKey,
  } = props;

  const dispatch = useDispatch();
  const { success, error } = useSelector(state => getToolRequestState(state, toolKey));

  const sendRequest = deviceId => dispatch(sendToolRequest(toolKey, deviceId));

  return (
    <ButtonWithConfirmationModal
      title={title}
      description={description}
      icon={svgIcon}
      buttonComponent={showDescription ? ToolButtonWithDescription : ToolButton}
      confirmationText={helperDescription}
      buttonLabel={buttonText}
      error={error}
      onConfirm={() => sendRequest(match.params.deviceId)}
      success={success}
      successText={successText}
      onClose={() => dispatch(removeKeyFromNetwork(toolKey))}
    />
  );
};

export const ToolButtonWithConfirmationModal = withRouter(ToolButtonWithConfirmationModalContainer);
