import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendAppInstallRequest, sendAppUninstallRequest } from '_redux/apps';
import { ButtonWithConfirmationModal } from 'portal-dashboard/devices/_components/ButtonWithConfirmationModal';
import { NetworkKeys } from '_redux/constants';
import { createNetworkSelectorFromKey, removeKeyFromNetwork } from '_redux/network';

const getInstallAppsRequest = createNetworkSelectorFromKey(NetworkKeys.InstallApps);
const getUninstallAppRequest = createNetworkSelectorFromKey(NetworkKeys.UninstallApp);

interface AppActionButtonWithModalProps {
  appId: string;
  deviceId: string;
  isInstalled: boolean;
}

export const AppInstallButtonWithModal = (props: AppActionButtonWithModalProps) => {
  const { appId, deviceId, isInstalled } = props;

  const dispatch = useDispatch();
  const { success, error } = useSelector(getInstallAppsRequest);

  return (
    <ButtonWithConfirmationModal
      variant="secondary"
      buttonLabel={isInstalled ? 'Reinstall' : 'Install'}
      confirmationText={`This will ${
        isInstalled ? 'reinstall' : 'install'
      } the application on the device`}
      onConfirm={() => dispatch(sendAppInstallRequest(appId, deviceId))}
      success={success}
      successText="Install application command sent."
      error={error}
      onClose={() => dispatch(removeKeyFromNetwork(NetworkKeys.InstallApps))}
    />
  );
};

export const AppUninstallButtonWithModal = (props: AppActionButtonWithModalProps) => {
  const { appId, deviceId } = props;

  const dispatch = useDispatch();
  const { success, error } = useSelector(getUninstallAppRequest);

  return (
    <ButtonWithConfirmationModal
      className="ml-2"
      variant="secondary"
      buttonLabel="Uninstall"
      confirmationText="This will uninstall the application on the device"
      onConfirm={() => dispatch(sendAppUninstallRequest(appId, deviceId))}
      success={success}
      successText="Uninstall application command sent."
      error={error}
      onClose={() => dispatch(removeKeyFromNetwork(NetworkKeys.UninstallApp))}
    />
  );
};
