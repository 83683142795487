import React from 'react';
import { Container } from 'react-bootstrap';
import { SettingsPage } from './SettingsPage';
import { Helmet } from 'react-helmet';

export const SettingsRoot = () => {
  return (
    <Container className="d-flex flex-fill min-vw-100 mt-4">
      <Helmet>
        <title>Settings - SSP</title>
      </Helmet>
      <SettingsPage />
    </Container>
  );
};
