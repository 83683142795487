import React from 'react';
import { NetworkKeys } from '_redux/constants';
import { ToolButtonWithConfirmationModal } from '../_components/ToolButton';
import lock_tool from 'images/tools/lock_tool.svg';

export const LockDevice = ({ showDescription = false }) => (
  <ToolButtonWithConfirmationModal
    toolKey={NetworkKeys.LockDevice}
    title="Lock Device"
    description="Lock your device if stolen or lost to protect your data"
    showDescription={showDescription}
    svgIcon={lock_tool}
    successTitle="Device Successfully Locked"
    helperDescription="This will lock your device"
    buttonText="Lock Device"
  />
);
