import { default as kyBase } from 'ky';
import cookie from 'cookie';

const setCSRFToken = options => {
  const token = getToken();
  if (token) options.headers.set('X-CSRF-Token', token);
};

// function used to check if the user is logged into an active session
// Checks by looking at the CSRF cookie:
//    - if the cookie exists, then the user is still logged in
//      and function returns the token
//    - else, they are logged out and return false
export const getToken = () => {
  const token = cookie.parse(document.cookie).CSRF_TOKEN;
  if (token) return token;
  return false;
};

export const sspApi = kyBase.extend({
  prefixUrl: '/ssp',
  credentials: 'include',
  throwHttpErrors: false,
  hooks: { beforeRequest: [setCSRFToken] },
});

export const isValidURL = (url: string): boolean => {
  // Older browsers do not support the URL function - to avoid any errors here, let's use regex if the browser does not support it
  if (typeof URL === 'function') {
    try {
      let parsedUrl = new URL(url);
      return parsedUrl.protocol === 'https:';
    } catch (e) {
      return false;
    }
  } else {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
      'i',
    );
    return !!pattern.test(url);
  }
};

// @ts-ignore
window.sspApi = sspApi;
export default sspApi;
