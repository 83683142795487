import { sspApi } from '_utils/network';

export function getAppleEnrollmentUrl(enrollmentCode, userEnrollment = false, tags = '') {
  return async (): Promise<{ url: string; enrollingToken: string }> => {
    const searchParams = new URLSearchParams();
    if (userEnrollment) searchParams.set('user_enrollment', 'true');
    if (tags) searchParams.set('tags', tags);
    const response = await sspApi.get(`${enrollmentCode}/apple_enrollment_profile_url`, {
      searchParams,
      headers: { accept: 'application/json' },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (userEnrollment && response.status === 403) {
      throw Error(
        'Your network might not be configured for User Enrollment. Contact your network admin for more information.',
      );
    } else if (response.status === 401) {
      throw Error('Not logged in');
    } else {
      console.error('GET apple_enrollment_profile_url: Unknown network error');
      throw Error('An error has occurred while fetching enrollment profile. Please try again.');
    }
  };
}

export function getWindowsEnrollmentUrls(enrollmentCode) {
  return async (): Promise<{ agent_url: string; shard_url: string }> => {
    const response = await sspApi.get(`${enrollmentCode}/windows_enrollment_urls`);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401) {
      throw Error('Not logged in');
    } else {
      throw Error();
    }
  };
}

export function getIsAmaConfigured(enrollmentCode) {
  return async (): Promise<{ is_configured: boolean }> => {
    const response = await sspApi.get(`${enrollmentCode}/is_ama_configured`);
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error('An error has occurred while fetching configurations. Please try again.');
    }
  };
}

export function getAmaEnrollmentToken(enrollmentCode) {
  return async (): Promise<{ enrollmentToken: string }> => {
    const response = await sspApi.get(`${enrollmentCode}/google_ama_enrollment_token`);
    if (response.status === 200) {
      return await response.json();
    } else {
      throw Error('An error has occurred while fetching an enrollment token. Please try again.');
    }
  };
}

export async function getEnrollmentStatus(
  enrollmentCode,
  enrollingToken,
): Promise<{ isEnrolled: boolean }> {
  const response = await sspApi.get(`${enrollmentCode}/enrollment_status`, {
    searchParams: new URLSearchParams({ enrolling_token: enrollingToken }),
  });
  const { is_enrolled } = await response.json();
  return { isEnrolled: is_enrolled };
}

export const validateOAuthToken = async ({
  token,
  enrollmentCode,
}: {
  token: string;
  enrollmentCode: string;
}) => {
  const response = await sspApi.post(`${enrollmentCode}/validate_oauth_token`, {
    json: { token, access_type: 'enrollment' },
    headers: { accept: 'application/json' },
  });
  const responseBody = await response.json();
  if (response.status !== 200)
    throw new Error(
      responseBody.error || 'OAuth token validation failed due to unknown server error',
    );
  return responseBody;
};
