import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import {
  getManagedDevice,
  getManagedDeviceSecurityPolicies,
  getManagedDeviceViolatingPolicies,
} from '_redux/devices';

import { DeviceLocationMap } from './_components/DeviceLocationMap';
import {
  DeviceStatusPaneToggle,
  STATUS_SUCCESS,
  STATUS_DANGER,
} from './_components/DeviceStatusPaneToggle';
import { SecurityPolicyText } from './SecurityPolicyText';
import { SYSTEM_TYPES } from '_utils/deviceUtils';

export const DeviceStatus = ({ id }) => {
  const { lat, lng, geofence_compliant, system_type } = useSelector(state =>
    getManagedDevice(state, id),
  );

  const allPolicies = useSelector(state => getManagedDeviceSecurityPolicies(state, id));
  const violatingPolicies = useSelector(state => getManagedDeviceViolatingPolicies(state, id));
  const isSecurityCompliant = violatingPolicies.length === 0;

  return (
    <Accordion>
      <Card className="border-right-0 border-left-0">
        <Card.Header className="bg-white border-bottom-0">
          <DeviceStatusPaneToggle
            eventKey="security"
            status={isSecurityCompliant ? STATUS_SUCCESS : STATUS_DANGER}
          >
            {isSecurityCompliant ? (
              <span>This device's security is up-to-date</span>
            ) : (
              <span>This device is missing critical security features</span>
            )}
          </DeviceStatusPaneToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="security">
          <Card.Body>
            <ul>
              {isSecurityCompliant
                ? allPolicies.map(policy => (
                    <li key={policy} className="mb-2">
                      <SecurityPolicyText policy={policy} isCompliant={true} />
                    </li>
                  ))
                : violatingPolicies.map(policy => (
                    <li key={policy} className="mb-2">
                      <SecurityPolicyText policy={policy} isCompliant={false} />
                    </li>
                  ))}
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      {system_type !== SYSTEM_TYPES.GOOGLE_AMA && (
        <Card className="border-bottom-0 border-right-0 border-left-0">
          <Card.Header className="bg-white border-bottom-0">
            <DeviceStatusPaneToggle
              eventKey="location"
              status={geofence_compliant ? STATUS_SUCCESS : STATUS_DANGER}
            >
              {geofence_compliant ? (
                <span>This device is located within its location boundaries</span>
              ) : (
                <span>This device is located outside of its designated boundaries</span>
              )}
            </DeviceStatusPaneToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="location">
            <Card.Body>
              <DeviceLocationMap lat={lat} lng={lng} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )}
    </Accordion>
  );
};
