import React from 'react';
import { Button } from 'react-bootstrap';
import { Icon } from '_components/Icon';
import { AuthTypes } from '_redux/constants';
import { isValidURL } from '_utils/network';

const authTypeDisplayInfo = {
  [AuthTypes.Google]: {
    icon: 'google',
    displayName: 'Google',
  },
  [AuthTypes.Azure]: {
    icon: 'windows',
    displayName: 'Azure',
  },
  [AuthTypes.OpenId]: {
    icon: '',
    displayName: 'OpenID',
  },
};

/*
 * Authentication flow:
 * 1. User clicks login button.
 * 2. Redirects to IdP login window.
 * 3. User authenticates with OAuth provider.
 * 4. Rails backend redirects to /loginsuccess route with id_token param appended upon successful authentication and error_description if any.
 * 5. Continue with the login process from LoginSuccess component which detects the id_token and posts to /validate_oauth_token.
 */
export const OAuthLoginForm = ({
  authType,
  authEndpoint,
}: {
  authType: 'azure' | 'google' | 'openid';
  authEndpoint: string;
}) => {
  function handleLoginButtonClick(event) {
    const skipValidateURL = authType !== 'openid';
    if (skipValidateURL || isValidURL(authEndpoint)) {
      event.preventDefault();
      window.location.assign(authEndpoint);
    } else {
      console.error('Invalid OAuth URL');
    }
  }

  const { icon, displayName } = authTypeDisplayInfo[authType];
  return (
    <Button
      block
      variant="primary"
      type="submit"
      onClick={handleLoginButtonClick}
      data-testid="login"
    >
      {icon && <Icon className="pr-1" glyph={icon} />}
      <span>Log in with {displayName}</span>
    </Button>
  );
};
