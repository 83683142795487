export enum DeviceTypes {
  MDM = 'managed',
  TA = 'trusted-access', // url compatible string format
}

export enum SliceNames {
  NetworkInfo = 'networkInfo',
  Login = 'login',
  Devices = 'devices',
  Apps = 'apps',
  Enroll = 'enroll',
  TrustedAccessConfigs = 'trustedAccessConfigs',
  MetaUser = 'metaUser',
  MdmEnroll = 'mdmEnroll',
}

export enum AuthTypes {
  Meraki = 'meraki',
  AD = 'ad',
  Google = 'google',
  Azure = 'azure',
  OpenId = 'openid',
  Saml = 'saml',
}

export enum NetworkStates {
  Pending = 'PENDING',
  Resolved = 'RESOLVED',
  Rejected = 'REJECTED',
}

export enum RequestMethods {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Patch = 'patch',
  Head = 'head',
  Delete = 'delete',
}

export enum NetworkKeys {
  // Logout
  HandleLogout = 'HANDLE_LOGOUT',
  // Network
  FetchNetwork = 'FETCH_NETWORK_INFORMATION',
  // Login
  HandleLogin = 'HANDLE_LOGIN',
  OAuthLogin = 'OAUTH_LOGIN',
  FetchUser = 'FETCH_USER',
  // User
  FetchMetaUser = 'FETCH_META_USER',
  // Password Reset
  PasswordResetRequest = 'PASSWORD_RESET_REQUEST',
  PasswordReset = 'PASSWORD_RESET',
  // AD Group Sync Status Key
  ADGroupSync = 'AD_GROUP_SYNC',
  // Configs
  FetchTAConfigs = 'FETCH_TA_CONFIGS',
  DownloadTAConfig = 'DOWNLOAD_TA_CONFIG',
  // Device
  FetchDevices = 'FETCH_DEVICES',
  FetchManagedDevice = 'FETCH_MANAGED_DEVICE',
  FetchTADevice = 'FETCH_TA_DEVICE',
  RegisterDevice = 'REGISTER_DEVICE',
  RemoveDevice = 'REMOVE_DEVICE', // todo: deprecate
  UpdateDeviceName = 'UPDATE_DEVICE_NAME', // todo: deprecate
  UpdateDevice = 'UPDATE_DEVICE',
  DeleteDevice = 'DELETE_DEVICE',
  // Device Tool
  SendMessage = 'MESSAGE',
  ResetWorkProfilePasscode = 'RESET_WORK_PROFILE_PASSCODE',
  LockDevice = 'LOCK',
  Restart = 'RESTART',
  Wipe = 'WIPE',
  CheckIn = 'CHECK_IN',
  Shutdown = 'SHUT_DOWN',
  LostModePlaySound = 'LOST_MODE_PLAY_SOUND',
  LostModeEnabled = 'LOST_MODE_ENABLE',
  LostModeDisabled = 'LOST_MODE_DISABLE',
  ClearPasscode = 'CLEAR_PASSCODE',
  // Device Apps
  FetchApps = 'FETCH_APPS',
  InstallApps = 'INSTALL_APPS',
  UninstallApp = 'UNINSTALL_APP',
  //General
  FetchGoogleApiKey = 'FETCH_GOOGLE_API_KEY',
  // Enrollment Keys
  FetchEnrollPermissions = 'FETCH_ENROLL_PERMISSIONS',
  // Settings
  UpdateUserSettings = 'UPDATE_USER_SETTINGS',
  // Enroll
  FetchEnrollProfile = 'FETCH_ENROLL_PROFILE',
  CheckEnrolled = 'CHECK_ENROLLED',
  MDMLogin = 'MDM_LOGIN',
}

export const USER_LOGOUT = 'USER_LOGOUT';
