import React from 'react';
import { Button, Image, Row } from 'react-bootstrap';
import green_checkmark from 'images/outline_green_checkmark_icon.svg';
import red_exclamation_mark from 'images/red_exclamation_mark.svg';
import { ButtonWithModal } from './ButtonWithModal';

export const SuccessDisplay = ({ successText, onClose }) => {
  return (
    <React.Fragment>
      <Image src={green_checkmark} className="d-block w-25 mx-auto" />
      <h3 className="text-center">{successText}</h3>
      <h4 className="text-center">This may take up to 15 minutes to appear.</h4>
      <Row className="justify-content-center mt-3">
        <Button variant="secondary" className="m-2" onClick={onClose}>
          Close
        </Button>
      </Row>
    </React.Fragment>
  );
};

const ConfirmationDisplay = ({ confirmationText, error, closeModal, onConfirm, buttonLabel }) => {
  return (
    <React.Fragment>
      <Image src={red_exclamation_mark} className="d-block w-25 mx-auto" />
      <h3 className="text-center">{confirmationText}</h3>
      <h3 className="text-center">Continue?</h3>
      {error && <div className="text-danger text-center m-4">{`Error: ${error}`}</div>}
      <Row className="justify-content-center mt-3">
        <Button variant="secondary" className="m-2" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="primary" className="m-2" onClick={onConfirm} data-testid="confirmationBtn">
          {buttonLabel}
        </Button>
      </Row>
    </React.Fragment>
  );
};

interface ButtonWithConfirmationModalProps {
  confirmationText: string;
  onConfirm(): void;
  buttonLabel: string;
  success?: boolean;
  successText?: string;
  onClose?(): void;
  error?: string;
  [propname: string]: any;
}

export const ButtonWithConfirmationModal = (props: ButtonWithConfirmationModalProps) => {
  const {
    confirmationText,
    onConfirm,
    buttonLabel,
    success,
    successText,
    onClose,
    error,
    ...buttonProps
  } = props;

  const handleClose = closeModal => {
    closeModal();
    setTimeout(onClose, 1000);
  };

  return (
    <ButtonWithModal {...buttonProps} buttonLabel={buttonLabel}>
      {closeModal =>
        success ? (
          <SuccessDisplay successText={successText} onClose={() => handleClose(closeModal)} />
        ) : (
          <ConfirmationDisplay
            confirmationText={confirmationText}
            onConfirm={onConfirm}
            buttonLabel={buttonLabel}
            closeModal={() => handleClose(closeModal)}
            error={error}
          />
        )
      }
    </ButtonWithModal>
  );
};
