import React, { useState } from 'react';
import { NetworkError } from './_components/errors/NetworkError';
import { NetworkStringInput } from './_components/NetworkStringInput';
import { PreAuthNav } from './_components/PreAuthNav';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProvidedNetworkError,
  fetchNetworkRequirements,
  getNetworkInfoErrorStatus,
  getNetworkId,
} from '_redux/networkInfo';
import { Redirect } from 'react-router';

export const NetworkVerificationProvidedError = {
  Unrecognized: 'Unrecognized domain.',
  Disabled:
    'The Self Service Portal is disabled for this network. Contact your IT administrator for more information.',
  Expired:
    "This organization's free trial or license has expired. Please contact your administrator.",
  Authentication: 'Please enable authentication on your network before logging in.',
  NonSM: 'Unrecognized Systems Manager network.',
};

export const NetworkEntry = ({ history }) => {
  const dispatch = useDispatch();
  const hasError = useSelector(getNetworkInfoErrorStatus);
  const networkId = useSelector(getNetworkId);
  const providedError = useSelector(getProvidedNetworkError);

  const [userInteracted, setUserInteracted] = useState(false);

  if (!hasError && userInteracted && networkId !== null && networkId !== '') {
    return <Redirect push to={{ pathname: '/' + networkId }} />;
  }

  switch (providedError) {
    case NetworkVerificationProvidedError.Disabled:
      return (
        <NetworkError
          header="The Self Service Portal is disabled for this network."
          subheader="Contact your IT administrator for more information."
        />
      );
    case NetworkVerificationProvidedError.Expired:
      return (
        <NetworkError
          header="This organization's free trial or license has expired."
          subheader="Contact your IT administrator for more information."
        />
      );
    case NetworkVerificationProvidedError.Authentication:
      // this error seems to not ever be used by rails
      return (
        <NetworkError
          header="Authentication Error"
          subheader="Contact your IT administrator for more information."
        />
      );
    default:
      const invalidNetwork =
        providedError && providedError === NetworkVerificationProvidedError.Unrecognized;
      const oldNetworkId = history.location.state ? history.location.state['networkId'] : '';

      return (
        <React.Fragment>
          <PreAuthNav />
          <NetworkStringInput
            initialNetworkId={oldNetworkId}
            invalidNetwork={invalidNetwork}
            onSubmit={rawNetworkId => {
              const networkId = rawNetworkId.trim();
              if (networkId === null || networkId === '') return;
              setUserInteracted(true);
              dispatch(fetchNetworkRequirements(networkId));
            }}
          />
        </React.Fragment>
      );
  }
};
