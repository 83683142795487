import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Fixes TypeError: Invalid scheme when using ky.get in IE 11
// https://github.com/sveltejs/svelte/issues/2621#issuecomment-513679652
if ('baseURI' in Node.prototype === false) {
  Object.defineProperty(Node.prototype, 'baseURI', {
    get: function() {
      var base = (this.ownerDocument || this).querySelector('base');
      return (base || window.location).href;
    },
    configurable: true,
    enumerable: true,
  });
}
