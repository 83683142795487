import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { WPNSsid } from './_components/WPN';
// import { ManagedDeviceCard, TADeviceCard, TAConnectionCard } from './_components/Devices';
import { useQuery } from 'react-query';
import { LoadingSpinnerFullscreen } from '_components/loadingSpinner/LoadingSpinner';
import { useSelector } from 'react-redux';
import { getDevicesLoaded } from '_redux/network';
// import { getManagedDeviceIds, getTrustedAccessDeviceIds } from '_redux/devices';
import { fetchSsids } from '../_queries/fetchSsids';

export const HomePage = () => {
  // const baseUrl = `/${match.params.networkId}/${match.params.userId}`;
  const ssidsQ = useQuery('ssids', fetchSsids);
  const devicesLoaded = useSelector(getDevicesLoaded);
  // const mdmDeviceIds = useSelector(getManagedDeviceIds);
  // const taDeviceIds = useSelector(getTrustedAccessDeviceIds);

  // handle first fetch
  if (ssidsQ.isLoading || !devicesLoaded) return <LoadingSpinnerFullscreen show />;

  // handle fetch errors
  if (ssidsQ.isError) return <div>Error...</div>;

  return (
    <Container>
      <Row>
        <Col>
          <h1>My devices</h1>
        </Col>
      </Row>
      <Row>
        {/* <Col style={{ flex: '0 0 320px' }}>
          <Row style={{ marginBottom: '3em' }}>
            <Col>
              <h2>Individual Devices</h2>
              <div>Lorem ipsum - your managed devices and trusted access devices.</div>
            </Col>
          </Row>
          <Row style={{ alignContent: 'center' }}>
            {mdmDeviceIds.map(deviceId => (
              <ManagedDeviceCard deviceId={deviceId} baseUrl={baseUrl} key={deviceId} />
            ))}
          </Row>
          <Row style={{ alignContent: 'center' }}>
            {taDeviceIds.map(deviceId => (
              <TADeviceCard deviceId={deviceId} baseUrl={baseUrl} key={deviceId} />
            ))}
          </Row>
          <Row style={{ alignContent: 'center', textAlign: 'center', marginBottom: '1em' }}>
            <TAConnectionCard baseUrl={baseUrl} />
          </Row>
        </Col> */}
        <Col style={{ flex: '1 0 320px' }}>
          <Row style={{ marginBottom: '3em' }}>
            <Col>
              <h2>Device groups</h2>
              <div>
                Devices in the same Wi-Fi personal network group can easily discover each other, so
                you can stream your phone’s audio to your external speakers, your digital personal
                assistant to connected home devices, etc etc
              </div>
            </Col>
          </Row>
          <Row style={{ alignContent: 'center' }}>
            {ssidsQ.isSuccess && ssidsQ.data.map(ssid => <WPNSsid ssid={ssid} key={ssid.id} />)}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
