import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';

import { TrustedAccessDevicePage } from './TrustedAccessDevicePage';
import { TrustedAccessDownloadRoot } from './download/configId';

import { DeviceTypes } from '_redux/constants';
import { fetchTrustedAccessDevice, getTrustedAccessDevice } from '_redux/devices';

export const TrustedAccessDeviceRoot = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTrustedAccessDevice(match.params.deviceId));
  }, [dispatch, match.params.deviceId]);

  const device = useSelector(state => getTrustedAccessDevice(state, match.params.deviceId));

  if (!device) return <Redirect to={`/${match.params.networkId}/${match.params.userId}/devices`} />; // device removed
  if (!device.complete) return null; // new device, hasn't finished fetching full record

  return (
    <Switch>
      <Route
        path={`/:networkId/:userId/devices/${DeviceTypes.TA}/:deviceId/download/:configId`}
        component={TrustedAccessDownloadRoot}
      />
      <Route
        path={`/:networkId/:userId/devices/${DeviceTypes.TA}/:deviceId`}
        component={TrustedAccessDevicePage}
      />
    </Switch>
  );
};
