import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { ButtonWithModal } from 'portal-dashboard/devices/_components/ButtonWithModal';
import { Row, Button, Form } from 'react-bootstrap';
import { updateUserAccessDevice } from '_redux/devices';
import { getUpdateDeviceRequestErrors } from '_redux/network';
import { TrustedAccessDeviceName } from '_utils/formUtils';

const editDeviceSchema = Yup.object().shape({
  name: TrustedAccessDeviceName,
});

export const EditDeviceButton = ({ deviceId, name }) => {
  const dispatch = useDispatch();
  const errorText = useSelector(state => getUpdateDeviceRequestErrors(state));

  // TODO: since the modal is closed on submit this won't display if there's an
  // error. find a fix for this.
  let error;
  if (errorText) {
    error = <span className="text-danger">Error: {errorText}</span>;
  }

  return (
    <ButtonWithModal buttonLabel="Edit name" variant="link">
      {setClose => (
        <Formik
          initialValues={{ name }}
          onSubmit={values => {
            dispatch(updateUserAccessDevice(deviceId, values.name));
            setClose();
          }}
          validationSchema={editDeviceSchema}
        >
          {({ handleChange, handleSubmit, values, touched, errors }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-2">
                <h3 className="mb-3">Edit device name</h3>
                <Form.Control
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  placeholder="Enter a name"
                  isInvalid={touched.name && !!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>
              {error}
              <Row className="justify-content-center mt-3">
                <Button variant="secondary" className="m-2" onClick={setClose}>
                  Close
                </Button>
                <Button variant="primary" className="m-2" type="submit">
                  Save changes
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </ButtonWithModal>
  );
};
