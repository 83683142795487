import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { DeviceInfo } from './DeviceInfo';
import { DeviceStatus } from './DeviceStatus';
import { SummaryTools } from '../_toolButtons/ToolDisplay';

export const SummaryRoot = ({ match }) => {
  return (
    <Row className="mt-5">
      <Col xs={12} md={6}>
        <DeviceInfo header="Info" id={match.params.deviceId} />
        <div className="my-5">
          <h3>Tools</h3>
          <SummaryTools id={match.params.deviceId} />
        </div>
      </Col>
      <Col xs={12} md={6}>
        <div>
          <h3>Status</h3>
          <DeviceStatus id={match.params.deviceId} />
        </div>
      </Col>
    </Row>
  );
};
