import React from 'react';
import styles from './CardContainer.module.scss';

export const CardContainer = ({ children }) => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardContainerColumn}>{children}</div>
    </div>
  );
};

export const Card = ({ children }) => {
  return <div className={styles.card}>{children}</div>;
};

export const CardIcon = ({ src }) => {
  return (
    <div className={styles.cardIcon}>
      <img src={src} alt="Systems Manager" style={{ width: '100px' }}></img>
    </div>
  );
};

export const CardHeader = ({ children }) => {
  return (
    <div className={styles.cardHeader}>
      <h1>{children}</h1>
    </div>
  );
};

export const CardBody = ({ children }) => {
  return <div className={styles.cardBody}>{children}</div>;
};

export const CardFootnote = ({ children }) => {
  return <div className={styles.cardFootnote}>{children}</div>;
};
