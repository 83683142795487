import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import warning from 'images/warning.svg';

export const TrustedAccessDisabled = () => {
  return (
    <Container className="flex-fill d-flex align-items-center justify-content-center">
      <Row className="justify-content-center align-items-center">
        <Col xs="5" md="3">
          <Image src={warning} />
        </Col>
        <Col xs="11" md="9">
          <h2>Your account does not have permission to access Trusted Access devices.</h2>
          <h3>Contact your network administrator for more information. </h3>
        </Col>
      </Row>
    </Container>
  );
};
