import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router';

import { DeviceTypes } from '_redux/constants';

import { AddTrustedAccessDevice } from './AddTrustedAccessDevice';
import { Unauthorized } from './Unauthorized';
import { getTrustedAccessPermissions } from '_redux/login';

export const EnrollTrustedAccessRoot = () => {
  const hasTrustedAccessPermissions = useSelector(getTrustedAccessPermissions);

  if (!hasTrustedAccessPermissions) return <Unauthorized />;

  return (
    <Route
      exact
      path={`/:networkId/:userId/enroll/${DeviceTypes.TA}`}
      component={AddTrustedAccessDevice}
    />
  );
};
