import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { TrustedAccessDeviceRoot } from './deviceId';
import { DeviceTypes, NetworkStates } from '_redux/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrustedAccessConfigs, getTrustedAccessConfigInfo } from '_redux/trustedAccessConfigs';
import { isTrustedAccessDenied } from '_redux/devices';
import { TrustedAccessDisabled } from './_components/TrustedAccessDisabled';

export const TrustedAccessRoot = () => {
  const dispatch = useDispatch();
  const [sentRequests, setSentRequests] = useState(false);

  useEffect(() => {
    dispatch(fetchTrustedAccessConfigs());
    setSentRequests(true);
  }, [dispatch]);

  const trustedAccessConfigsRequest = useSelector(getTrustedAccessConfigInfo);
  const trustedAccessDenied = useSelector(isTrustedAccessDenied);

  // Wait for network request to complete
  if (!sentRequests || trustedAccessConfigsRequest.state !== NetworkStates.Resolved) return null;

  // Display trusted access denied error if applicable
  if (trustedAccessDenied) return <TrustedAccessDisabled />;

  return (
    <Route
      path={`/:networkId/:userId/devices/${DeviceTypes.TA}/:deviceId`}
      component={TrustedAccessDeviceRoot}
    />
  );
};
