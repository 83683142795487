import React, { Fragment } from 'react';
import { Button, Row, Spinner } from 'react-bootstrap';
import infoIcon from 'images/info.svg';
import warningIcon from 'images/warning.svg';
import successIcon from 'images/outline_green_checkmark_icon.svg';

const WPNModalRow = ({ children }) => (
  <Row className="align-content-center justify-content-around m-4 text-center">{children}</Row>
);

export const WPNModalSuccess = ({ onAcknowledge }) => (
  <Fragment>
    <WPNModalRow>
      <img src={successIcon} alt="alert" style={{ width: '100px' }} />
    </WPNModalRow>
    <WPNModalRow>Success!</WPNModalRow>
    <WPNModalRow>Your new password and QR code are now available to connect devices</WPNModalRow>
    <WPNModalRow>
      <Button onClick={onAcknowledge}>Ok</Button>
    </WPNModalRow>
  </Fragment>
);

export const WPNModalError = ({ onAcknowledge }) => (
  <Fragment>
    <WPNModalRow>
      <img src={infoIcon} alt="alert" style={{ width: '100px' }} />
    </WPNModalRow>
    <WPNModalRow>Error!!</WPNModalRow>
    <WPNModalRow>Please try again later</WPNModalRow>
    <Button onClick={onAcknowledge}>Ok</Button>
  </Fragment>
);

export const WPNModalSpinner = () => (
  <Fragment>
    <WPNModalRow>
      <Spinner animation="border" variant="dark" />
    </WPNModalRow>
    <WPNModalRow>Generating a new password</WPNModalRow>
  </Fragment>
);

export const WPNModalResetConfirmation = ({ onClose, onConfirm }) => (
  <Fragment>
    <WPNModalRow>
      <img src={warningIcon} alt="alert" style={{ width: '100px' }} />
    </WPNModalRow>
    <WPNModalRow>
      Generating a new password will disconnect your devices currently on the network. Would you
      like to continue?
    </WPNModalRow>
    <WPNModalRow>
      <Button size="lg" variant="outline-dark" onClick={onClose}>
        Cancel
      </Button>
      <Button size="lg" onClick={onConfirm}>
        Generate a new password
      </Button>
    </WPNModalRow>
  </Fragment>
);
