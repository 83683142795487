import React from 'react';
import { Link } from 'react-router-dom';

import artboard from 'images/tools/artboard.svg';

import { ToolButton } from '../_components/ToolButton';

export const AllToolsButton = () => {
  return (
    <Link to={'tools'}>
      <ToolButton icon={artboard} title="See all tools" />
    </Link>
  );
};
