// This must be the first line in src/index.js
import './polyfill';
// This must be the first line in src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import 'font-awesome/css/font-awesome.min.css';
import 'theme/index.scss';

import { createBrowserHistory } from 'history';
import { configureStore } from 'redux-starter-kit';

import { rootReducer } from '_redux';
import { PortalRoutes } from 'portal-auth';
import { AppProviders } from 'AppProviders';
import { QueryClient } from 'react-query';
import { EnrollRoutes } from 'enroll';

// equivalent to <BrowserRouter /> component
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/BrowserRouter.js
const history = createBrowserHistory();

const store = configureStore({
  reducer: rootReducer,
});

const queryClient = new QueryClient();

const providerProps = { history, store, queryClient };
const mountPoint = document.getElementById('root');

if (window.location.host.includes('enroll')) {
  ReactDOM.render(
    <AppProviders {...providerProps} title="MDM Enrollment">
      <EnrollRoutes />
    </AppProviders>,
    mountPoint,
  );
} else {
  ReactDOM.render(
    <AppProviders {...providerProps} title="Self-Service Portal">
      <PortalRoutes />
    </AppProviders>,
    mountPoint,
  );
}
