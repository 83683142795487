import React from 'react';
import { Image } from 'react-bootstrap';
import { PreAuthNav } from '../PreAuthNav';
import warning from '../../../images/yellow-exclamation.png';

export const NetworkError = ({ header, subheader }) => {
  return (
    <React.Fragment>
      <PreAuthNav />
      <div className="text-center">
        <Image src={warning} className="py-5" />
        <h1 className="pb-2">{header}</h1>
        <h3>{subheader}</h3>
      </div>
    </React.Fragment>
  );
};
