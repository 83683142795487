import React from 'react';
import classnames from 'classnames';
import { Icon } from '_components/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, Row, Col, Card } from 'react-bootstrap';

import { formatDate, formatRemainingTime, SYSTEM_TYPES } from '_utils/deviceUtils';
import { DeviceTypes } from '_redux/constants';
import { downloadMobileConfig, getTrustedAccessConfig } from '_redux/trustedAccessConfigs';
import { getTrustedAccessDevice } from '_redux/devices';
import {
  TB_STATIC,
  TB_DYNAMIC,
  TrustedAccessConnection,
  TrustedAccessConfig,
} from '_types/trustedAccess';

import styles from './TrustedAccessProfileCard.module.scss';

const STATUSES = {
  INSTALLED: 'installed',
  NOT_INSTALLED: 'not_installed',
  EXPIRED: 'expired',
};

const STATUSES_MAP = {
  [STATUSES.INSTALLED]: {
    subtitle: 'installed',
    className: 'installed',
  },
  [STATUSES.NOT_INSTALLED]: {
    subtitle: 'not installed',
    className: 'notInstalled',
  },
  [STATUSES.EXPIRED]: {
    subtitle: 'expired',
    className: 'expired',
  },
};

interface TrustedAccessProfileCardProps {
  connection?: TrustedAccessConnection;
  configId: string;
  deviceId: string;
  networkId: string;
  userId: string;
}

const getAccessEndDate = (config: TrustedAccessConfig, connection: TrustedAccessConnection) => {
  if (config.timebound_type === TB_STATIC) {
    return Date.parse(config.access_end_date);
  } else if (config.timebound_type === TB_DYNAMIC && connection) {
    // Dynamic access is stored in seconds so we need to convert to milliseconds
    return config.timebound_period * 1000 + Date.parse(connection.downloaded_at);
  }
  return null;
};

const getConfigStatus = (
  config: TrustedAccessConfig,
  connection: TrustedAccessConnection,
  currentTime: number,
  deviceType: string,
) => {
  let configStatus = STATUSES.NOT_INSTALLED;
  const accessEndDate = getAccessEndDate(config, connection);
  if (
    !!connection &&
    (!!connection.scep_completed_at ||
      (deviceType === SYSTEM_TYPES.ANDROID_PASSPOINT && !!connection.downloaded_at))
  ) {
    configStatus = STATUSES.INSTALLED;
  }
  if (accessEndDate && accessEndDate < currentTime) {
    configStatus = STATUSES.EXPIRED;
  }
  return configStatus;
};

const getDownloadLinkText = (configStatus, config: TrustedAccessConfig) => {
  let downloadLinkText = 'Download profile';
  // Dynamic configs are re-downloadable
  if (config.timebound_type === TB_DYNAMIC && configStatus !== STATUSES.NOT_INSTALLED) {
    downloadLinkText = 'Renew access';
  } else if (configStatus === STATUSES.EXPIRED) {
    downloadLinkText = null;
  } else if (configStatus === STATUSES.INSTALLED) {
    downloadLinkText = 'Re-download profile';
  }
  return downloadLinkText;
};

export const TrustedAccessProfileCard = (props: TrustedAccessProfileCardProps) => {
  const { connection, configId, deviceId, networkId, userId } = props;
  const config = useSelector(state => getTrustedAccessConfig(state, configId));
  const device = useSelector(state => getTrustedAccessDevice(state, deviceId));

  const currentTime = new Date().getTime();
  const configStatus = getConfigStatus(config, connection, currentTime, device.system_type);
  const accessEndDate = getAccessEndDate(config, connection);
  let remainingTimeInSeconds;
  if (
    config.timebound_type === TB_DYNAMIC &&
    configStatus !== STATUSES.EXPIRED &&
    connection?.downloaded_at
  ) {
    // dynamic config has been downloaded and has remaining time
    remainingTimeInSeconds =
      config.timebound_period - (currentTime - Date.parse(connection?.downloaded_at)) / 1000;
  }

  const downloadLinkText = getDownloadLinkText(configStatus, config);
  const androidTaParams = `enrollment_code=${networkId}&user_id=${userId}&trusted_access_config_id=${configId}`;
  const androidTaUrl = `/android-trusted-access/download?${androidTaParams}`;
  const downloadPageUrl = `/${networkId}/${userId}/devices/${DeviceTypes.TA}/${deviceId}/download/${configId}`;
  const dispatch = useDispatch();

  const isBrowserWindows = device.system_type === SYSTEM_TYPES.WINDOWS;
  const showDownloadLink =
    !isBrowserWindows &&
    (config.timebound_type === TB_DYNAMIC ||
      (config.timebound_type === TB_STATIC && configStatus !== STATUSES.EXPIRED));

  return (
    <Col xs={12} md={6} lg={4} className="h-100 mb-4">
      <Card
        className={classnames(styles.profileCard, styles[STATUSES_MAP[configStatus].className])}
      >
        <Card.Body>
          <div className={styles.profileCardHeader}>
            <span className={styles.profileCardTitle}>{config.payload_display_name}</span>
            <span className={styles.profileCardSubtitle}>
              {STATUSES_MAP[configStatus].subtitle}
            </span>
          </div>
          <ConnectionInfoRow label="SSID name">
            <Icon glyph="wifi" title={config.ssid_name} /> {config.ssid_name}
          </ConnectionInfoRow>
          <ConnectionInfoRow label="Profile installed">
            {formatDate(
              (device.system_type === SYSTEM_TYPES.ANDROID_PASSPOINT
                ? props?.connection?.downloaded_at
                : props?.connection?.scep_completed_at) ?? null,
              true,
            )}
          </ConnectionInfoRow>
          <ConnectionInfoRow label="Last connected">
            {formatDate(props?.connection?.last_connected_at ?? null, true)}
          </ConnectionInfoRow>
          {config.timebound_type === TB_DYNAMIC && (
            <>
              <ConnectionInfoRow label="Access lasts for">
                {formatRemainingTime(config.timebound_period)}
              </ConnectionInfoRow>
              <ConnectionInfoRow label="Renew access in">
                {formatRemainingTime(remainingTimeInSeconds)}
              </ConnectionInfoRow>
            </>
          )}
          <ConnectionInfoRow
            label={configStatus === STATUSES.EXPIRED ? 'Access expired' : 'Access ends'}
          >
            <span className={configStatus === STATUSES.EXPIRED ? styles.accessExpiredMessage : ''}>
              {formatDate(accessEndDate ? new Date(accessEndDate) : null, true)}
            </span>
          </ConnectionInfoRow>
        </Card.Body>
        {showDownloadLink && (
          <ListGroup variant="flush">
            <ListGroup.Item className="text-right">
              {device.system_type === SYSTEM_TYPES.ANDROID_PASSPOINT ? (
                <Card.Text
                  className={styles.passpointDownload}
                  onClick={() => dispatch(downloadMobileConfig(device.id, config.id))}
                  data-testid="downloadPasspointProfileBtn"
                >
                  {downloadLinkText}
                </Card.Text>
              ) : (
                <Card.Link
                  href={
                    device.system_type === SYSTEM_TYPES.ANDROID ? androidTaUrl : downloadPageUrl
                  }
                  data-testid="downloadProfileBtn"
                >
                  {downloadLinkText}
                </Card.Link>
              )}
            </ListGroup.Item>
          </ListGroup>
        )}
      </Card>
    </Col>
  );
};

const ConnectionInfoRow = ({ label, children }) => (
  <Row>
    <Col xs={5}>
      <h4>{label}</h4>
    </Col>
    <Col xs={7}>{children}</Col>
  </Row>
);
