import React from 'react';

import { ReactComponent as MobileIcon } from 'images/mobile_header.svg';
import { ReactComponent as LaptopIcon } from 'images/laptop_header.svg';

const MERAKI_GREEN = '#7bc144';

const SystemTypeIcon = ({ type }) => {
  switch (type) {
    case 'android':
    case 'google_ama':
    case 'android_passpoint':
    case 'ios':
      return (
        <h1 className="mt-1 mr-1">
          <MobileIcon height="1.5em" fill={MERAKI_GREEN} />
        </h1>
      );
    case 'mac':
    case 'windows':
    default:
      return (
        <h1 className="mr-3">
          <LaptopIcon height="1.5em" fill={MERAKI_GREEN} />
        </h1>
      );
  }
};

export const DeviceName = ({ children, type }) => {
  return (
    // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
    <div role="heading" className="d-flex align-items-center flex-nowrap">
      <SystemTypeIcon type={type} />
      <h1 className="mt-1 text-truncate">{children}</h1>
    </div>
  );
};
