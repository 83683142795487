import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';

export const NetworkConnectivityBanner = () => {
  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    const onlineEventListener = () => setOnline(true);
    const offlineEventListener = () => setOnline(false);

    window.addEventListener('online', onlineEventListener);
    window.addEventListener('offline', offlineEventListener);

    return () => {
      window.removeEventListener('online', onlineEventListener);
      window.removeEventListener('offline', offlineEventListener);
    };
  }, []);

  if (online) return null;

  return <Alert variant={'danger'}>Internet connection lost!</Alert>;
};
