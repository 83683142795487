import React from 'react';
import { Alert, Container, Row, Col } from 'react-bootstrap';
import { NetworkIdForm } from 'shared/networkIdentification/NetworkIdForm';

export const NetworkStringInput = ({ onSubmit, invalidNetwork, initialNetworkId = '' }) => {
  return (
    <Container className="my-5 px-auto">
      <Row className="justify-content-md-center mb-5">
        <Col xs={12} sm={6}>
          <h1 className="text-center">Meraki Self Service Portal Login</h1>
        </Col>
      </Row>
      <Row className="justify-content-md-center mb-3">
        <Col xs={12} sm={6}>
          <h3 className="text-center">Enter your Network ID</h3>
        </Col>
      </Row>
      <Row className="justify-content-md-center mb-4">
        <Col xs={12} sm={4}>
          <div>
            The Network ID is either a 10-digit code or a combination of letters, numbers, or
            characters.
          </div>
        </Col>
      </Row>
      <Row className="justify-content-md-center mb-4">
        <Col xs={12} sm={4}>
          {invalidNetwork && <Alert variant="danger">This is not a valid Network ID.</Alert>}
          <NetworkIdForm initialNetworkId={initialNetworkId} onSubmit={onSubmit} />
        </Col>
      </Row>
    </Container>
  );
};
