import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getGoogleMapApiKey, fetchGoogleApiKey } from '_redux/networkInfo';
import GoogleMapReact from 'google-map-react';
import { MapMarker } from './MapMarker';

// @ts-ignore: Needed to ignore lat and lng params required by google-map-react
const Marker = ({ lat, lng }) => {
  return <MapMarker text="Device" />;
};

export const DeviceLocationMap = ({ lat, lng }) => {
  const dispatch = useDispatch();
  const googleMapApiKey = useSelector(getGoogleMapApiKey);

  useEffect(() => {
    if (!googleMapApiKey) dispatch(fetchGoogleApiKey());
  }, [googleMapApiKey, dispatch]);

  if (!googleMapApiKey) return null;

  if (lat === undefined || lng === undefined) {
    return <div>No known location</div>;
  }

  return (
    // We need to enforce a set height in order for the map to display properly
    // within a bootstrap accordion. This height works well on a phone and on a
    // computer screen.
    <div style={{ height: '35vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMapApiKey }}
        defaultCenter={{ lat, lng }}
        defaultZoom={15}
      >
        <Marker lat={lat} lng={lng} />
      </GoogleMapReact>
    </div>
  );
};
