import React, { useRef, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormPassword, FormEmail, LoginFormUsername } from '_utils/formUtils';
import { AuthTypes } from '_redux/constants';

import styles from './RegularLoginForm.module.scss';

const MerakiLoginSchema = Yup.object().shape({
  email: FormEmail,
  password: FormPassword,
});

const ADLoginSchema = Yup.object().shape({
  email: LoginFormUsername,
  password: FormPassword,
});

export const RegularLoginForm = ({
  authType,
  enrollmentCode,
  onSubmit,
}: {
  authType: 'ad' | 'meraki';
  enrollmentCode: string;
  onSubmit: ({ email, password }) => void;
}) => {
  const emailEl = useRef(null);
  useEffect(() => {
    emailEl.current.focus();
  }, []);

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={authType === AuthTypes.Meraki ? MerakiLoginSchema : ADLoginSchema}
      onSubmit={({ email, password }) => onSubmit({ email, password })}
    >
      {({ handleSubmit, handleChange, values, errors, touched }) => (
        <Form className={styles.loginForm} onSubmit={handleSubmit}>
          <Form.Group controlId="email">
            <Form.Label className={styles.formLabel}>
              {authType === AuthTypes.Meraki ? 'Email address' : 'Username'}
            </Form.Label>
            <Form.Control
              ref={emailEl}
              name="email"
              onChange={handleChange}
              value={values.email}
              isInvalid={touched.email && !!errors.email}
              data-testid={authType === AuthTypes.Meraki ? 'email' : 'username'}
            />
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label className={styles.formLabel}>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              onChange={handleChange}
              value={values.password}
              isInvalid={touched.password && !!errors.password}
              data-testid="password"
            />
            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
          </Form.Group>
          <Row>
            <Col>
              <Button variant="primary" type="submit" block data-testid="login">
                Log in
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to={`/${enrollmentCode}/password-reset-request`}>
                <Button variant="link" block>
                  Forgot password?
                </Button>
              </Link>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
