import React from 'react';
import { Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import { currentDevicePlatform } from './currentDevicePlatform';

export function RedirectToPlatformEnrollmentPage({
  enrollmentCode,
  authToken,
  userEnrollment,
}: {
  enrollmentCode: string;
  authToken?: string;
  userEnrollment?: boolean;
}) {
  const platform = currentDevicePlatform();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  if (authToken) {
    searchParams.set('token', authToken);
  }
  if (userEnrollment) {
    searchParams.set('user_enrollment', 'true');
  }

  if (platform) {
    return (
      <Redirect
        to={{
          pathname: `/${enrollmentCode}/${platform}`,
          search: searchParams.toString(),
        }}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: '/error',
          search: `?errmsg=Your device is not currently supported`,
        }}
      />
    );
  }
}
