import React, { useEffect, useState } from 'react';
import { WaitWithSpinner } from 'enroll/_components/WaitWithSpinner';
import { getAmaEnrollmentToken } from 'enroll/api';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Page } from 'enroll/_components/Page';
import SMLogo from 'images/SM_logo@2x.png';
import { LoadingSpinnerBox } from '_components/loadingSpinner/LoadingSpinner';
import AmaInstruction from 'images/enrollment-screens/Ama-instructions-1@2x.png';

export const GoogleAmaEnrollmentPage = ({ domain, history }: { domain: string; history: any }) => {
  const [enrollmentToken, setEnrollmentToken] = useState('');
  const [downloadButtonClicked, setDownloadButtonClicked] = useState(false);
  const isAdp = document.referrer.includes('android-app://com.google.android.apps.work.clouddpc');

  useEffect(() => {
    if (downloadButtonClicked) {
      history.push(`/${domain}/success`);
    }
  }, [downloadButtonClicked, domain, history]);

  if (!enrollmentToken) {
    return (
      <WaitWithSpinner
        asyncFn={getAmaEnrollmentToken(domain)}
        onSuccess={(data: { enrollment_token?: string }) => {
          setEnrollmentToken(data.enrollment_token);
        }}
        onFail={error => {
          const params = new URLSearchParams({
            errmsg: error.message,
          });
          history.push(`/error?${params.toString()}`);
        }}
      />
    );
  } else {
    const params = new URLSearchParams({
      et: enrollmentToken,
    });
    const enrollmentUrl = `https://enterprise.google.com/android/enroll?${params.toString()}`;
    if (isAdp) {
      window.location.href = `https://enterprise.google.com/android/enroll?et=${enrollmentToken}`;
      return <LoadingSpinnerBox />;
    } else {
      return (
        <Page
          title="Instructions: Systems Manager Enrollment"
          header="How to enroll your Android device"
          iconSrc={SMLogo}
        >
          <React.Fragment>
            <p>
              Click Enroll and follow the Google steps to provision your device. When prompted,
              click Accept & continue.
            </p>
            <Container>
              <Row className="justify-content-center">
                <Col md={7} lg={5} className="justify-content-center">
                  <Image fluid src={AmaInstruction} />
                </Col>
              </Row>
              <Row>
                <Button href={enrollmentUrl} onClick={() => setDownloadButtonClicked(true)}>
                  Enroll
                </Button>
              </Row>
            </Container>
          </React.Fragment>
        </Page>
      );
    }
  }
};
