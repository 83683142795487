import React from 'react';
import { Container, Image } from 'react-bootstrap';
import warning from 'images/yellow-exclamation.png';

export const ErrorRoot = () => (
  <Container className="flex-fill d-flex flex-row align-items-center justify-content-center">
    <Image src={warning} className="py-5" />
    <div>
      <h1 className="m-4">Oops, the page you were trying to visit does not exist</h1>
      <h3 className="m-4">Either the URL was misspelled or the page has been moved/removed.</h3>
    </div>
  </Container>
);
