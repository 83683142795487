import React, { useState, useRef, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Button, Form, Alert } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { WindowsAppDownloadButton } from 'shared/WindowsAppDownloadButton';

import { DeviceTypes } from '_redux/constants';
import {
  getTrustedAccessDeviceIds,
  enrollTrustedAccessDevice,
  getEnrollUserAccessDeviceError,
} from '_redux/devices';
import { getEnrollAvailability } from '_redux/enroll';
import { TrustedAccessDeviceName } from '_utils/formUtils';
import { SYSTEM_TYPES } from '_utils/deviceUtils';

import {
  EnrollmentCard,
  EnrollmentCardHeader,
  EnrollmentCardBody,
  EnrollmentCardBodyTitle,
  EnrollmentCardBodyText,
  EnrollmentCardFooter,
} from '../_components/EnrollmentCard';
import { TrustedAccessDeviceLimitReached } from './TrustedAccessDeviceLimitReached';

const addDeviceSchema = Yup.object().shape({
  name: TrustedAccessDeviceName,
  type: Yup.string().required('Please select a device type'),
});

export const AddTrustedAccessDevice = ({ match: { params } }) => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const deviceIds = useSelector(getTrustedAccessDeviceIds);
  const errorMessage = useSelector(getEnrollUserAccessDeviceError);

  const prevDeviceIds = useRef([]);
  useEffect(() => {
    prevDeviceIds.current = deviceIds;
  });

  // check avilability
  // @ts-ignore
  const { user_access_devices } = useSelector(getEnrollAvailability);
  if (user_access_devices <= 0) {
    return <TrustedAccessDeviceLimitReached />;
  }

  const handleSubmit = ({ name, type }) => {
    setSubmitted(true);
    dispatch(enrollTrustedAccessDevice(name, type));
  };

  // successfully added iPhone/iPad/Mac device
  if (submitted && deviceIds.length > prevDeviceIds.current.length) {
    const [newDeviceId] = deviceIds.filter(id => !prevDeviceIds.current.includes(id));
    return (
      <Redirect
        to={`/${params.networkId}/${params.userId}/devices/${DeviceTypes.TA}/${newDeviceId}`}
      />
    );
  }

  return (
    <Fragment>
      <EnrollmentCard>
        <EnrollmentCardHeader>Add a new device</EnrollmentCardHeader>
        <Formik
          validateOnBlur
          validateOnChange={false}
          validationSchema={addDeviceSchema}
          onSubmit={handleSubmit}
          initialValues={{
            name: '',
            type: '',
          }}
        >
          {({ handleSubmit, handleChange, values, errors, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <EnrollmentCardBody>
                {submitted && errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                <EnrollmentCardBodyTitle>Register your current device</EnrollmentCardBodyTitle>
                <Form.Group as={Row} controlId="formGroupType">
                  <Form.Label column sm="4">
                    <h4>Device type</h4>
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      as="select"
                      value={values.type}
                      onChange={handleChange}
                      isInvalid={touched.type && !!errors.type}
                      name="type"
                      data-testid="deviceTypeSelect"
                    >
                      <option key="" value="" disabled>
                        Select a device type
                      </option>
                      <option
                        value={SYSTEM_TYPES.IOS}
                        data-testid={SYSTEM_TYPES.IOS.concat('Option')}
                      >
                        iPhone/iPad
                      </option>
                      <option
                        value={SYSTEM_TYPES.MAC}
                        data-testid={SYSTEM_TYPES.MAC.concat('Option')}
                      >
                        Mac
                      </option>
                      <option
                        value={SYSTEM_TYPES.WINDOWS}
                        data-testid={SYSTEM_TYPES.WINDOWS.concat('Option')}
                      >
                        Windows
                      </option>
                      <option
                        value={SYSTEM_TYPES.ANDROID_PASSPOINT}
                        data-testid={SYSTEM_TYPES.ANDROID_PASSPOINT.concat('Option')}
                      >
                        Android (with Passpoint)
                      </option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                {values.type !== SYSTEM_TYPES.WINDOWS && (
                  <Form.Group as={Row} controlId="formGroupName">
                    <Form.Label column sm="4">
                      <h4>Device name</h4>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        isInvalid={touched.name && !!errors.name}
                        placeholder="Enter a name"
                        data-testid="deviceNameInput"
                      />
                      <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                )}

                {values.type === SYSTEM_TYPES.WINDOWS && (
                  <Alert variant="primary" className="display-flex flex-column mb-3">
                    <EnrollmentCardBodyText>
                      To register your Windows device, you must install the Meraki Trusted Access
                      app.
                    </EnrollmentCardBodyText>
                    <WindowsAppDownloadButton />
                  </Alert>
                )}

                <EnrollmentCardBodyText>
                  Registering this device will allow you to access your organization's Wi-Fi
                  network(s) on it without entering a username/password.
                </EnrollmentCardBodyText>
              </EnrollmentCardBody>
              <EnrollmentCardFooter>
                {/* If the user has selected "Windows", we want to just redirect them back to the original page
                 */}
                {values.type === SYSTEM_TYPES.WINDOWS && (
                  <a href={`/${params.networkId}/${params.userId}/devices`}>
                    <Button variant="primary" className="px-3">
                      Back
                    </Button>
                  </a>
                )}
                {values.type !== SYSTEM_TYPES.WINDOWS && (
                  <Button
                    variant="primary"
                    className="px-3"
                    type="submit"
                    data-testid="addDeviceSubmitBtn"
                  >
                    Add device
                  </Button>
                )}
              </EnrollmentCardFooter>
            </Form>
          )}
        </Formik>
      </EnrollmentCard>
    </Fragment>
  );
};
