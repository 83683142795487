import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import {
  CardContainer,
  Card,
  CardIcon,
  CardHeader,
  CardBody,
  CardFootnote,
} from 'enroll/_components/CardContainer';
import { Helmet } from 'react-helmet';
import { getProfileStatus, getNetworkId } from '_redux/mdmEnroll';
import checkMarkIcon from 'images/outline_green_checkmark_icon.svg';

export const EnrollSuccess = () => {
  const profileStatus = useSelector(getProfileStatus);
  const networkId = useSelector(getNetworkId);
  if (!profileStatus && false) {
    return <Redirect to="/" />;
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>Success: Systems Manager enrollment</title>
      </Helmet>
      <CardContainer>
        <Card>
          <CardIcon src={checkMarkIcon} />
          <CardHeader>This device is now enrolled.</CardHeader>
          <CardBody>You are done setting up this device for Meraki Systems Manager.</CardBody>
        </Card>
        <CardFootnote>
          Need to manage your devices? Go to the{' '}
          <Link to={`/${networkId}`}>Self-Service Portal</Link>.
        </CardFootnote>
      </CardContainer>
    </React.Fragment>
  );
};
