import React from 'react';
import styles from './MapMarker.module.scss';

// Marker used by all new dashboard maps
export const MapMarker = ({ text = '' }) => (
  <div className={styles.mapMarker}>
    <div className={styles.markerContent}>{text}</div>
    <div className={styles.markerCaret}>
      <div className={styles.caretBorder} />
    </div>
  </div>
);
