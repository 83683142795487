import React from 'react';
import { NetworkKeys } from '_redux/constants';
import { ToolButtonWithConfirmationModal } from '../_components/ToolButton';
import reboot_tool from 'images/tools/reboot_tool.svg';

export const Shutdown = ({ showDescription = false }) => (
  <ToolButtonWithConfirmationModal
    toolKey={NetworkKeys.Shutdown}
    title="Shutdown Device"
    description="Turn off your device remotely"
    showDescription={showDescription}
    svgIcon={reboot_tool}
    successTitle="Device Shutdown"
    helperDescription="This will turn off your device"
    buttonText="Shutdown"
  />
);
