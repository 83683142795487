import React from 'react';
import { Alert } from 'react-bootstrap';
import { Redirect } from 'react-router';

export const LoginErrors = {
  // Meraki/AD
  InvalidUser: 'User not found',
  InvalidAuth: 'Invalid authentication',
  InvalidLogin: 'Invalid login',

  // OAuth
  UnprocessedUser: 'Cannot process user',
  AuthFailed: 'Authentication failed',

  // All
  WrongNetworkUser: 'The credentials provided belong to a different network in your organization.',
  Unrecognized: 'Unrecognized domain',
  Disabled:
    'The Self Service Portal is disabled for this user. Contact your IT administrator for more information.',
  Disabled2: 'SSP is not enabled for this user',
  Timeout: 'Request timed out',
};

export const LoginError = ({ error }: { error: string }) => {
  if (!error) return null;
  if (/Invalid assertion:/.test(error)) {
    return <Alert variant="danger">{error}</Alert>;
  } else {
    switch (error) {
      case LoginErrors.Unrecognized:
        // We don't expect to get here because we've already verified the enrollment code
        console.error('Called login endpoint with invalid enrollment code');
        return <Redirect to="/" />;
      case LoginErrors.Disabled:
      case LoginErrors.Disabled2:
        return (
          <Alert variant="warning">
            The Self Service Portal is not enabled for this account. Please contact your IT
            administrator for more information.
          </Alert>
        );
      case LoginErrors.AuthFailed:
        return (
          <Alert variant="danger">
            There was an error logging in. Please check your login credentials and try again.
          </Alert>
        );
      case LoginErrors.UnprocessedUser:
        return <Alert variant="danger">This account no longer exists.</Alert>;
      case LoginErrors.InvalidAuth:
        return (
          <Alert variant="danger">
            There was an error logging in. Please reload the page and try again.
          </Alert>
        );
      case LoginErrors.InvalidLogin:
        return <Alert variant="danger">Invalid login</Alert>;
      case LoginErrors.InvalidUser:
        return <Alert variant="danger">This account no longer exists.</Alert>;
      case LoginErrors.WrongNetworkUser:
        return (
          <Alert variant="danger">
            The credentials provided belong to a different network in your organization. Please
            contact your IT administrator for more information.
          </Alert>
        );
      default:
        return (
          <Alert variant="warning">
            There was an error logging in. Please try again or contact your IT administrator.
          </Alert>
        );
    }
  }
};
