import React, { Fragment, useEffect } from 'react';
import { PreAuthNav } from 'portal-auth/_components/PreAuthNav';
import { LoadingSpinnerBox } from '_components/loadingSpinner/LoadingSpinner';
import { Container, Row, Col } from 'react-bootstrap';
import {
  completeSamlLogin,
  getLoginError,
  validateOAuthToken as portalValidateOAuthToken,
} from '_redux/login';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { getUser, getLoginStatus } from '_redux/login';
import { LoginError } from 'shared/login/LoginError';
import { fetchNetworkRequirements, getAuthType } from '_redux/networkInfo';
import cookie from 'cookie';
import { FormlessEnrollmentOAuthLogin } from '../../enroll/networkId/_components/FormlessEnrollmentOAuthLogin';
import { RedirectToPlatformEnrollmentPage } from '../../enroll/networkId/_components/RedirectToPlatformEnrollmentPage';
import { AdeEnrollmentRedirect } from './AdeEnrollmentRedirect';

const CompleteLogin = ({ token, enrollmentCode }) => {
  const dispatch = useDispatch();
  let params = new URLSearchParams(window.location.search);
  const isSaml = params.get('saml_success') === 'true';
  const userEnrollment = params.get('user_enrollment') === 'true';

  useEffect(() => {
    if (isSaml) {
      dispatch(completeSamlLogin(''));
    } else if (token) {
      dispatch(portalValidateOAuthToken(token, enrollmentCode));
    }
  }, [dispatch, isSaml, token, enrollmentCode, userEnrollment]);
  return null;
};

export const LoginSuccess = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getLoginStatus);
  const user = useSelector(getUser);
  const authType = useSelector(getAuthType);
  const loginError = useSelector(getLoginError);
  const isEnroll = /enroll/.test(window.location.host);

  let params = new URLSearchParams(window.location.search);
  // Oauth redirects include the token in the hash instead of the search
  if (window.location.hash.includes('#')) {
    params = new URLSearchParams(window.location.hash.slice(1));
  }
  const authToken = params.get('token');
  const enrollmentCode = params.get('enrollment_code');
  const errorDesc = params.get('error_description');
  const pcc_machine_id = cookie.parse(document.cookie).pcc_machine_id;
  const isAde = Boolean(pcc_machine_id);

  const state = params.get('state');
  let enrollmentCodeState;
  let userEnrollment;
  if (state && state.split(';').length === 2) {
    enrollmentCodeState = state.split(';')[0];
    userEnrollment = state.split(';')[1] === 'true';
  } else {
    enrollmentCodeState = state;
    userEnrollment = false;
  }
  const token = params.get('id_token');

  useEffect(() => {
    if (((enrollmentCode && enrollmentCode.length) || enrollmentCodeState) && !authType) {
      dispatch(fetchNetworkRequirements(enrollmentCode || enrollmentCodeState));
    }
  }, [enrollmentCode, dispatch, authType, enrollmentCodeState]);

  if (errorDesc || loginError) {
    // When there is an error in the ade enrollment, we want to add the pcc_machine_id back in so the cookie can be set again
    const pccMachineSearch = isAde ? `&pcc_machine_id=${pcc_machine_id}` : '';
    return (
      <Fragment>
        <PreAuthNav />
        <Container>
          <Row className="justify-content-center">
            <Col md={7} lg={5} className="justify-content-center">
              <LoginError error={errorDesc || loginError} />
              <Redirect
                to={`/${enrollmentCode}?error_description=${errorDesc ||
                  loginError}${pccMachineSearch}`}
              />
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }

  if (isEnroll) {
    if (isAde) {
      return (
        <AdeEnrollmentRedirect
          enrollmentCode={enrollmentCode || enrollmentCodeState}
          token={token}
          pccMachineId={pcc_machine_id}
        />
      );
    } else if (token && enrollmentCodeState) {
      // This is likely the OAuth flow so we need to validate the id_token
      return (
        <FormlessEnrollmentOAuthLogin
          token={token}
          enrollmentCode={enrollmentCodeState}
          userEnrollment={userEnrollment}
        />
      );
    } else if (enrollmentCode) {
      // If we're trying to hit the enrollment portal, all we need is the enrollmentCode which we pass as a param
      return (
        <RedirectToPlatformEnrollmentPage authToken={authToken} enrollmentCode={enrollmentCode} />
      );
    }
  } else {
    // If we're trying to hit the SSP portal, we need to ensure user is logged in and fetch info
    // First we need to know what type of user we're looking for
    if (authType) {
      if (isLoggedIn && user && user.id) {
        if (user.enrollment_string) {
          return (
            <Redirect
              to={`/${encodeURIComponent(user.enrollment_string)}/${encodeURIComponent(user.id)}`}
            />
          );
        }
        // If there is no enrollment_string we can use the enrollmentCode
        return (
          <Redirect
            to={`/${enrollmentCode || enrollmentCodeState}/${encodeURIComponent(user.id)}`}
          />
        );
      }
      return <CompleteLogin token={token} enrollmentCode={enrollmentCodeState} />;
    }
  }

  return (
    <Fragment>
      <PreAuthNav />
      <Container>
        <Row className="justify-content-center">
          <Col md={7} lg={5}>
            <p className="m-4 lead text-center">Validating Sign in...</p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col md={7} lg={5} className="d-flex justify-content-center">
            <LoadingSpinnerBox />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
