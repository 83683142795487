import React from 'react';
import { NetworkKeys } from '_redux/constants';
import { ToolButtonWithConfirmationModal } from '../_components/ToolButton';
import passcode_tool from 'images/tools/passcode_tool.svg';

export const ClearPasscode = ({ showDescription = false }) => (
  <ToolButtonWithConfirmationModal
    toolKey={NetworkKeys.ClearPasscode}
    title="Clear Passcode"
    description="Clear the passcode on your device"
    showDescription={showDescription}
    svgIcon={passcode_tool}
    successTitle="Message Sent"
    helperDescription="This will clear the passcode from your device"
    buttonText="Clear Passcode"
  />
);
