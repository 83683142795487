import { sspApi } from '_utils/network';
import { createSelector } from 'redux-starter-kit';
import { NetworkKeys, NetworkStates } from './constants';
import {
  startPost,
  endRejected,
  endWithResponse,
  checkResponseForError,
  createNetworkSelectorFromKey,
} from '_redux/network';
import { hyphenateNetworkId } from './networkInfo';

export const getPasswordResetRequestInfo = createNetworkSelectorFromKey(
  NetworkKeys.PasswordResetRequest,
);

export const getPasswordResetInfo = createNetworkSelectorFromKey(NetworkKeys.PasswordReset);

export const getPasswordResetRequestError = createSelector(
  [getPasswordResetRequestInfo],
  passwordResetInfo => {
    return passwordResetInfo && passwordResetInfo.error ? passwordResetInfo.error : '';
  },
);

export const hasPasswordResetRequestSucceeded = createSelector(
  [getPasswordResetRequestInfo],
  passwordResetInfo => {
    if (!passwordResetInfo || passwordResetInfo.state !== NetworkStates.Resolved) return false;
    return passwordResetInfo.ok;
  },
);

export const getPasswordResetError = createSelector([getPasswordResetInfo], passwordResetInfo => {
  return passwordResetInfo && passwordResetInfo.error ? passwordResetInfo.error : '';
});

export const hasPasswordResetSucceeded = createSelector(
  [getPasswordResetInfo],
  passwordResetInfo => {
    if (!passwordResetInfo || passwordResetInfo.state !== NetworkStates.Resolved) return false;
    return passwordResetInfo.ok;
  },
);

export const requestPasswordReset = (email: string, rawNetworkId: string) => async dispatch => {
  const key = NetworkKeys.PasswordResetRequest;
  dispatch(startPost(key, true));

  const body = new FormData();
  body.set('email', email);

  try {
    const networkId = hyphenateNetworkId(rawNetworkId);
    const response = await sspApi.post(`${networkId}/password_reset_request`, {
      body,
    });

    const data = await response.clone().json();
    const { error, errorData } = checkResponseForError(data);

    if (!error) {
      dispatch(endWithResponse(key, response));
    } else {
      dispatch(endWithResponse(key, response, errorData));
    }
  } catch (e) {
    dispatch(endRejected(key, e));
  }
};

export const resetPassword = (
  password: string,
  confirmPassword: string,
  token: string,
  rawNetworkId,
) => async dispatch => {
  const key = NetworkKeys.PasswordReset;
  dispatch(startPost(key, true));

  const body = new FormData();
  body.set('password', password);
  body.set('password_confirmation', confirmPassword);
  body.set('token', token);

  try {
    const networkId = hyphenateNetworkId(rawNetworkId);
    const response = await sspApi.post(`${networkId}/password_reset`, {
      body,
    });

    const data = await response.clone().json();
    const { error, errorData } = checkResponseForError(data);

    if (!error) {
      dispatch(endWithResponse(key, response));
    } else {
      dispatch(endWithResponse(key, response, errorData));
    }
  } catch (e) {
    dispatch(endRejected(key, e));
  }
};
