import { createSlice } from 'redux-starter-kit';
import { SliceNames, NetworkKeys } from './constants';
import { startGet, endWithResponse, endRejected } from './network';
import { sspApi } from '_utils/network';

export interface MetaUser {
  id?: string;
  ids: [];
  email?: string;
  fullName?: string;
  username?: string;
  hasPassword?: boolean;
  tags: [];
  adGroups: [];
  asmGroups: [];
  groups: [];
  isExternal?: boolean;
  type?: string;
  types: [];
  s3Url?: string;
  sspEnabled?: boolean;
  trustedAccessEnabled?: boolean;
  displayName?: string;
  googleEmmId?: string;
  isChromeUser?: boolean;
  trustedAccessDeviceLimit?: number;
  identityCertificateFilename?: string;
  identityCertificateSubject?: string;
  identityCertificateIssuer?: string;
  identityCertificateExpiry?: string;
  hasIdentityCertificate?: boolean;
}

const initialState: MetaUser = {
  ids: [],
  tags: [],
  adGroups: [],
  asmGroups: [],
  groups: [],
  types: [],
  trustedAccessDeviceLimit: null,
  identityCertificateFilename: null,
  identityCertificateSubject: null,
  identityCertificateIssuer: null,
  identityCertificateExpiry: null,
};

const metaUserSlice = createSlice({
  slice: SliceNames.MetaUser,
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    resetAndInsert: (_state, { payload }) => ({ ...initialState, ...payload }),
  },
});

export default metaUserSlice.reducer;

// actions
export const { resetAndInsert, reset } = metaUserSlice.actions;

export const fetchMetaUser = () => async dispatch => {
  const key = NetworkKeys.FetchMetaUser;
  // TODO: set it to block when deprecating login/user
  dispatch({ ...startGet(key, false), ...reset() });

  try {
    const response = await sspApi.get(`get_meta_user`);
    const defaultAction = endWithResponse(key, response);

    if (response.ok) {
      const data = await response.json();
      dispatch({ ...defaultAction, ...resetAndInsert(data) });
    } else {
      dispatch(defaultAction);
    }
  } catch (e) {
    dispatch(endRejected(key, e)); // TODO: what do we do if rejected?
  }
};

// selectors
export const getMetaUser = state => state[SliceNames.MetaUser];
