import React, { Fragment } from 'react';
import { Navbar } from 'react-bootstrap';
import styles from './PreAuthNav.module.scss';
import { NetworkConnectivityBanner } from '_components/navbar/NetworkConnectivityBanner';
import { Logo } from '_components/navbar/Navbar';
import { useSelector } from 'react-redux';
import { getCustomColor } from '_redux/networkInfo';

export const PreAuthNav = () => {
  const customColor = useSelector(getCustomColor);
  return (
    <Fragment>
      <Navbar
        variant="light"
        bg="white"
        className={styles.navBar}
        style={{ borderTopColor: customColor }}
      >
        <Navbar.Brand>
          <Logo />
        </Navbar.Brand>
      </Navbar>
      <NetworkConnectivityBanner />
    </Fragment>
  );
};
