/*
 * This is how we find the platform on which the user is visiting SSP. We use
 * this information to route to device-specific enrollment pages.
 */
type deviceType = 'ios' | 'mac' | 'windows' | 'windows10' | 'windowsSettings' | 'android';
export function currentDevicePlatform(): deviceType {
  if (
    /iPhone|iPod|iPad/.test(navigator.userAgent) ||
    (/Macintosh/i.test(navigator.userAgent) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 1)
  ) {
    // Safari on <iPad OS 13 is listed as iPad
    // Safari on >iPad OS 13 mimics Mac OS Safari but has 'maxTouchPoints' attribute
    // https://stackoverflow.com/questions/60482650/how-to-detect-ipad-useragent-on-safari-browser
    return 'ios';
  } else if (/Macintosh/.test(navigator.userAgent)) {
    return 'mac';
  } else if (/MSAuthHost/.test(navigator.userAgent)) {
    return 'windowsSettings';
  } else if (/Windows/.test(navigator.userAgent)) {
    return 'windows';
  } else if (/Android|Silk/.test(navigator.userAgent)) {
    return 'android';
  } else {
    console.error('Unrecognized device type for enrollment');
    return null;
  }
}

// Testing util
export function mockCurrentDevicePlatform(deviceType: deviceType) {
  // @ts-ignore
  Object.defineProperty(global.navigator, 'platform', { value: '', writable: true });
  // @ts-ignore
  Object.defineProperty(global.navigator, 'userAgent', { value: '', writable: true });
  switch (deviceType) {
    case 'ios':
      // @ts-ignore
      global.navigator.userAgent = 'iPhone';
      break;
    case 'mac':
      // @ts-ignore
      global.navigator.userAgent = 'Macintosh';
      break;
    case 'windows':
      // @ts-ignore
      global.navigator.userAgent = 'Windows';
      break;
    case 'windows10':
      // @ts-ignore
      global.navigator.userAgent = 'Windows NT 10.0';
      break;
    case 'windowsSettings':
      // @ts-ignore
      global.navigator.userAgent = 'MSAuthHost/1.0';
      break;
    case 'android':
      // @ts-ignore
      global.navigator.userAgent = 'Android';
      break;
  }
}

export function isWindowsAutoPilot(): boolean {
  return /MSAppHost/.test(navigator.userAgent);
}

// Testing util
export function mockIsWindowsAutoPilot() {
  // @ts-ignore
  Object.defineProperty(global.navigator, 'platform', { value: '', writable: true });
  // @ts-ignore
  Object.defineProperty(global.navigator, 'userAgent', { value: '', writable: true });
  // @ts-ignore
  global.navigator.userAgent = 'MSAppHost/1.0';
}

type browserType = 'safari' | 'chrome' | 'firefox' | 'ie' | 'edge';
export function currentDeviceBrowser(): browserType {
  const userAgent = navigator.userAgent;
  if (/Edge\/|EdgiOS\/\d+/i.test(userAgent)) {
    return 'edge';
  } else if (/Trident\/|MSIE/.test(userAgent)) {
    return 'ie';
  } else if (/Firefox\/\d+|FxiOS\/\d+/i.test(userAgent)) {
    return 'firefox';
  } else if (/(Chrome\/|CriOS\/)/.test(userAgent)) {
    return 'chrome';
  } else if (/Safari\/\d+/i.test(userAgent)) {
    return 'safari';
  } else {
    console.error('Unrecognized browser.');
    return null;
  }
}

// Testing util
export function mockCurrentDeviceBrowser(browserType: browserType) {
  // @ts-ignore
  Object.defineProperty(global.navigator, 'platform', { value: '', writable: true });
  // @ts-ignore
  Object.defineProperty(global.navigator, 'userAgent', { value: '', writable: true });
  switch (browserType) {
    case 'edge':
      // @ts-ignore
      global.navigator.userAgent = 'Edge';
      break;
    case 'ie':
      // @ts-ignore
      global.navigator.userAgent = 'Trident';
      break;
    case 'firefox':
      // @ts-ignore
      global.navigator.userAgent = 'Firefox';
      break;
    case 'chrome':
      // @ts-ignore
      global.navigator.userAgent = 'Chrome';
      break;
    case 'safari':
      // @ts-ignore
      global.navigator.userAgent = 'Safari';
      break;
  }
}
