import { combineReducers } from 'redux';

import { SliceNames, USER_LOGOUT, NetworkKeys, NetworkStates } from './constants';
import { sspApi } from '_utils/network';
import network, {
  checkResponseForError,
  startPost,
  endWithResponse,
  endRejected,
  getLogoutInfo,
} from './network';
import { createSelector } from 'redux-starter-kit';

import networkInfo from './networkInfo';
import mdmEnroll from './mdmEnroll';
import login from './login';
import enroll from './enroll';
import spinner from './spinner';
import apps from './apps';
import devices from './devices';
import trustedAccessConfigs from './trustedAccessConfigs';
import MetaUser from './metaUser';

const appReducer = combineReducers({
  spinner,
  network,
  apps,
  enroll,
  [SliceNames.NetworkInfo]: networkInfo,
  [SliceNames.MdmEnroll]: mdmEnroll,
  [SliceNames.Login]: login,
  [SliceNames.Devices]: devices,
  [SliceNames.TrustedAccessConfigs]: trustedAccessConfigs,
  [SliceNames.MetaUser]: MetaUser,
});

export const rootReducer = (state, action) => {
  //  If the action is logout, clear the state except for the network state
  if (action.type === USER_LOGOUT) {
    const { networkInfo } = state;
    state = { networkInfo };
  }

  return appReducer(state, action);
};

// Logout

// Logout Status Selector
export const getLogoutStatus = createSelector([getLogoutInfo], logoutStatus => {
  if (!logoutStatus || logoutStatus.state === NetworkStates.Pending) {
    return null;
  }

  const { state, error } = logoutStatus;
  if (state === NetworkStates.Rejected || error) {
    return false;
  }
  return true;
});

export const wipeStore = () => async dispatch => {
  dispatch({ type: USER_LOGOUT });
};

export const handleLogout = () => async dispatch => {
  const key = NetworkKeys.HandleLogout;
  dispatch(startPost(key, true));

  try {
    const response = await sspApi.post('logout');
    const { errorData } = checkResponseForError(response);
    dispatch(endWithResponse(key, response, errorData));
  } catch (e) {
    dispatch(endRejected(key, e));
  }
};
