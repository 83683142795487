import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';

export const EnrollmentCard = ({ children }) => {
  return (
    <Container className="flex-fill d-flex align-items-center justify-content-center">
      <Row className="justify-content-center my-5 w-100">
        <Col xs={12} md={8} lg={6}>
          <Card>{children}</Card>
        </Col>
      </Row>
    </Container>
  );
};

export const EnrollmentCardHeader = ({ children }) => {
  return (
    <Card.Header as="h2" className="bg-transparent py-4">
      {children}
    </Card.Header>
  );
};

export const EnrollmentCardBody = ({ children }) => {
  return <Card.Body className="px-4 py-4">{children}</Card.Body>;
};

export const EnrollmentCardBodyTitle = ({ children }) => {
  return (
    <Card.Title as="h3" className="pb-3">
      {children}
    </Card.Title>
  );
};

export const EnrollmentCardBodyText = ({ children }) => {
  return <Card.Text>{children}</Card.Text>;
};

export const EnrollmentCardRestrictedBody = ({ children }) => {
  return <div className="px-0">{children}</div>;
};

export const EnrollmentCardFooter = ({ children }) => {
  return (
    <Card.Footer className="bg-transparent d-flex justify-content-end py-3">{children}</Card.Footer>
  );
};
