import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import { NetworkIdVerificationAndRoutes } from './networkId';
import { NetworkEntry } from './NetworkEntry';
import { LoginSuccess } from '../shared/login/LoginSuccess';
import { AndroidTrustedAccessRedirectPage } from './AndroidTrustedAccessRedirectPage';

export const PortalRoutes = () => {
  return (
    <Fragment>
      <Route exact path="/" component={NetworkEntry} />
      <Switch>
        <Route path="/android-trusted-access" component={AndroidTrustedAccessRedirectPage} />
        <Route exact path="/loginsuccess" component={LoginSuccess} />
        <Route path="/:networkId" component={NetworkIdVerificationAndRoutes} />
      </Switch>
    </Fragment>
  );
};
