import React from 'react';
import { NetworkKeys } from '_redux/constants';
import { ToolButtonWithConfirmationModal } from '../_components/ToolButton';
import reboot_tool from 'images/tools/reboot_tool.svg';

export const Restart = ({ showDescription = false }) => (
  <ToolButtonWithConfirmationModal
    toolKey={NetworkKeys.Restart}
    title="Reboot Device"
    description="Reboot your device remotely"
    showDescription={showDescription}
    svgIcon={reboot_tool}
    successTitle="Device Restarting"
    helperDescription="This will clear the passcode from your device"
    buttonText="Restart"
  />
);
