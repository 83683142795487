import React from 'react';
import { useLocation } from 'react-router-dom';
import { Page } from 'enroll/_components/Page';
import SMLogo from 'images/SM_logo@2x.png';
import { Button, Row } from 'react-bootstrap';
import { useSupportedFeaturesQuery } from '_redux/networkInfo';
import { LoadingSpinnerBox } from '_components/loadingSpinner/LoadingSpinner';

/*
 * Windows Autopilot Mdm enrollments will land on this page as the terms of use.
 * The terms of use informs users of the ways in which their organization can
 * control their device, not SM. This happens prior to the enrollment phase and both
 * collects consent and allow us to pass enrollment information to the subsequent
 * Discovery Service.
 *
 * The following parameters are passed in the query string:
 *   - redirect_uri: After the user accepts or rejects the Terms of Use, the user is redirected to this URL.
 *   - client-request-id: A GUID that is used to correlate logs for diagnostic and debugging purposes. You use this parameter to log or trace the state of the enrollment request to help find the root cause in case of failures.
 *   - api-version: Specifies the version of the protocol requested by the client. This provides a mechanism to support version revisions of the protocol.
 *   - mode: Specifies that the device is corporate owned when mode=azureadjoin. This parameter is not present for BYOD devices.
 *
 * A bearer access token issued by Azure AD and then passed in the authorization header of the HTTP request. Here is a typical format: Authorization: Bearer CI6MTQxmCF5xgu6yYcmV9ng6vhQfaJYw…. The following claims are expected
 * in the access token passed by Windows to the Terms of Use endpoint:
 *   - Object ID: Identifier of the user object corresponding to the authenticated user.
 *   - UPN: A claim containing the user principal name (UPN) of the authenticated user.
 *   - TID: A claim representing the tenant ID of the tenant.
 *   - Resource: A sanitized URL representing the MDM application.
 *
 * Terms of Use Content
 *   - Accept: the user accepts the Terms of Use and proceeds with enrollment.
 *   - Decline: the user declines and stops the enrollment process.
 * The Terms of Use content must be consistent with the theme used for the other pages rendered during this process.
 *
 * Terms of Use Processing Logic
 * The user is redirected to the TOU page during the OOBE or from the Setting flow. From here they have generally have two options, accept or decline:
 *   User clicks on the Accept button - Redirect to the URI specified by the redirect_uri parameter in the incoming request. The following query string parameters are expected:
 *     - IsAccepted: This mandatory Boolean must be set to true.
 *     - OpaqueBlob: Required parameter if the user accepts. This can be used to make some information available to the enrollment endpoint. The value persisted here is made available unchanged at the enrollment endpoint. Might
 *       be useful to add user information here/correlate some info. ex. ms-appx-web://MyApp1/ToUResponse?OpaqueBlob=value&IsAccepted=true
 *   User clicks on the Decline button - again, redirect to the URI specified in redirect_uri in the incoming request. The following query string parameters are expected:
 *     - IsAccepted - This mandatory Boolean must be set to false. This also applies if the user skipped the Terms of Use.
 *     - OpaqueBlob - This parameter is not expected to be used because the enrollment is stopped with an error message displayed to the user.
 *
 * It's also recommended that the client-request-id parameter is sent in the query string as part of the redirect response (and for all requests, as it can be used to debug and log).
 */

export function WindowsAutopilotTermsOfUsePage({ match }) {
  const domain = match.params.networkId;
  const autopilotSupportedQ = useSupportedFeaturesQuery(['has_pcc_windows_mdm_autopilot'], domain);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirectUri = params.get('redirect_uri');
  const opaqueBlob = {
    enrollmentString: domain,
    clientRequestId: params.get('client-request-id'),
    apiVersion: params.get('api-version'),
    mode: params.get('mode'),
    windowsApiVersion: params.get('windows_api_version'),
  };

  const redirect = (url: string) => {
    window.location.assign(url);
  };

  if (autopilotSupportedQ.isSuccess && autopilotSupportedQ.data['has_pcc_windows_mdm_autopilot']) {
    if (redirectUri) {
      return (
        <Page title="Terms of Use" header="Terms of Use" iconSrc={SMLogo}>
          <div>
            {/* Terms of Use */}
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Aliquam malesuada bibendum arcu vitae.
              Blandit volutpat maecenas volutpat blandit. Dictum varius duis at consectetur. Sit
              amet dictum sit amet justo donec. Fermentum dui faucibus in ornare quam viverra orci.
              Ullamcorper a lacus vestibulum sed. Quam pellentesque nec nam aliquam sem et tortor
              consequat. Nam libero justo laoreet sit. Mollis nunc sed id semper risus in. Venenatis
              tellus in metus vulputate eu scelerisque felis imperdiet. Habitant morbi tristique
              senectus et netus et malesuada fames ac. Sodales ut etiam sit amet nisl purus in. In
              cursus turpis massa tincidunt dui ut ornare lectus. Etiam sit amet nisl purus in.
              Nullam vehicula ipsum a arcu cursus vitae.
            </p>
            <p>
              Ipsum consequat nisl vel pretium lectus quam id leo in. Dignissim cras tincidunt
              lobortis feugiat vivamus at augue. Convallis posuere morbi leo urna molestie at
              elementum. Ut pharetra sit amet aliquam id diam maecenas ultricies mi. Eleifend mi in
              nulla posuere. Porttitor eget dolor morbi non. Duis at tellus at urna condimentum
              mattis pellentesque id. Duis ultricies lacus sed turpis tincidunt. Tempor commodo
              ullamcorper a lacus vestibulum sed arcu non. Eu lobortis elementum nibh tellus. Diam
              sollicitudin tempor id eu nisl nunc mi ipsum faucibus. Quam lacus suspendisse faucibus
              interdum posuere. Sit amet justo donec enim diam vulputate ut pharetra. Quam elementum
              pulvinar etiam non quam lacus suspendisse. Laoreet sit amet cursus sit amet. Nisi
              scelerisque eu ultrices vitae auctor. Tellus molestie nunc non blandit massa enim nec
              dui nunc. Ultrices sagittis orci a scelerisque purus semper eget duis at. Fermentum
              posuere urna nec tincidunt praesent. Nulla at volutpat diam ut venenatis.
            </p>

            {/* Accept and Decline buttons */}
            <Row className="justify-content-center mt-3">
              <Button
                onClick={() =>
                  redirect(
                    `https://example.com?IsAccepted=true&OpaqueBlob=${encodeURIComponent(
                      JSON.stringify(opaqueBlob),
                    )}`,
                  )
                }
                variant="primary"
                className="m-2"
              >
                Accept
              </Button>
              <Button
                onClick={() => redirect(`${redirectUri}?IsAccepted=false`)}
                variant="secondary"
                className="m-2"
              >
                Decline
              </Button>
            </Row>
          </div>
        </Page>
      );
    } else {
      return (
        <Page title="Terms of Use" header="Terms of Use" iconSrc={SMLogo}>
          <div>
            <p>Something went wrong. Please restart and try again.</p>
          </div>
        </Page>
      );
    }
  } else if (autopilotSupportedQ.isLoading) {
    return <LoadingSpinnerBox />;
  } else {
    return (
      <Page title="Terms of Use" header="Terms of Use" iconSrc={SMLogo}>
        <div>
          <p>
            This feature is not supported for your Organization. Please contact a network
            administrator.
          </p>
        </div>
      </Page>
    );
  }
}
