import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UserIdVerificationAndRoutes } from './userId';
import { PasswordResetRequestPage } from './password-reset-request';
import { PasswordResetPage } from './password-reset';
import {
  getNetworkInfoErrorStatus,
  fetchNetworkRequirements,
  getAuthType,
} from '_redux/networkInfo';
import { Redirect, Route, Switch } from 'react-router';
import { LoginContainer } from './Login';

export const NetworkIdVerificationAndRoutes = ({ match }) => {
  const { networkId } = match.params;

  const dispatch = useDispatch();
  const authType = useSelector(getAuthType);
  const invalidNetwork = useSelector(getNetworkInfoErrorStatus);

  useEffect(() => {
    if (networkId && networkId.length && !authType) dispatch(fetchNetworkRequirements(networkId));
  }, [networkId, dispatch, authType]);

  if (invalidNetwork) {
    return <Redirect push to={{ pathname: '/', state: { networkId } }} />;
  } else if (authType) {
    return (
      <Switch>
        <Route exact path="/:networkId" component={LoginContainer} />
        <Route
          exact
          path="/:networkId/password-reset-request"
          component={PasswordResetRequestPage}
        />
        <Route exact path="/:networkId/password-reset" component={PasswordResetPage} />
        <Route path="/:networkId/:userId" component={UserIdVerificationAndRoutes} />
      </Switch>
    );
  } else {
    return null;
  }
};
