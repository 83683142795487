import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { Page } from 'enroll/_components/Page';
import SMLogo from 'images/SM_logo@2x.png';
import { NetworkIdForm } from 'shared/networkIdentification/NetworkIdForm';
import { Alert } from 'react-bootstrap';

export const NetworkIdEntryPage = ({ location }) => {
  const invalidNetworkId = (location && location.state && location.state.invalidNetworkId) || '';
  const authExpired = (location && location.state && location.state.authExpired) || false;
  const [submittedNetworkId, setSubmittedNetworkId] = useState('');

  if (!!submittedNetworkId) {
    return (
      <Redirect
        push
        to={{
          pathname: `/${submittedNetworkId}`,
          search: location.search,
        }}
      />
    );
  }

  return (
    <Page
      title="Network: Systems Manager enrollment"
      iconSrc={SMLogo}
      header="Enroll in Systems Manager"
    >
      <p>
        Secure your device and start using your company or school’s secure Wi-Fi, network access, or
        other features.
      </p>
      <h3>Network ID</h3>
      <p>Enter the Network ID given to you by your administrator.</p>
      {authExpired && <Alert variant="warning">Your session has expired. Please try again.</Alert>}
      {invalidNetworkId && <Alert variant="danger">This is not a valid Network ID.</Alert>}
      <NetworkIdForm
        onSubmit={(networkId: string) => setSubmittedNetworkId(networkId)}
        initialNetworkId={invalidNetworkId}
        submitButtonLabel="Start"
      />
    </Page>
  );
};
