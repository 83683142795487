import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

export const RemoveSlashAndRedirect = () => {
  const location = useLocation();
  const path = location.pathname;

  if (path !== '/' && path[path.length - 1] === '/') {
    const altered_path = path.substring(0, path.length - 1);
    return (
      <Redirect
        to={{
          pathname: altered_path,
          hash: location.hash,
          state: location.state,
          search: location.search,
        }}
      />
    );
  }

  return null;
};
