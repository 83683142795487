import React, { Fragment } from 'react';
import { PreAuthNav } from 'portal-auth/_components/PreAuthNav';
//import { Container, Row, Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

export const PasswordResetSuccessPage = ({ networkId }) => {
  return (
    <Fragment>
      <PreAuthNav />
      <div className="text-center my-5">
        <h1 className="my-4">Password has been reset!</h1>
        <LinkContainer to={`/${networkId}`}>
          <Button block variant="link">
            Return to login page
          </Button>
        </LinkContainer>
      </div>
    </Fragment>
  );
};
