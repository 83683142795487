import React from 'react';
import { NetworkKeys } from '_redux/constants';
import { ToolButtonWithConfirmationModal } from '../_components/ToolButton';
import checkin_tool from 'images/tools/checkin_tool.svg';

export const Sync = ({ showDescription = false }) => (
  <ToolButtonWithConfirmationModal
    toolKey={NetworkKeys.CheckIn}
    title="Sync Device"
    successText="Device synced."
    description="Refresh device details"
    showDescription={showDescription}
    svgIcon={checkin_tool}
    successTitle="Device Synced"
    helperDescription="This will check in your device"
    buttonText="Check in"
  />
);
