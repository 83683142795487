import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { Icon } from '_components/Icon';

export const AppSearchBar = ({ onChange }) => {
  return (
    <InputGroup className="align-items-center">
      <InputGroup.Prepend>
        <InputGroup.Text>
          <Icon glyph="search" height="1.5rem" />
        </InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl placeholder="Search" onChange={event => onChange(event.target.value)} />
    </InputGroup>
  );
};
