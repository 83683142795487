import { createSlice, createSelector } from 'redux-starter-kit';
import { SliceNames, NetworkKeys } from './constants';
import { sspApi } from '_utils/network';
import { startGet, endRejected, endWithResponse, checkResponseForError } from './network';

const initialState = {
  networkId: '',
  max_check_time: 60,
};

const mdmEnrollSlice = createSlice({
  slice: SliceNames.MdmEnroll,
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    done: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  actions,
  reducer,
  selectors: { getMdmEnroll },
} = mdmEnrollSlice;

export const getNetworkId = createSelector([getMdmEnroll], mdmInfo => mdmInfo.networkId);

export const getProfileStatus = createSelector([getMdmEnroll], mdmInfo => mdmInfo.profile_status);

export const getEnrollmentUrl = createSelector([getMdmEnroll], mdmInfo => mdmInfo.enroll_url);

export const getEnrolled = createSelector([getMdmEnroll], mdmInfo => mdmInfo.is_enrolled);

export const getShardUrl = createSelector([getMdmEnroll], mdmInfo => mdmInfo.shard_url);

// Fetch backend
const { done } = actions;

export const checkEnrolled = () => async (dispatch, getState) => {
  const key = NetworkKeys.CheckEnrolled;
  dispatch({ ...startGet(key, false) });
  setTimeout(async () => {
    try {
      const { networkId, auth_token } = getState()[SliceNames.MdmEnroll];
      let { max_check_time } = getState()[SliceNames.MdmEnroll];
      const org_id = getState()[SliceNames.MdmEnroll].is_enrolled_data.id;
      const enrolling_token = getState()[SliceNames.MdmEnroll].is_enrolled_data.enrolling_token;
      let searchParams = {
        id: org_id,
        enrolling_token: enrolling_token,
      };
      if (auth_token) {
        searchParams['auth_token'] = auth_token;
      }
      const response = await sspApi.get(`${networkId}/check_enrolled`, {
        headers: {
          accept: 'application/json',
        },
        searchParams: searchParams,
      });
      const data = await response.json();
      max_check_time -= 1;
      const { error, errorData } = checkResponseForError(data);
      const defaultAction = endWithResponse(key, response, errorData);
      if (!error) {
        if (data.enrolled) {
          dispatch({
            ...defaultAction,
            ...done({ is_enrolled: true }),
          });
        } else {
          dispatch({ ...defaultAction });
          if (max_check_time >= 0) {
            dispatch({ ...done({ max_check_time: max_check_time }) });
            dispatch(checkEnrolled());
          }
        }
      } else {
        console.log(errorData);
      }
    } catch (e) {
      dispatch(endRejected(key, e));
    }
  }, 2000);
};

export default reducer;
