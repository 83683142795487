import React from 'react';

import { OAuthLoginForm } from 'shared/login/OAuthLoginForm';
import { oAuthEndpoint } from '../oAuthEndpoint';

/*
 * Note: there are two definitions of an auth token on this page:
 * (1) An ID token returned by the OAuth window after a user successfully
 * authenticates. Used to validate login on our server.
 * (2) An auth token created on our server. Used to post a successful login to
 * the Windows settings client and identify the auth session during certificate
 * installation.
 */

export function EnrollmentOAuthLogin({ authType, enrollmentCode, oAuthSettings }) {
  const searchParams = new URLSearchParams(window.location.search);
  const pcc_machine_id = searchParams.get('pcc_machine_id');
  if (pcc_machine_id) {
    // The date at the end adds an expiration to the cookie to five minutes from now
    document.cookie = `pcc_machine_id=${pcc_machine_id};${new Date(Date.now() + 5 * 60 * 1000)}`;
  }
  const userEnrollment = searchParams.get('user_enrollment');

  return (
    <OAuthLoginForm
      authType={authType}
      authEndpoint={oAuthEndpoint(authType, enrollmentCode, oAuthSettings, userEnrollment)}
    />
  );
}
