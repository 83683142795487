import React from 'react';
import classname from 'classnames';

/**
 * The Icon component renders FontAwesome icons given their name
 * See all icons here: https://fontawesome.com/icons
 */

interface IconProps {
  glyph: string;
  title?: string;
  height?: string;
  className?: string;
}

export const Icon = (props: IconProps) => (
  <i
    className={classname(`fa fa-${props.glyph}`, props.className)}
    title={props.title || `${props.glyph} icon`}
    style={{ lineHeight: props.height }}
  />
);
