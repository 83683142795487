import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Page } from 'enroll/_components/Page';
import ExclamationIcon from 'images/red_exclamation_mark.svg';

export const AUTH_EXPIRED_ERROR = 'Expired or invalid authentication. Please try again.';

export const EnrollError = ({ location }) => {
  const message = new URLSearchParams(location.search).get('errmsg');
  if (!message) {
    return <Redirect to="/" />;
  } else if (message === AUTH_EXPIRED_ERROR) {
    return <Redirect to={{ pathname: `/`, state: { authExpired: true } }} />;
  } else {
    return (
      <Page title="Error: Systems Manager enrollment" iconSrc={ExclamationIcon} header="Error">
        <h3>{message}</h3>
        <Link to="/">Return to enrollment home page.</Link>
      </Page>
    );
  }
};
