import { useDispatch, useSelector } from 'react-redux';
import { getAuthType } from '_redux/networkInfo';
import { Alert, Container, Row, Col } from 'react-bootstrap';
import React, { Fragment } from 'react';
import { PreAuthNav } from 'portal-auth/_components/PreAuthNav';
import { AuthTypes, NetworkKeys } from '_redux/constants';
import { LoginError } from 'shared/login/LoginError';
import { Redirect } from 'react-router';
import { getUser, getLoginStatus } from '_redux/login';
import { OAuthLoginForm } from 'shared/login/OAuthLoginForm';
import { RegularLoginForm } from 'shared/login/RegularLoginForm';
import { SamlLoginForm } from 'shared/login/SamlLoginForm';
import { WindowsAppDownloadButton } from 'shared/WindowsAppDownloadButton';
import { getAuthEndpoint, getWindowsTrustedAccessEnabled } from '_redux/networkInfo';
import { handleLogin, getLoginError } from '_redux/login';
import { createNetworkSelectorFromKey } from '_redux/network';

const LoginSwitcher = ({ match }) => {
  const authType = useSelector(getAuthType);
  const endpoint = useSelector(getAuthEndpoint);
  const dispatch = useDispatch();

  switch (authType) {
    case AuthTypes.Google:
    case AuthTypes.Azure:
    case AuthTypes.OpenId:
      return <OAuthLoginForm authType={authType} authEndpoint={endpoint} />;
    case AuthTypes.Meraki:
    case AuthTypes.AD:
      return (
        <RegularLoginForm
          authType={authType}
          enrollmentCode={match.params.networkId}
          onSubmit={({ email, password }) => {
            dispatch(handleLogin(email, password));
          }}
        />
      );
    case AuthTypes.Saml:
      return <SamlLoginForm authEndpoint={endpoint} />;
    default:
      return <Redirect push to="/" />;
  }
};

export const LoginContainer = ({ match, location }) => {
  const isLoggedIn = useSelector(getLoginStatus);
  const user = useSelector(getUser);
  const loginError = useSelector(getLoginError);
  const fetchUserInfo = useSelector(createNetworkSelectorFromKey(NetworkKeys.FetchUser));
  const isWindowsDevice = window.navigator.userAgent.includes('Windows');
  const enableDownloadLink = useSelector(getWindowsTrustedAccessEnabled) && isWindowsDevice;
  const params = new URLSearchParams(window.location.search);
  const errorDesc = params.get('error_description');

  if (isLoggedIn && user && Object.entries(user).length) {
    // redirect if logged in
    if (location.state && location.state.referer) {
      // go back to previous location
      return <Redirect push to={location.state.referer} />;
    }
    return <Redirect to={`/${match.params.networkId}/${encodeURIComponent(user.id)}`} />;
  }

  return (
    <Fragment>
      <PreAuthNav />
      <Container>
        <Row className="justify-content-md-center">
          <Col md={7} lg={5}>
            <h1 className="m-4 text-center">Self Service Portal Login</h1>
            <LoginError error={loginError || fetchUserInfo.error || errorDesc} />
            <LoginSwitcher match={match} />
            {enableDownloadLink && (
              <Col md={10} className="m-auto">
                <Alert
                  variant="primary"
                  className="display-flex flex-column mt-5 text-center"
                  style={{ fontSize: 11 }}
                >
                  <span>Would you like to connect to your organization's Wi-Fi?</span>
                  <br />
                  <span>
                    To register your Windows device, download and launch the Meraki Trusted Access
                    app.
                  </span>
                  <WindowsAppDownloadButton />
                </Alert>
              </Col>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
