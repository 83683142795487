import React from 'react';
import { EnrollmentCardRestrictedBody } from 'portal-dashboard/enroll/_components/EnrollmentCard';

export const IOSInstructions = () => {
  return (
    <EnrollmentCardRestrictedBody>
      <ol>
        <li>
          Tap <b>Allow</b> at the prompt to download the configuration profile.
        </li>
        <li>
          Open the <b>Settings</b> app and tap on <b>Profile Downloaded</b> near the top of the
          page.
        </li>
        <li>
          In the top right hand corner, tap <b>Install</b>. Then, follow the on-screen prompts to
          install the configuration.
        </li>
        <li>
          Tap <b>Done</b> on the profile installation confirmation screen.
        </li>
      </ol>
    </EnrollmentCardRestrictedBody>
  );
};
