import { sspApi } from '_utils/network';
import { startPost, endWithResponse, endRejected } from './network';
import { SliceNames } from './constants';

export const getToolRequestState = (state, key) => {
  let success = null;
  let error = null;
  const networkSlice = state['network'];
  if (networkSlice[key] && networkSlice[key].success !== undefined) {
    success = networkSlice[key].success;
    error = networkSlice[key].error;
  }

  return { success, error };
};

export const sendToolRequest = (key, deviceId, json = null) => async dispatch => {
  const url = `devices/tools/${deviceId}/${key.toLowerCase()}`;
  dispatch({ ...startPost(key, true) });
  try {
    const response = await sspApi.post(url, { json });
    const data = await response.clone().json();

    const toolResponse = response.ok
      ? { success: data.success, error: data.error }
      : { success: false, error: (data && data.error) || 'There was an error making the request' };
    dispatch(endWithResponse(key, response, toolResponse));
  } catch (e) {
    dispatch({ ...endRejected(key, e) });
  }
};

export const getAvailableTools = (state, deviceId) => {
  const device = state[SliceNames.Devices].managed_devices[deviceId];
  return device.supported_tools;
};
