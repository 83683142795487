import React from 'react';
import { EnrollmentCardRestrictedBody } from 'portal-dashboard/enroll/_components/EnrollmentCard';

export const MacInstructions = ({ profileName }) => {
  return (
    <EnrollmentCardRestrictedBody>
      <ol>
        <li>Find the .mobileconfig file that was just downloaded and open it.</li>
        <li>
          In the <b>Profiles</b> dialog box, when asked to install "{profileName}", click{' '}
          <b>Install</b>.
        </li>
        <li>Enter your password or use Touch ID to authorize the change.</li>
      </ol>
    </EnrollmentCardRestrictedBody>
  );
};
