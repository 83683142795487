import { createSlice, createSelector } from 'redux-starter-kit';
import { SliceNames, NetworkKeys, NetworkStates } from './constants';
import {
  startGet,
  endWithResponse,
  checkResponseForError,
  endRejected,
  createNetworkSelectorFromKey,
} from './network';
import { sspApi } from '_utils/network';

const initialConfigState = { trusted_access_config_ids: [], trusted_access_configs: {} };

function normalizeTrustedAccessConfigs(configsArr) {
  const trusted_access_configs = {};
  configsArr.forEach(config => {
    trusted_access_configs[config.id] = config;
  });
  return { trusted_access_configs, trusted_access_config_ids: Object.keys(trusted_access_configs) };
}

const trustedAccessConfigsSlice = createSlice({
  initialState: initialConfigState,
  slice: SliceNames.TrustedAccessConfigs,
  reducers: {
    reset: () => initialConfigState,
    resetAndInsert: (_state, { payload }) => normalizeTrustedAccessConfigs(payload),
  },
});

export default trustedAccessConfigsSlice.reducer;
export const { slice, reducer, actions } = trustedAccessConfigsSlice;

export const fetchTrustedAccessConfigs = () => async dispatch => {
  const key = NetworkKeys.FetchTAConfigs;
  dispatch({ ...startGet(key, true) });

  try {
    const response = await sspApi.get(`trusted_access_configs`, {
      headers: {
        accept: 'application/json',
      },
    });
    const data = await response.json();
    const { error, errorData } = checkResponseForError(data);
    const defaultAction = endWithResponse(key, response, errorData);

    if (!error) {
      dispatch({
        ...defaultAction,
        ...actions.resetAndInsert(data),
      });
    } else dispatch(defaultAction);
  } catch (e) {
    dispatch(endRejected(key, e)); // TODO: what do we do if rejected?
  }
};

export const downloadMobileConfig = (
  deviceId,
  trustedAccessConfigId,
  delay = 0,
) => async dispatch => {
  const key = NetworkKeys.DownloadTAConfig;
  dispatch({ ...startGet(key, false) });
  setTimeout(async () => {
    try {
      const response = await sspApi.get(
        `user_access_devices/${deviceId}/download_config/${trustedAccessConfigId}`,
        {
          headers: {
            accept: 'application/x-apple-aspen-config; charset=utf-8; application/x-wifi-config',
          },
        },
      );
      if (response.ok) {
        const blob = await response.blob();
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'trusted_access';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        dispatch(endWithResponse(key, response));
      } else {
        const data = await response.clone().json();
        const { errorData } = checkResponseForError(data);
        dispatch(endWithResponse(key, response, errorData));
      }
    } catch (e) {
      dispatch(endRejected(key, e));
    }
  }, delay);
};

//
// selectors
export const getTrustedAccessConfigIds = state =>
  state[SliceNames.TrustedAccessConfigs].trusted_access_config_ids;

export const getTrustedAccessConfig = (state, trusted_access_config_id) =>
  state[SliceNames.TrustedAccessConfigs].trusted_access_configs[trusted_access_config_id];

export const getTrustedAccessConfigInfo = createNetworkSelectorFromKey(NetworkKeys.FetchTAConfigs);

export const getTrustedAccessConfigDownloadInfo = createNetworkSelectorFromKey(
  NetworkKeys.DownloadTAConfig,
);

export const getTrustedAccessConfigError = createSelector(
  [getTrustedAccessConfigInfo],
  requestInfo => {
    return requestInfo && requestInfo.error ? requestInfo.error : '';
  },
);

export const getTrustedAccessConfigDownloadError = createSelector(
  [getTrustedAccessConfigDownloadInfo],
  requestInfo => {
    return requestInfo && requestInfo.error ? requestInfo.error : '';
  },
);

export const trustedAccessConfigDownloadErrorExists = createSelector(
  [getTrustedAccessConfigDownloadInfo],
  downloadConfigInfo =>
    downloadConfigInfo &&
    downloadConfigInfo.state === NetworkStates.Resolved &&
    !downloadConfigInfo.ok,
);
