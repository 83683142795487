import React, { useEffect, useRef } from 'react';
import { Redirect } from 'react-router';
import { LoadingSpinnerBox } from '_components/loadingSpinner/LoadingSpinner';

/*
 * The Windows 10 settings auth window will land on this page after the user
 * successfully logs in. We send a post to the redirect URL provided in the
 * search params when the window opened, at which point we expect the window to
 * be closed by the client.
 * https://docs.microsoft.com/en-us/windows/client-management/mdm/federated-authentication-device-enrollment
 */
export function WindowsSettingsEnrollmentPage({ history }) {
  const isRedirect = history && history.action === 'REPLACE';
  const formEl = useRef(null);
  useEffect(() => {
    if (isRedirect) {
      formEl.current.submit();
    }
  });
  if (!isRedirect) return <Redirect to="/" />;
  const params = new URLSearchParams(history.location.search);
  return (
    <React.Fragment>
      <LoadingSpinnerBox />
      <form ref={formEl} method="post" action="ms-app://windows.immersivecontrolpanel">
        <input type="hidden" name="wresult" value={params.get('token')} />
        <input style={{ display: 'none' }} type="submit" />
      </form>
    </React.Fragment>
  );
}
