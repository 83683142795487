import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import warningImage from 'images/warning.svg';

export const TrustedAccessDeviceLimitReached = () => (
  <Container className="my-auto">
    <Row className="justify-content-center text-center">
      <Col xs={4} md={2}>
        <Image src={warningImage} />
      </Col>
    </Row>

    <Row className="justify-content-center text-center">
      <Col>
        <h1>You've reached your device limit</h1>
      </Col>
    </Row>

    <Row className="justify-content-center">
      <Col xs={8} md={4}>
        To add a new device, you must first remove an existing device. You can remove a device on
        its details page.
      </Col>
    </Row>
  </Container>
);
