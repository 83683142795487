import React, { Fragment, useState } from 'react';
import { Col, Card, Button, Image, Modal, Container } from 'react-bootstrap';
import { Icon } from '_components/Icon';
import QRCode from 'qrcode.react';
import wpnStyles from './WPN.module.scss';

import deviceImage from 'images/devices.svg';
import copySvg from 'images/wpn/copy.svg';
import resetSvg from 'images/wpn/reset.svg';
import visibleSvg from 'images/wpn/visible.svg';
import hiddenSvg from 'images/wpn/hidden.svg';
import { SsidType } from '_types/ssid';
import { WPNPskGroupReset, WPNPskGroupCreate } from './WPNActions';

const WPNCardRow = ({ title, children }: { title?: any; children: any }) => (
  <div className={wpnStyles.cardSectionContainer}>
    {title && <div className={wpnStyles.cardSectionTitle}>{title}</div>}
    <div className={wpnStyles.cardSectionContent}>{children}</div>
  </div>
);

const WPNActivePskGroup = ({ ssidName, passphrase, showResetFlow }) => {
  const [isCensored, setCensored] = useState(true);
  const passphraseView = isCensored ? '●'.repeat(passphrase.length) : passphrase;

  return (
    <Fragment>
      <WPNCardRow>
        On each of your devices, find this Wi-Fi network and enter your unique password
      </WPNCardRow>
      <WPNCardRow title="Passphrase">
        <div className={wpnStyles.cardSectionPasscodeContainer}>
          <div>{passphraseView}</div>
          <div>
            <Button variant="link" onClick={showResetFlow}>
              <img src={resetSvg} alt="reset" />
            </Button>
            <Button variant="link" onClick={() => navigator.clipboard.writeText(passphrase)}>
              <img src={copySvg} alt="copy" />
            </Button>
            <Button variant="link" onClick={() => setCensored(!isCensored)}>
              {isCensored ? (
                <img src={visibleSvg} alt="show" />
              ) : (
                <img src={hiddenSvg} alt="hide" />
              )}
            </Button>
          </div>
        </div>
      </WPNCardRow>
      <WPNCardRow title="QR login">
        <QRCode value={`WIFI:T:WPA;S:${ssidName};P:${passphrase};;`} size={90} />
      </WPNCardRow>
    </Fragment>
  );
};

const WPNInactivePskGroup = ({ showCreateFlow }) => {
  return (
    <Fragment>
      <WPNCardRow>
        Create this group to connect your devices to this network and each other
      </WPNCardRow>
      <WPNCardRow>
        <Image src={deviceImage} className="d-block ml-auto mr-auto" />
      </WPNCardRow>
      <WPNCardRow>
        <Button block variant="outline-primary" onClick={showCreateFlow}>
          Create a device group
        </Button>
      </WPNCardRow>
    </Fragment>
  );
};

export const WPNSsid = ({ ssid }) => {
  const [showCreate, setShowCreate] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const closeModal = () => {
    setShowReset(false);
    setShowCreate(false);
  };

  const activePskGroup = ssid.psk_group;
  const inactivePskGroup = ssid.type === SsidType.PskGroup && !ssid.psk_group;

  return (
    <Col className={wpnStyles.cardContainer}>
      <Card body>
        <Card.Title className={wpnStyles.cardHeader}>Wi-Fi Personal Network Group</Card.Title>

        <Card.Title>
          <Icon glyph="wifi" /> {ssid.ssid || ssid.name}
        </Card.Title>

        {activePskGroup && (
          <WPNActivePskGroup
            ssidName={ssid.ssid || ssid.name}
            showResetFlow={() => setShowReset(true)}
            passphrase={ssid.psk_group.passphrase}
          />
        )}

        {inactivePskGroup && <WPNInactivePskGroup showCreateFlow={() => setShowCreate(true)} />}

        <Modal show={showCreate || showReset} onHide={closeModal} centered>
          <Modal.Body>
            <Container fluid>
              {showReset && <WPNPskGroupReset closeCb={closeModal} ssidId={ssid.id} />}
              {showCreate && <WPNPskGroupCreate closeCb={closeModal} ssidId={ssid.id} />}
            </Container>
          </Modal.Body>
        </Modal>
      </Card>
    </Col>
  );
};
