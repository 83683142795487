import React, { useEffect } from 'react';
import { Route } from 'react-router';
import { Fragment } from 'react';
import { EnrollTrustedAccessRoot } from './trusted-access';
import { EnrollPicker } from './EnrollPicker';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEnrollPermissions } from '_redux/enroll';
import { NetworkStates, NetworkKeys, DeviceTypes } from '_redux/constants';
import { createNetworkSelectorFromKey } from '_redux/network';
import { Helmet } from 'react-helmet';

const getNetworkEnrollInfo = createNetworkSelectorFromKey(NetworkKeys.FetchEnrollPermissions);

export const EnrollRoot = () => {
  const dispatch = useDispatch();
  const networkEnrollInfo = useSelector(getNetworkEnrollInfo);

  useEffect(() => {
    dispatch(fetchEnrollPermissions());
  }, [dispatch]);

  if (networkEnrollInfo.state !== NetworkStates.Resolved) {
    return <div>Pending...</div>;
  }
  return (
    <Fragment>
      <Helmet>
        <title>Add a device - SSP</title>
      </Helmet>
      <Route exact path="/:networkId/:userId/enroll" component={EnrollPicker} />
      <Route
        path={`/:networkId/:userId/enroll/${DeviceTypes.TA}`}
        component={EnrollTrustedAccessRoot}
      />
    </Fragment>
  );
};
