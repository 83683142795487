import React from 'react';
import { NetworkKeys } from '_redux/constants';
import { ToolButtonWithConfirmationModal } from '../_components/ToolButton';
import lost_mode_disabled_tool from 'images/tools/lost_mode_disabled_tool.svg';

export const LostModeDisabled = ({ showDescription = false }) => (
  <ToolButtonWithConfirmationModal
    toolKey={NetworkKeys.LostModeDisabled}
    title="Disable Lost Mode"
    description="Found your device? Turn off Lost Mode"
    showDescription={showDescription}
    svgIcon={lost_mode_disabled_tool}
    successTitle="Lost Mode Disabled"
    helperDescription="This will disable lost mode on your device"
    buttonText="Disable"
  />
);
