import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { LostTools, ManagedTools } from '../_toolButtons/ToolDisplay';

export const ToolsRoot = ({ match }) => {
  return (
    <Row className="my-5">
      <Col>
        <Row>
          <LostTools id={match.params.deviceId} />
        </Row>
        <Row className="mt-5">
          <Col>
            <h3 className="mb-3">Manage your device</h3>
            <ManagedTools id={match.params.deviceId} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
