import React from 'react';
import { CardDeck, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { getAvailableTools } from '_redux/tools';

// managed tools
import { Sync } from './Sync';
import { ClearPasscode } from './ClearPasscode';
import { LockDevice } from './LockDevice';
import { ResetWorkProfilePasscode } from './ResetWorkProfilePasscode';
import { Restart } from './Restart';
import { SendMessage } from './SendMessage';
import { Shutdown } from './Shutdown';
import { Wipe } from './Wipe';
import { AllToolsButton } from './AllToolsButton';

// lost tools
import { LostModeDisabled } from './LostModeDisabled';
import { EnableLostMode } from './EnableLostMode';
import { PlaySound } from './PlaySound';

export const SummaryTools = ({ id }) => {
  const {
    show_message,
    show_clear_passcode,
    show_checkin,
    show_reboot,
    show_wipe,
  } = useSelector(state => getAvailableTools(state, id));

  return (
    <Row className="ml-0">
      {show_message && <SendMessage />}
      {show_clear_passcode && <ClearPasscode />}
      {show_checkin && <Sync />}
      {show_reboot && <Restart />}
      {show_wipe && <Wipe />}
      <AllToolsButton />
    </Row>
  );
};

export const LostTools = ({ id }) => {
  const { show_lost_mode, show_sound } = useSelector(state => getAvailableTools(state, id));

  if (!show_lost_mode && !show_sound) return null;
  return (
    <Col>
      <h3 className="mb-3">Lost your device?</h3>
      <CardDeck style={{ display: 'flex' }}>
        {show_lost_mode && <LostModeDisabled showDescription />}
        {show_lost_mode && <EnableLostMode showDescription />}
        {show_sound && <PlaySound showDescription />}
      </CardDeck>
    </Col>
  );
};

export const ManagedTools = ({ id }) => {
  const {
    show_checkin,
    show_clear_passcode,
    show_reset_passcode,
    show_lock,
    show_reboot,
    show_message,
    show_shutdown,
    show_wipe,
  } = useSelector(state => getAvailableTools(state, id));

  return (
    <CardDeck style={{ display: 'flex' }}>
      {show_checkin && <Sync showDescription />}
      {show_clear_passcode && <ClearPasscode showDescription />}
      {show_lock && <LockDevice showDescription />}
      {show_reset_passcode && <ResetWorkProfilePasscode id={id} showDescription />}
      {show_reboot && <Restart showDescription />}
      {show_message && <SendMessage showDescription />}
      {show_shutdown && <Shutdown showDescription />}
      {show_wipe && <Wipe showDescription />}
    </CardDeck>
  );
};
