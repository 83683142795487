import React from 'react';
import { NetworkKeys } from '_redux/constants';
import { ToolButtonWithConfirmationModal } from '../_components/ToolButton';
import sound_tool from 'images/tools/sound_tool.svg';

export const PlaySound = ({ showDescription = false }) => (
  <ToolButtonWithConfirmationModal
    toolKey={NetworkKeys.LostModePlaySound}
    title="Play Sound"
    description="Sound an alarm on your device to help locate it"
    showDescription={showDescription}
    svgIcon={sound_tool}
    successTitle="Lost Device Sound Mode Activated"
    helperDescription="This will activate sound mode for your lost device"
    buttonText="Activate"
  />
);
