import React, { useState, useEffect } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

// This page lives at a route that matches the Android Trusted Access app deep
// link. If users have landed on this page from the Add device page, it means
// that they submitted the form from either:
// - an Android device that does NOT have the app installed, or
// - a non-Android device

// Our goals here:
// - avoid confusion by preventing users from reaching a "Not found" page at
//   this route, regardless of device type
// - facilitate app installation on Android devices by redirecting to the Play
//   Store app
// - complete Add device flow by redirecting to devices page in browser

// NOTE: There is potential for confusing behavior on this page on non-Android
// devices. See comments below for details.

export const AndroidTrustedAccessRedirectPage = () => {
  const [redirectNow, setRedirectNow] = useState(false);

  useEffect(() => {
    // Sleep before redirecting so user has time to read the redirect message.
    const id = setTimeout(() => {
      setRedirectNow(true);

      // Expected behavior:
      // - On Android devices: the Play Store app will open and the browser
      //   window location will not change.
      // - On non-Android devices: the browser window location will change.
      window.location.href =
        'http://play.google.com/store/apps/details?id=com.meraki.trustedaccess';
    }, 3000);

    return () => {
      clearTimeout(id);
    };
  }, []);

  if (redirectNow) {
    // Expected behavior:
    // - On Android devices: the Play Store app should have opened. In the
    //   browser, redirect to the devices page.
    // - On non-Android devices: there is a race condition between this Redirect
    //   and the window.location change above. This is unideal as the final
    //   browser location is not deterministic but as of right now I don't know of
    //   a more elegant, device-aware solution.
    const searchParams = new URLSearchParams(window.location.search);
    const enrollment_code = searchParams.get('enrollment_code');
    const user_id = searchParams.get('user_id');

    return <Redirect to={`/${enrollment_code}/${user_id}/devices`} />;
  }

  return (
    <Container className="flex-fill d-flex flex-column align-items-center justify-content-center min-vh-100">
      <h3>It seems like your device does not have the Meraki Trusted Access app installed.</h3>
      <p>You will now be redirected to the Google Play Store.</p>
      <Spinner animation="border" />
    </Container>
  );
};
