import React, { useState } from 'react';
import { useSupportedFeaturesQuery } from '_redux/networkInfo';
import { WaitWithSpinner } from 'enroll/_components/WaitWithSpinner';
import { getIsAmaConfigured } from 'enroll/api';
import { LegacyAndroidEnrollmentPage } from '../_components/LegacyAndroidEnrollmentPage';
import { GoogleAmaEnrollmentPage } from '../_components/GoogleAmaEnrollmentPage';
import { LoadingSpinnerBox } from '_components/loadingSpinner/LoadingSpinner';

const renderLegacy = () => {
  return <LegacyAndroidEnrollmentPage />;
};

export const AndroidEnrollmentPage = ({ match, history }) => {
  const domain = match.params.networkId;
  const googleAmaSupportedQ = useSupportedFeaturesQuery(['has_pcc_google_ama'], domain);
  const [isAmaConfigured, setIsAmaConfigured] = useState(null);

  if (googleAmaSupportedQ.isSuccess && googleAmaSupportedQ.data['has_pcc_google_ama']) {
    if (isAmaConfigured === null) {
      return (
        <WaitWithSpinner
          asyncFn={getIsAmaConfigured(domain)}
          onSuccess={(data: { is_configured?: boolean }) => {
            setIsAmaConfigured(data.is_configured);
          }}
          onFail={error => {
            const params = new URLSearchParams({
              errmsg: error.message,
            });
            history.push(`/error?${params.toString()}`);
          }}
        />
      );
    } else {
      return isAmaConfigured ? (
        <GoogleAmaEnrollmentPage domain={match.params.networkId} history={history} />
      ) : (
        renderLegacy()
      );
    }
  } else if (googleAmaSupportedQ.isLoading) {
    return <LoadingSpinnerBox />;
  } else {
    return renderLegacy();
  }
};
