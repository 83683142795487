import React from 'react';
import { Nav, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector } from 'react-redux';
import { getCustomColor } from '_redux/networkInfo';

// hack to skip prop check for variant prop
const NavVariant = props => <Nav {...props} />;

export const ManagedDevicePaneTabs = ({ deviceUrl }) => {
  const customColor = useSelector(getCustomColor);
  const navItemActiveStyle = { borderBottomColor: customColor };
  return (
    <Row className="border-bottom">
      <NavVariant variant="underline" className="">
        <Nav.Item>
          <LinkContainer
            activeStyle={navItemActiveStyle}
            to={`${deviceUrl}/summary`}
            eventKey="summary"
          >
            <Nav.Link>Summary</Nav.Link>
          </LinkContainer>
        </Nav.Item>
        <Nav.Item>
          <LinkContainer activeStyle={navItemActiveStyle} to={`${deviceUrl}/tools`}>
            <Nav.Link eventKey="tools">Tools</Nav.Link>
          </LinkContainer>
        </Nav.Item>
        <Nav.Item>
          <LinkContainer activeStyle={navItemActiveStyle} to={`${deviceUrl}/apps`}>
            <Nav.Link eventKey="apps">Apps</Nav.Link>
          </LinkContainer>
        </Nav.Item>
      </NavVariant>
    </Row>
  );
};
