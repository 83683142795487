import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestPasswordReset,
  hasPasswordResetRequestSucceeded,
  getPasswordResetRequestError,
} from '_redux/passwordReset';
import { PreAuthNav } from 'portal-auth/_components/PreAuthNav';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Formik } from 'formik';
import { FormEmail } from '_utils/formUtils';
import * as Yup from 'yup';

const PasswordResetSchema = Yup.object().shape({
  email: FormEmail,
});

export const PasswordResetRequestPage = ({ match }) => {
  const { networkId } = match.params;
  const dispatch = useDispatch();

  const passwordResetSucceeded = useSelector(hasPasswordResetRequestSucceeded);
  const passwordResetError = useSelector(getPasswordResetRequestError);

  return (
    <Fragment>
      <PreAuthNav />
      <Container>
        <Row className="justify-content-center text-center my-4">
          <Col>
            <h1 className="my-4">Forgot your password?</h1>
            <div>A password reset link will be sent to your email</div>
            {passwordResetSucceeded && (
              <div className="mt-4 text-success">
                If there is an account associated with the email address you entered, you will
                receive an email with password reset instructions
              </div>
            )}
          </Col>
        </Row>

        <Formik
          validateOnBlur
          validateOnChange={false}
          validationSchema={PasswordResetSchema}
          onSubmit={({ email }) => {
            dispatch(requestPasswordReset(email, networkId));
          }}
          initialValues={{
            email: '',
          }}
        >
          {({ handleSubmit, handleChange, values, errors, handleBlur, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Container>
                <Form.Group as={Row} className="my-4 justify-content-center">
                  <Col xs={12} md={4}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      isInvalid={(touched.email && !!errors.email) || passwordResetError}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email || passwordResetError}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Row className="justify-content-center">
                  <Col xs={6} md={2}>
                    <Button variant="primary" type="submit" block>
                      Send
                    </Button>
                  </Col>

                  <Col xs={6} md={2}>
                    <LinkContainer to={`/${match.params.networkId}`}>
                      <Button block variant="link">
                        Back
                      </Button>
                    </LinkContainer>
                  </Col>
                </Form.Row>
              </Container>
            </Form>
          )}
        </Formik>
      </Container>
    </Fragment>
  );
};
