import React from 'react';
import { Container, Button, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import deviceImage from '../../images/devices.svg';
import { Redirect } from 'react-router';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchSsids } from 'portal-dashboard/_queries/fetchSsids';

export const NoDevices = () => {
  const ssidsQ = useQuery('ssids', fetchSsids);
  const params = useParams<{ networkId: string; userId: string }>();

  if (ssidsQ.isSuccess && ssidsQ.data && ssidsQ.data.length) {
    return <Redirect to={`/${params.networkId}/${params.userId}/homepage`} />;
  }
  return (
    <Container className="flex-fill d-flex flex-column align-items-center justify-content-center">
      <Image src={deviceImage} />
      <h1 className="m-4">You have no devices.</h1>
      <LinkContainer to={'enroll'}>
        <Button variant="primary">Add a device</Button>
      </LinkContainer>
    </Container>
  );
};
