import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { Icon } from '_components/Icon';

const WINDOWS_TRUSTED_ACCESS_APP_LINK =
  'https://dl.meraki.net/TrustedAccess/Windows/TrustedAccess.AppInstaller';

export const WindowsAppDownloadButton = () => {
  return (
    <Col className="px-0 mt-3">
      <Button
        variant="primary"
        href={WINDOWS_TRUSTED_ACCESS_APP_LINK}
        data-testid="windows-download"
        style={{ fontSize: 11 }}
      >
        <Icon className="pr-1" glyph={'windows'} /> Download
      </Button>
    </Col>
  );
};
