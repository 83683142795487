import React from 'react';
import {
  CardContainer,
  Card,
  CardIcon,
  CardHeader,
  CardBody,
  CardFootnote,
} from 'enroll/_components/CardContainer';
import { PrivacyPolicyNotice } from 'enroll/PrivacyPolicyNotice';
import { Helmet } from 'react-helmet';

export function Page({ title, iconSrc, header, children }) {
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CardContainer>
        <Card>
          <CardIcon src={iconSrc} />
          <CardHeader>{header}</CardHeader>
          <CardBody>{children}</CardBody>
        </Card>
        <CardFootnote>
          <PrivacyPolicyNotice />
        </CardFootnote>
      </CardContainer>
    </React.Fragment>
  );
}
