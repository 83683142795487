import { createSlice, createSelector } from 'redux-starter-kit';
import { SliceNames, NetworkKeys } from './constants';
import {
  startGet,
  endWithResponse,
  checkResponseForError,
  endRejected,
  startPost,
  startPut,
  startDelete,
  createNetworkSelectorFromKey,
} from './network';
import { sspApi } from '_utils/network';
import { getAllApps } from './apps';
import {
  getTrustedAccessConfigDownloadError,
  getTrustedAccessConfigError,
} from './trustedAccessConfigs';

export interface AppInstallInfo {
  id: string;
  pcc_software_id: string;
  installed_version: string;
  installed_at: string;
  updated_at: string;
}

const TRUSTED_ACCESS_DISABLED_ERROR =
  'User is not configured for Trusted access. Please contact a network administrator.';

const initialDeviceState = {
  managed_devices: {},
  managed_device_ids: [],
  user_access_devices: {},
  user_access_device_ids: [],
};

const upsertEntity = (entityKey, state, payload) => {
  const idKey = payload.id;

  // get or create empty entity
  if (!state[`${entityKey}s`][idKey]) state[`${entityKey}s`][idKey] = {};
  const entity = state[`${entityKey}s`][idKey]; // this pluralization is dumb we should refactor

  Object.assign(entity, payload);

  // add to xyz_ids
  const idArr = state[`${entityKey}_ids`];
  if (!idArr.includes(idKey)) idArr.push(idKey);
};

const removeTrustedAccessDeviceFromStore = (state, idToRemove) => {
  const { user_access_device_ids, user_access_devices } = state;

  const remainingDeviceIds = user_access_device_ids.filter(id => id !== idToRemove);
  const remainingDevices = {};
  remainingDeviceIds.forEach(id => (remainingDevices[id] = user_access_devices[id]));
  return {
    ...state,
    user_access_devices: remainingDevices,
    user_access_device_ids: remainingDeviceIds,
  };
};

const devicesSlice = createSlice({
  initialState: initialDeviceState,
  slice: SliceNames.Devices,
  reducers: {
    reset: () => initialDeviceState,
    resetAndInsert: (state, { payload }) => {
      Object.assign(state, initialDeviceState);

      payload.managed_devices.forEach(managed_device => {
        upsertEntity('managed_device', state, managed_device);
      });

      payload.user_access_devices.forEach(user_access_device => {
        upsertEntity('user_access_device', state, user_access_device);
      });
    },
    upsertTrustedAccessDevice: (state, { payload }) => {
      upsertEntity('user_access_device', state, payload);
    },
    upsertManagedDevice: (state, { payload }) => {
      upsertEntity('managed_device', state, payload);
    },
    removeTrustedAccessDeviceSuccess: (state, { payload }) =>
      removeTrustedAccessDeviceFromStore(state, payload),
  },
});

export default devicesSlice.reducer;
export const { slice, reducer, actions } = devicesSlice;

export const fetchDevices = () => async dispatch => {
  const key = NetworkKeys.FetchDevices;
  dispatch({ ...startGet(key, true) });

  try {
    const response = await sspApi.get(`devices`);
    const defaultAction = endWithResponse(key, response);
    if (response.ok) {
      const data = await response.json();
      dispatch({ ...defaultAction, ...actions.resetAndInsert(data) });
    } else {
      dispatch(defaultAction);
    }
  } catch (e) {
    dispatch(endRejected(key, e));
  }
};

//
// actions
export const enrollTrustedAccessDevice = (name, type) => async dispatch => {
  const key = NetworkKeys.RegisterDevice;
  dispatch({ ...startPost(key, true) });

  try {
    const response = await sspApi.post(`user_access_devices`, {
      headers: {
        accept: 'application/json',
      },
      searchParams: {
        name,
        system_type: type,
      },
    });

    if (response.ok) {
      const { user_access_device } = await response.json();
      dispatch({
        ...endWithResponse(key, response),
        ...actions.upsertTrustedAccessDevice(user_access_device),
      });
    } else {
      const error = await response.json();
      dispatch(endWithResponse(key, response, error));
    }
  } catch (e) {
    dispatch(endRejected(key, e)); // TODO: what do we do if rejected?
  }
};

export const fetchTrustedAccessDevice = user_access_device_id => async dispatch => {
  const key = NetworkKeys.FetchTADevice;
  dispatch({ ...startGet(key, true) });

  try {
    const response = await sspApi.get(`user_access_devices/${user_access_device_id}`, {
      headers: {
        accept: 'application/json',
      },
    });
    const data = await response.json();
    const { error, errorData } = checkResponseForError(data);
    const defaultAction = endWithResponse(key, response, errorData);

    if (!error) {
      dispatch({
        ...defaultAction,
        ...actions.upsertTrustedAccessDevice({ ...data, complete: true }),
      });
    } else dispatch(defaultAction);
  } catch (e) {
    dispatch(endRejected(key, e)); // TODO: what do we do if rejected?
  }
};

export const updateUserAccessDevice = (deviceId: number, name: string) => async dispatch => {
  const key = NetworkKeys.UpdateDevice;
  dispatch({ ...startPut(key, true) });

  try {
    const response = await sspApi.put(`user_access_devices/${deviceId}`, {
      headers: {
        accept: 'application/json',
      },
      searchParams: {
        name,
      },
    });

    const data = await response.json();
    const { error, errorData } = checkResponseForError(data);
    const defaultAction = endWithResponse(key, response, errorData);

    if (!error) {
      dispatch({
        ...defaultAction,
        ...actions.upsertTrustedAccessDevice({ ...data.user_access_device, complete: true }),
      });
    } else {
      dispatch(defaultAction);
    }
  } catch (e) {
    dispatch(endRejected(key, e)); // TODO: what do we do if rejected?
  }
};

export const deleteUserAccessDevice = (deviceId: number) => async dispatch => {
  const key = NetworkKeys.DeleteDevice;
  dispatch(startDelete(key, true));

  try {
    const response = await sspApi.delete(`user_access_devices/${deviceId}`, {
      headers: {
        accept: 'application/json',
      },
      searchParams: {
        id: deviceId,
      },
    });

    const data = await response.json();
    const { error, errorData } = checkResponseForError(data);
    const defaultAction = endWithResponse(key, response, errorData);

    if (!error) {
      dispatch({ ...defaultAction, ...actions.removeTrustedAccessDeviceSuccess(deviceId) });
    } else {
      dispatch(defaultAction);
    }
  } catch (e) {
    dispatch(endRejected(key, e)); // TODO: what do we do if rejected?
  }
};

export const fetchManagedDevice = managed_device_id => async dispatch => {
  const key = NetworkKeys.FetchManagedDevice;
  dispatch({ ...startGet(key, true) });

  try {
    const response = await sspApi.get(`devices/${managed_device_id}`, {
      headers: {
        accept: 'application/json',
      },
    });
    const defaultAction = endWithResponse(key, response);

    if (response.ok) {
      const data = await response.json();
      dispatch({
        ...defaultAction,
        ...actions.upsertManagedDevice({ ...data, complete: true }),
      });
    } else dispatch(defaultAction);
  } catch (e) {
    dispatch(endRejected(key, e)); // TODO: what do we do if rejected?
  }
};

//
// user access device selectors
export const getTrustedAccessDeviceIds = state => state[SliceNames.Devices].user_access_device_ids;

export const getTrustedAccessDevice = (state, user_access_device_id) => {
  const device = state[SliceNames.Devices].user_access_devices[user_access_device_id];
  return device;
};

export const getTrustedAccessDeviceCount = createSelector(
  [getTrustedAccessDeviceIds],
  deviceIds => deviceIds.length,
);

export const getTrustedAccessDeviceComplete = (state, user_access_device_id) => {
  const device = getTrustedAccessDevice(state, user_access_device_id);
  if (!device || !device.complete) return null;
  return device;
};

export const getEnrollUserAccessDeviceInfo = createNetworkSelectorFromKey(
  NetworkKeys.RegisterDevice,
);

export const getFetchTrustedAccessDevicesInfo = createNetworkSelectorFromKey(
  NetworkKeys.FetchTADevice,
);

export const getUpdateUserAccessDeviceInfo = createNetworkSelectorFromKey(NetworkKeys.UpdateDevice);

export const getEnrollUserAccessDeviceError = createSelector(
  [getEnrollUserAccessDeviceInfo],
  requestInfo => {
    return requestInfo && requestInfo.error ? requestInfo.error : '';
  },
);

export const getFetchTrustedAccessDevicesError = createSelector(
  [getFetchTrustedAccessDevicesInfo],
  requestInfo => {
    return requestInfo && requestInfo.error ? requestInfo.error : '';
  },
);

export const getUpdateUserAccessDeviceError = createSelector(
  [getUpdateUserAccessDeviceInfo],
  requestInfo => {
    return requestInfo && requestInfo.error ? requestInfo.error : '';
  },
);

export const isTrustedAccessDenied = createSelector(
  [
    getTrustedAccessConfigError,
    getFetchTrustedAccessDevicesError,
    getUpdateUserAccessDeviceError,
    getTrustedAccessConfigDownloadError,
  ],
  (
    trustedAccessConfigError,
    fetchTrustedAccessDevicesError,
    updateUserAccessDeviceError,
    trustedAccessConfigDownloadError,
  ) => {
    if (trustedAccessConfigError === TRUSTED_ACCESS_DISABLED_ERROR) {
      return true;
    } else if (fetchTrustedAccessDevicesError === TRUSTED_ACCESS_DISABLED_ERROR) {
      return true;
    } else if (updateUserAccessDeviceError === TRUSTED_ACCESS_DISABLED_ERROR) {
      return true;
    } else if (trustedAccessConfigDownloadError === TRUSTED_ACCESS_DISABLED_ERROR) {
      return true;
    }
    return false;
  },
);

// managed device selectors
export const getManagedDeviceIds = state => state[SliceNames.Devices].managed_device_ids;

export const getManagedDevice = (state, managed_device_id) => {
  const device = state[SliceNames.Devices].managed_devices[managed_device_id];
  return device;
};

export const getManagedDeviceFriendlyName = (state, managed_device_id) => {
  const device = getManagedDevice(state, managed_device_id);
  if (!device) return null;
  return device.name || device.system_name || device.friendly_system_model || 'Unknown Device';
};

export const getManagedDeviceSecurityPolicies = (state, managed_device_id) => {
  const device = getManagedDevice(state, managed_device_id);
  if (!device) return null;
  return Object.keys(device.security_requirements);
};

export const getManagedDeviceViolatingPolicies = (state, managed_device_id) => {
  const device = getManagedDevice(state, managed_device_id);
  if (!device) return null;
  const allPolicies = Object.keys(device.security_requirements);
  return allPolicies.filter(policy => !device.security_requirements[policy]);
};

// app related selectors
export const getAppsForManagedDevice = (state, managed_device_id) => {
  const device = getManagedDevice(state, managed_device_id);
  return device.apps;
};

export const getAppInstallInfo = (state, managed_device_id, app_id) => {
  const apps = getAppsForManagedDevice(state, managed_device_id);
  return apps[app_id];
};

export const getAppsForManagedDeviceAsSortedList = (state, managed_device_id) => {
  const device_apps = getAppsForManagedDevice(state, managed_device_id);
  const allApps = getAllApps(state);
  return Object.values(device_apps).sort((a1: AppInstallInfo, a2: AppInstallInfo) => {
    // sort missing apps to the end
    // sort apps within missing and installed groups alphabetically
    if (a1.installed_at === null && a2.installed_at !== null) {
      return 1;
    } else if (a2.installed_at === null && a1.installed_at !== null) {
      return -1;
    } else {
      return allApps[a1.id].name.localeCompare(allApps[a2.id].name);
    }
  });
};

export const getFilteredAppIdsForManagedDevice = (state, managed_device_id, filterTerm) => {
  const apps = getAppsForManagedDeviceAsSortedList(state, managed_device_id);
  const allApps = getAllApps(state);
  const ids = [];
  apps.forEach((installInfo: AppInstallInfo) => {
    if (allApps[installInfo.id].name.toLowerCase().includes(filterTerm)) {
      ids.push(installInfo.id);
    }
  });
  return ids;
};
