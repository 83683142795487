import React, { Fragment } from 'react';
import defaultLogo from 'images/cisco_meraki_logo_green.svg';
import { NetworkConnectivityBanner } from './NetworkConnectivityBanner';
import { Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector } from 'react-redux';
import {
  getCustomColor,
  getCustomLogoUrl,
  getCustomLogoAltText,
  getNetworkId,
} from '_redux/networkInfo';

import styles from './Navbar.module.scss';

export const Logo = () => {
  const logo = useSelector(getCustomLogoUrl);
  const networkId = useSelector(getNetworkId);
  const altText = useSelector(getCustomLogoAltText) || networkId;
  if (logo) {
    return <img src={logo} alt={altText} className={styles.logobranded} />;
  } else {
    return <img src={defaultLogo} alt={'Cisco Meraki'} className={styles.logounbranded} />;
  }
};

const Brand = props => (
  <Navbar.Brand {...props}>
    <Logo />
  </Navbar.Brand>
);

export const NavbarBase = ({ homeUrl = null, children = null }) => {
  const customColor = useSelector(getCustomColor);
  return (
    <Fragment>
      <Navbar
        collapseOnSelect
        expand="sm"
        bg="white"
        variant="light"
        className={styles.navBar}
        style={{ borderTopColor: customColor }}
      >
        {!homeUrl ? (
          <Brand />
        ) : (
          <LinkContainer to={homeUrl}>
            <Brand />
          </LinkContainer>
        )}
        {children}
      </Navbar>
      <NetworkConnectivityBanner />
    </Fragment>
  );
};
