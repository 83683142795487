import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteUserAccessDevice } from '_redux/devices';
import { getDeleteDeviceRequestErrors } from '_redux/network';
import { ButtonWithConfirmationModal } from 'portal-dashboard/devices/_components/ButtonWithConfirmationModal';

export const DeleteDeviceButton = ({ deviceId }) => {
  const dispatch = useDispatch();
  const error = useSelector(state => getDeleteDeviceRequestErrors(state));

  return (
    <ButtonWithConfirmationModal
      buttonLabel="Remove device"
      variant="link"
      confirmationText="Your device will no longer have access to the network."
      onConfirm={() => dispatch(deleteUserAccessDevice(deviceId))}
      error={error}
      data-testid="removeDeviceBtn"
    />
  );
};
