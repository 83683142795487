import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { NetworkIdEntryPage } from './NetworkIdEntryPage';
import { LoginPage } from './networkId';
import { EnrollSuccess } from './networkId/success';
import { AndroidEnrollmentPage } from './networkId/android';
import { MacEnrollmentPage } from './networkId/mac';
import { IosEnrollmentPage } from './networkId/ios';
import { WindowsEnrollmentPage } from './networkId/windows';
import { WindowsSettingsEnrollmentPage } from './networkId/windowsSettings';
import { WindowsAutopilotTermsOfUsePage } from './networkId/windowsAutopilot';
import { EnrollError } from './error';
import { EnrollDisclaimer } from './disclaimer';
import { PreAuthNav } from 'portal-auth/_components/PreAuthNav';
import { Footer } from '_components/footer/Footer';
import { LoginSuccess } from 'shared/login/LoginSuccess';

export const EnrollRoutes = () => {
  const location = useLocation();
  return (
    <div className="d-flex flex-column min-vh-100">
      <PreAuthNav />
      <Switch>
        <Route exact path="/loginsuccess" component={LoginSuccess} />
        <Route exact path="/error" component={EnrollError} />
        <Route exact path="/disclaimer" component={EnrollDisclaimer} />
        <Route exact path="/:networkId/android" component={AndroidEnrollmentPage} />
        <Route exact path="/:networkId/mac" component={MacEnrollmentPage} />
        <Route exact path="/:networkId/ios" component={IosEnrollmentPage} />
        <Route exact path="/:networkId/windows" component={WindowsEnrollmentPage} />
        <Route exact path="/:networkId/windowsSettings" component={WindowsSettingsEnrollmentPage} />
        <Route
          exact
          path="/:networkId/windowsAutopilot"
          component={WindowsAutopilotTermsOfUsePage}
        />
        <Route exact path="/:networkId/success" component={EnrollSuccess} />
        <Route exact path="/:networkId" component={LoginPage} />
        <Route exact path="/" component={NetworkIdEntryPage} />
        <Route
          path="/:networkId/:a"
          render={({ match }) => <Redirect to={`/${match.params.networkId}`} />}
        />
      </Switch>
      <Footer>
        {location.pathname !== '/disclaimer' ? (
          <Link to="/disclaimer" className="mb-3 mb-sm-0">
            What can my administrator see?
          </Link>
        ) : null}
      </Footer>
    </div>
  );
};
