import React from 'react';
import SMLogo from 'images/SM_logo@2x.png';
import { Page } from 'enroll/_components/Page';
import { MdmProfileEnrollment } from '../_components/MdmProfileEnrollment';

export const IosEnrollmentPage = ({ match, history }) => {
  return (
    <Page
      title="Instructions: Systems Manager Enrollment"
      header="How to enroll your iOS device"
      iconSrc={SMLogo}
    >
      <MdmProfileEnrollment ios enrollmentCode={match.params.networkId} history={history} />
    </Page>
  );
};
