import React, { useState } from 'react';
import { Button, Modal, Image } from 'react-bootstrap';

import warning from 'images/yellow-exclamation.png';

export const LogoutFailureModal = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  return (
    <Modal show={show} centered>
      <Modal.Body className="text-center my-3">
        <Image src={warning} />
        <h2>Something went wrong</h2>
        <h3>
          There was an error logging out of your account. Please confirm you have an internet
          connection and try again.
        </h3>
        <Button onClick={() => handleClose()}>Ok</Button>
      </Modal.Body>
    </Modal>
  );
};
