import { createSlice, createSelector } from 'redux-starter-kit';
import { SliceNames, AuthTypes, NetworkKeys } from './constants';
import { sspApi } from '_utils/network';
import {
  startGet,
  startPost,
  endRejected,
  endWithResponse,
  checkResponseForError,
  getLoginInfo,
  getUserInfo,
  getADGroupSyncInfo,
} from './network';
import { getNetworkId, getAuthType, hyphenateNetworkId } from './networkInfo';

interface User {
  trusted_access_enabled?: boolean;
}

const initialState = {
  user: {},
};

const addUser = (state, { data, auth_type }) => {
  let userInfo;

  switch (auth_type) {
    case AuthTypes.Google:
      userInfo = data.GoogleOauthPccUser;
      break;
    case AuthTypes.Azure:
      userInfo = data.AzureOauthPccUser;
      break;
    case AuthTypes.OpenId:
      userInfo = data.GenericOauthPccUser;
      break;
    case AuthTypes.AD:
      userInfo = data.ActiveDirectoryPccUser;
      break;
    case AuthTypes.Saml:
      userInfo = data.SamlPccUser;
      break;
    case AuthTypes.Meraki:
    default:
      userInfo = data.SystemsManagerPccUser;
      break;
  }

  return {
    ...state,
    user: {
      ...userInfo,
      identity_certificate: data.SystemsManagerPccUser
        ? data.SystemsManagerPccUser.identity_certificate
        : { fileName: null, subject: null, issuer: null, expiry: null },
      has_phone_network: data.has_phone_network,
      has_sm_network: data.has_sm_network,
      trusted_access_enabled: Object.values(data).some((user: User) => user.trusted_access_enabled),
    },
  };
};

const loginSlice = createSlice({
  slice: SliceNames.Login,
  initialState,
  reducers: {
    reset: () => initialState,
    succeeded: (state, { payload }) => addUser(state, payload),
  },
});
export const {
  actions,
  reducer,
  selectors: { getLogin },
} = loginSlice;

export const getEmail = createSelector([getLogin], login => {
  if (login.user && login.user.email) return login.user.email;
  return null;
});

export const getUser = createSelector([getLogin], login => login.user);

export const getSSIDName = createSelector([getUser], user => user.ssid_name);

export const getTrustedAccessPermissions = createSelector([getUser], user => {
  return user.trusted_access_enabled || false;
});

// network related selectors
export const getLoginError = createSelector([getLoginInfo], adLoginInfo => {
  if (adLoginInfo) {
    return adLoginInfo.error;
  } else {
    return null;
  }
});

export const getLoginErrorStatus = createSelector([getLoginInfo], loginInfo => {
  if (loginInfo && loginInfo.success === false) {
    // If success is false, return true to indicate an error
    return !loginInfo.success;
  } else if (loginInfo && loginInfo.ok !== undefined) {
    // If status is not ok, return true to indicate an error
    return !loginInfo.ok;
  } else if (loginInfo && loginInfo.error) {
    return true;
  }
  return false;
});

export const getProvidedLoginError = createSelector([getLoginInfo], loginInfo => {
  if (loginInfo && loginInfo.error) {
    return loginInfo.error;
  }
  return null;
});

export const isADGroupSyncing = createSelector([getADGroupSyncInfo], adGroupSyncInfo => {
  if (!adGroupSyncInfo) return false;
  if (!adGroupSyncInfo.status) return true;
  return adGroupSyncInfo.status === true;
});

export const loginErrorExists = createSelector(
  [getLoginErrorStatus],
  (oauthStatus, loginStatus) => {
    if (oauthStatus !== null) {
      return oauthStatus;
    } else if (loginStatus !== null) {
      return loginStatus;
    } else {
      // No login error exists because there is no login so far
      return false;
    }
  },
);

// Get the login status
// If the user is in the process of being fetched, return null
// If the user is not logged in (i.e. get a 404 with the error not logged in), return false
// else, return true
export const getLoginStatus = createSelector(
  [getUserInfo, getAuthType, isADGroupSyncing],
  (userStatus, authType, isADGroupSyncing) => {
    if (!userStatus) {
      return null;
    }

    if (isADGroupSyncing && authType === AuthTypes.AD) return null;

    const { status, error } = userStatus;
    if (status === 404 && error === 'Not logged in') {
      return false;
    }
    return true;
  },
);

const { reset, succeeded } = actions;
export const handleLogin = (email: string, password: string) => async (dispatch, getState) => {
  const key = NetworkKeys.HandleLogin;

  dispatch({ ...startPost(key, true), ...reset() });

  const { networkId } = getState()[SliceNames.NetworkInfo];
  const body = new URLSearchParams();
  body.set('email', email);
  body.set('password', password);
  body.set('access_type', 'portal');

  try {
    const response = await sspApi.post(`${networkId}/login`, {
      body,
      headers: {
        accept: 'application/json',
      },
    });

    const data = await response.json();
    const { error, errorData } = checkResponseForError(data);
    const defaultAction = endWithResponse(key, response, errorData);

    if (!error) {
      if (data.syncing) {
        // Case where user has logged in, but a background job is needs to complete
        // before the user can be fetched
        dispatch(pollADGroupSync(data.job_id, networkId));
      } else {
        // Case where user has logged in and no background job is required
        dispatch(fetchUser());
      }
    }
    dispatch(defaultAction);
  } catch (e) {
    dispatch(endRejected(key, e));
  }
};

const MAX_POLLING_ATTEMPT_NUM = 30;
export const pollADGroupSync = (jobId: string, providedNetworkId = '', attemptNum = 0) => async (
  dispatch,
  getState,
) => {
  const key = NetworkKeys.ADGroupSync;

  dispatch({ ...startGet(key, true), ...reset() });

  const rawNetworkId = providedNetworkId === '' ? getNetworkId(getState()) : providedNetworkId;
  try {
    const networkId = hyphenateNetworkId(rawNetworkId);
    const response = await sspApi.get(`${networkId}/get_active_directory_group_sync_job_status`, {
      headers: {
        accept: 'application/json',
      },
      searchParams: {
        job_id: jobId,
      },
    });

    const data = await response.json();
    const { error, errorData } = checkResponseForError(data);
    const defaultAction = endWithResponse(key, response, errorData);

    if (!error) {
      const syncing = data.jobStatus !== 'complete';
      if (syncing) {
        if (attemptNum < MAX_POLLING_ATTEMPT_NUM) {
          dispatch({ ...defaultAction, blocking: true });
          setTimeout(() => dispatch(pollADGroupSync(jobId, networkId, attemptNum + 1)), 2000);
        } else {
          dispatch(defaultAction);
        }
      } else {
        dispatch(defaultAction);
        dispatch(fetchUser());
      }
    } else {
      dispatch(defaultAction);
    }
  } catch (e) {
    dispatch(endRejected(key, e));
  }
};

export const validateOAuthToken = (token: string, enrollmentCode: string) => async dispatch => {
  dispatch(startPost(NetworkKeys.HandleLogin, true));

  const response = await sspApi.post(`${enrollmentCode}/validate_oauth_token`, {
    json: { token, access_type: 'portal' },
    headers: { accept: 'application/json' },
  });
  const { error } = await response.json();
  if (!error) {
    dispatch(endWithResponse(NetworkKeys.HandleLogin, response));
    dispatch(fetchUser());
  } else {
    dispatch(endWithResponse(NetworkKeys.HandleLogin, response, { error }));
  }
};

export const completeSamlLogin = (error: string) => async dispatch => {
  dispatch(startPost(NetworkKeys.HandleLogin, true));
  if (!error) {
    dispatch(endWithResponse(NetworkKeys.HandleLogin, {}));
    dispatch(fetchUser());
  } else {
    dispatch(endWithResponse(NetworkKeys.HandleLogin, {}, { error }));
  }
};

export const fetchUser = () => async (dispatch, getState) => {
  const key = NetworkKeys.FetchUser;
  dispatch({ ...startGet(key, true), ...reset() });

  const { auth_type } = getState()[SliceNames.NetworkInfo];
  try {
    const response = await sspApi.get(`getuser`);

    const data = await response.clone().json();
    const { error, errorData } = checkResponseForError(data);
    const defaultAction = endWithResponse(key, response, errorData);

    if (!error) {
      dispatch(defaultAction);
      dispatch(succeeded({ data, auth_type }));
    } else {
      dispatch(defaultAction);
    }
  } catch (e) {
    dispatch(endRejected(key, e));
  }
};

export default reducer;
