import React, { useEffect } from 'react';
import { useMutation } from 'react-query';

import { LoadingSpinnerFullscreen } from '_components/loadingSpinner/LoadingSpinner';
import { validateOAuthToken } from 'enroll/api';
import { Redirect } from 'react-router';

export function AdeEnrollmentRedirect({ enrollmentCode, token, pccMachineId }) {
  const params = new URLSearchParams(window.location.search);
  const isSamlSuccess = params.get('saml_success') === 'true';
  const errorDesc = params.get('error_description');

  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation(
    'validate_oauth_token',
    validateOAuthToken,
  );

  useEffect(() => {
    if (token && !data) {
      mutate({ token, enrollmentCode });
    }
  }, [token, data, mutate, enrollmentCode]);

  if (isLoading) {
    return <LoadingSpinnerFullscreen show />;
  } else if (isSamlSuccess || isSuccess) {
    window.location.assign(
      `${
        window.location.origin
      }/ssp/${enrollmentCode}/ade/profile?pcc_machine_id=${encodeURIComponent(pccMachineId)}`,
    );
    return null;
  } else if ((isError && error) || errorDesc) {
    const pccMachineSearch = Boolean(pccMachineId) ? `&pcc_machine_id=${pccMachineId}` : '';
    return (
      <Redirect
        // @ts-ignore error.message should exist since isError is true
        to={`/${enrollmentCode}?error_description=${errorDesc || error.message}${pccMachineSearch}`}
      />
    );
  } else {
    return <React.Fragment />;
  }
}
