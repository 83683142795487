import React from 'react';
import { Page } from 'enroll/_components/Page';
import SMLogo from 'images/SM_logo@2x.png';
import playStorePNG from 'images/play-store-badge.png';
import { Image } from 'react-bootstrap';

export const LegacyAndroidEnrollmentPage = () => {
  const playStoreUrl = new URL('https://play.google.com/store/apps/details?id=com.meraki.sm');
  const playStoreIntent = `intent://${playStoreUrl.host}${playStoreUrl.pathname}${playStoreUrl.search}#Intent;package=com.android.vending;scheme=https;S.browser_fallback=${playStoreUrl};end`;
  const isAndroidUa = navigator.userAgent.match(/Android/i);
  const onClickFallback = () =>
    setTimeout(() => (window.location.href = playStoreUrl.toString()), 1000);
  return (
    <Page
      title="Instructions: Systems Manager enrollment"
      header="Install the Meraki Systems Manager app"
      iconSrc={SMLogo}
    >
      <p>Follow the instructions within the app to enroll your Android device.</p>
      <a href={isAndroidUa ? playStoreIntent : playStoreUrl.toString()} onClick={onClickFallback}>
        <Image src={playStorePNG} width={180} alt="Get it on Google Play" />
      </a>
      <p>
        Don’t have Google Play?{' '}
        <a href="https://dl.meraki.net/androidsm/AndroidSM.apk">Download the app manually</a>.
      </p>
    </Page>
  );
};
