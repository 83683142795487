import React from 'react';
import { Router, RouteComponentProps } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClientProvider, QueryClient, DefaultOptions } from 'react-query';

import { ConnectedLoadingSpinnerFullscreen } from '_components/loadingSpinner/LoadingSpinner';
import { Helmet } from 'react-helmet';
import { EnhancedStore } from 'redux-starter-kit';
import { DevTools } from '_components/DevTools';
import { RemoveSlashAndRedirect } from '_components/RemoveSlashAndRedirect';

const queryClientOptions: DefaultOptions = {
  queries: {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  },
  mutations: {
    retry: false,
  },
};

const isDevelopment = process.env.NODE_ENV === 'development';

type Props = {
  store: EnhancedStore;
  history: RouteComponentProps['history'];
  queryClient: QueryClient;
  title: string;
};
export const AppProviders: React.FC<Props> = ({ store, history, queryClient, children, title }) => {
  queryClient.setDefaultOptions(queryClientOptions);

  return (
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={store}>
        <Router history={history}>
          {children}
          <ConnectedLoadingSpinnerFullscreen />
          <RemoveSlashAndRedirect />
          <Helmet>
            <title>{title}</title>
          </Helmet>
          {isDevelopment && <DevTools />}
        </Router>
      </ReduxProvider>
    </QueryClientProvider>
  );
};
