const BLANK_STR = '-';

export const SYSTEM_TYPES = {
  MAC: 'mac',
  IOS: 'ios',
  ANDROID: 'android',
  WINDOWS: 'windows',
  ANDROID_PASSPOINT: 'android_passpoint',
  GOOGLE_AMA: 'google_ama',
};

export const formatDeviceType = systemType => {
  switch (systemType) {
    case 'mac':
      return 'Mac';
    case 'ios':
      return 'iOS';
    default:
      return BLANK_STR;
  }
};

export const formatMacAddress = mac => {
  if (mac) {
    return mac.toUpperCase();
  } else {
    return BLANK_STR;
  }
};

// rawDate is one of the following formats:
// - integer seconds since epoch
// - string value representing a date
// returns a string in the format "ShortMonth. DD, YYYY, HH:MM AM/PM"
export const formatDate = (rawDate, withTime = false) => {
  if (rawDate) {
    const format = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    if (withTime) {
      Object.assign(format, {
        hour: 'numeric',
        minute: 'numeric',
      });
    }
    const date = typeof rawDate === 'number' ? new Date(rawDate * 1000) : new Date(rawDate);
    return date.toLocaleDateString(undefined, format);
  } else {
    return BLANK_STR;
  }
};

// OS names can be out of date on Dashboard, and we don't want to display
// obsolete OS names
export const formatOSName = osName => {
  switch (osName) {
    case 'OS X':
      return 'macOS';
    default:
      return osName;
  }
};

// Convert a length of time in seconds into a human-readable format
export const formatRemainingTime = length => {
  if (!length) return BLANK_STR;
  let result = '';
  const days = Math.floor(length / 86400);
  length -= days * 86400;
  result += days > 0 ? `${days} days ` : '';
  const hours = Math.floor(length / 3600);
  length -= hours * 3600;
  result += hours > 0 ? `${hours} hrs ` : '';
  const minutes = Math.floor(length / 60);
  length -= minutes * 60;
  result += minutes > 0 ? `${minutes} min ` : '';
  return result;
};
