import React from 'react';
import styles from './Footer.module.scss';

export const Footer = ({ children }) => (
  <div className={styles.footer}>
    <div className={styles.copyright}>
      &#169;{` ${new Date().getFullYear()} Cisco Systems, Inc.`}
    </div>
    {children}
  </div>
);
