import { AuthTypes } from '_redux/constants';

export const GOOGLE_AUTH_ENDPOINT = 'https://accounts.google.com/o/oauth2/v2/auth';
export const GOOGLE_CLIENT_ID =
  '228803170967-1g9jcp073e8ceqos6cng478rg3o60hgr.apps.googleusercontent.com';

export const AZURE_AUTH_ENDPOINT_ORIGIN = 'https://login.microsoftonline.com';
export const AZURE_AUTH_ENDPOINT_PATH = '/oauth2/authorize';

export const AUTHORIZATION_ENDPOINT_PARAMS = {
  scope: 'openid email',
  response_type: 'id_token',
  redirect_uri: `${window.location.origin}/loginsuccess`,
};

export function oAuthEndpoint(
  authType: AuthTypes,
  enrollmentCode: string,
  oAuthSettings,
  userEnrollment = 'false',
) {
  let endpoint, params;
  switch (authType) {
    case AuthTypes.Azure:
      endpoint = `${AZURE_AUTH_ENDPOINT_ORIGIN}/${oAuthSettings.tenant_id}${AZURE_AUTH_ENDPOINT_PATH}`;
      params = new URLSearchParams({
        ...AUTHORIZATION_ENDPOINT_PARAMS,
        client_id: oAuthSettings.client_id,
        nonce: oAuthSettings.nonce,
        state: `${enrollmentCode};${userEnrollment === 'true'}`,
      });
      break;
    case AuthTypes.Google:
      endpoint = GOOGLE_AUTH_ENDPOINT;
      params = new URLSearchParams({
        ...AUTHORIZATION_ENDPOINT_PARAMS,
        client_id: GOOGLE_CLIENT_ID,
        nonce: oAuthSettings.nonce,
        state: `${enrollmentCode};${userEnrollment === 'true'}`,
        prompt: 'select_account',
      });
      break;
    case AuthTypes.OpenId:
      endpoint = oAuthSettings.endpoint;
      params = new URLSearchParams({
        ...AUTHORIZATION_ENDPOINT_PARAMS,
        client_id: oAuthSettings.client_id,
        nonce: oAuthSettings.nonce,
        state: `${enrollmentCode};${userEnrollment === 'true'}`,
      });
      break;
    default:
      console.error('Invalid OAuth type');
      return null;
  }
  return `${endpoint}?${params.toString()}`;
}
