import React, { useEffect } from 'react';
import { useMutation } from 'react-query';

import { LoadingSpinnerFullscreen } from '_components/loadingSpinner/LoadingSpinner';
import { LoginError } from 'shared/login/LoginError';
import { RedirectToPlatformEnrollmentPage } from './RedirectToPlatformEnrollmentPage';
import { validateOAuthToken } from 'enroll/api';

/*
 * Note: this component should only be used for popup-less enrollment flows like Windows MDM
 * due to the window directly hitting LoginSuccess and not returning to the EnrollmentOAuth
 * component, so we make the validate_oauth_token call here
 */

export function FormlessEnrollmentOAuthLogin({ enrollmentCode, token, userEnrollment }) {
  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation(
    'validate_oauth_token',
    validateOAuthToken,
  );

  useEffect(() => {
    if (token && !data) {
      mutate({ token, enrollmentCode });
    }
  }, [token, enrollmentCode, userEnrollment, mutate, data]);

  if (isLoading) {
    return <LoadingSpinnerFullscreen show />;
  } else if (isSuccess) {
    return (
      <RedirectToPlatformEnrollmentPage
        authToken={data.auth_token}
        enrollmentCode={enrollmentCode}
        userEnrollment={userEnrollment}
      />
    );
  } else {
    return (
      <React.Fragment>
        {isError && error && (
          // @ts-ignore
          <LoginError error={error.message} />
        )}
      </React.Fragment>
    );
  }
}
