import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Button, Form, Alert } from 'react-bootstrap';
import { getUser } from '_redux/login';
import { getTrustedAccessDeviceCount } from '_redux/devices';
import {
  updateUserSettings,
  getUpdateUserSettingsErrorExists,
  getUpdateUserSettingsError,
} from '_redux/settings';

import styles from './FormActionsRow.module.scss';

interface SettingsFormItemProps {
  label: string;
  description?: string;
  children: any;
  htmlFor?: string;
}

const SettingsFormItem = (props: SettingsFormItemProps) => (
  <Form.Group as={Row} className="my-4">
    <Form.Label column xs={12} md={3} htmlFor={props.htmlFor}>
      {props.label}
      {!!props.description && <div className="font-weight-light">{props.description}</div>}
    </Form.Label>
    <Col xs={12} md={3}>
      {props.children}
    </Col>
  </Form.Group>
);

export const SettingsPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const deviceCount = useSelector(getTrustedAccessDeviceCount);

  const hasErrors = useSelector(getUpdateUserSettingsErrorExists);
  const errorMessage =
    useSelector(getUpdateUserSettingsError) ||
    'An unknown error has occured. Please try again or contact support';
  const [submitted, setSubmitted] = useState(false);

  const [identityCertFile, setIdentityCertFile] = useState(null);
  const [identityCertPassword, setIdentityCertPassword] = useState('');

  if (!user) return null;

  const availableDevices = user.trusted_access_device_limit - deviceCount;

  const updateIdentityCertFile = e => {
    const fileInput = e.target;
    const files = Array.from(fileInput.files);
    const firstFile = files[0];
    setIdentityCertFile(firstFile);
  };

  const updateIdentityCertPassword = e => setIdentityCertPassword(e.target.value);

  const resetState = () => {
    setIdentityCertPassword('');
    setIdentityCertFile(null);
    setSubmitted(false);
  };

  const submit = e => {
    e.preventDefault();
    setSubmitted(true);
    dispatch(
      updateUserSettings({
        identityCertFile,
        identityCertPassword,
      }),
    );
  };

  const hasChanges = !!identityCertFile || !!identityCertPassword;

  return (
    <Form
      noValidate
      onSubmit={submit}
      className="d-flex flex-column flex-fill justify-content-between"
    >
      <div className="mx-3">
        {submitted && hasErrors && <Alert variant="danger">{errorMessage}</Alert>}
        <h1 className="mb-4">Settings</h1>
        <SettingsFormItem
          label="Email"
          description="Your email is set by your administrator and cannot be edited here."
        >
          <p>{user.email}</p>
        </SettingsFormItem>

        <SettingsFormItem
          label="Trusted Access device limit"
          description="This limit only applies to Trusted Access devices, not to devices provided by your
            administrator."
        >
          <Form.Label>
            {`${deviceCount} licenses used, ${availableDevices} licenses available`}
          </Form.Label>
        </SettingsFormItem>

        <SettingsFormItem
          label="Current identity certificate"
          description="Uploading a new identity certificate will replace this certificate."
        >
          <Form.Label>{user.identity_certificate.fileName || 'No certificate uploaded'}</Form.Label>
        </SettingsFormItem>

        <SettingsFormItem
          label="New Identity certificate"
          description="Upload an identity certificate downloaded from a 3rd party certificate provider."
        >
          <Form.Control
            type="file"
            className="custom-file-input"
            id="identityCertificateUpload"
            // this is a hacky way to reset the value of this form element when the 'Reset changes' button is hit.
            // this value is only for the form and is not sent to the backend.
            value={identityCertFile ? undefined : ''}
            onChange={updateIdentityCertFile}
          />
          <Form.Label
            className="custom-file-label"
            style={{ right: '1rem', left: '1rem' }}
            htmlFor="identityCertificateUpload"
          >
            {identityCertFile ? identityCertFile.name : 'Choose file'}
          </Form.Label>
        </SettingsFormItem>

        <SettingsFormItem
          label="Identity certificate password"
          description="Enter the password created by the 3rd party certificate provider at the time of download."
          htmlFor="identity_cert_password"
        >
          <Form.Control
            id="identity_cert_password"
            type="password"
            value={identityCertPassword}
            onChange={updateIdentityCertPassword}
          />
        </SettingsFormItem>
      </div>

      <Row
        className={`justify-content-center ${styles.formActionsRow} ${
          hasChanges ? styles.hasChanges : ''
        }`}
      >
        <Button variant="secondary" className="m-2" onClick={resetState}>
          Reset changes
        </Button>
        <Button className="m-2" type="submit">
          Save changes
        </Button>
      </Row>
    </Form>
  );
};
