import React, { Fragment, useState } from 'react';
import { PreAuthNav } from 'portal-auth/_components/PreAuthNav';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { PasswordResetSuccessPage } from './PasswordResetSuccessPage';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetPassword,
  getPasswordResetError,
  hasPasswordResetSucceeded,
} from '_redux/passwordReset';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormPassword } from '_utils/formUtils';

const PasswordResetSchema = Yup.object().shape({
  password: FormPassword,
  confirmPassword: FormPassword,
});

const ErrorMessages = {
  'Invalid link':
    'This password recovery link has either been used or expired. Please request another.',
  'Password confirmation does not match': 'Password and password confirmation do not match.',
};

export const PasswordResetPage = ({ match, location }) => {
  const networkId = match.params.networkId;
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const [submitted, setSubmitted] = useState(false);

  const passwordResetSucceeded = useSelector(hasPasswordResetSucceeded);

  const passwordResetError = useSelector(getPasswordResetError);

  if (passwordResetSucceeded && submitted) {
    return <PasswordResetSuccessPage networkId={networkId} />;
  }

  return (
    <Fragment>
      <PreAuthNav />
      <Container>
        <Row className="justify-content-center text-center my-4">
          <Col>
            <h1 className="my-4">Choose your new password</h1>
            <div className="text-danger">
              {ErrorMessages[passwordResetError] || passwordResetError}
            </div>
          </Col>
        </Row>

        <Formik
          validateOnBlur
          validateOnChange={false}
          validationSchema={PasswordResetSchema}
          onSubmit={({ password, confirmPassword }) => {
            setSubmitted(true);
            dispatch(resetPassword(password, confirmPassword, token, networkId));
          }}
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
        >
          {({ handleSubmit, handleChange, values, errors, handleBlur, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Container>
                <Form.Group as={Row} className="my-4 justify-content-center">
                  <Col xs={12} md={4}>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      isInvalid={touched.password && !!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="my-4 justify-content-center">
                  <Col xs={12} md={4}>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="confirmPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                      isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPassword || 'Passwords do not match'}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Row className="justify-content-center">
                  <Col xs={6} md={2}>
                    <Button variant="primary" type="submit" block>
                      Submit
                    </Button>
                  </Col>

                  <Col xs={6} md={2}>
                    <LinkContainer to={`/${networkId}`}>
                      <Button block variant="link">
                        Cancel
                      </Button>
                    </LinkContainer>
                  </Col>
                </Form.Row>
              </Container>
            </Form>
          )}
        </Formik>
      </Container>
    </Fragment>
  );
};
