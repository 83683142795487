import { createSlice, createSelector } from 'redux-starter-kit';
import { SliceNames } from './constants';
import { sspApi } from '_utils/network';
import {
  startGet,
  startPost,
  endRejected,
  endWithResponse,
  checkResponseForError,
} from './network';
import { NetworkKeys } from '_redux/constants';

export interface App {
  name: string;
  icon: string;
  description: string;
  version: string;
}

const initialAppState = { appIds: [], apps: {} };

function normalizeApps(appsArr) {
  const apps = {};
  appsArr.forEach(app => {
    apps[app.id] = app;
  });
  return { apps, appIds: Object.keys(apps) };
}

const appsSlice = createSlice({
  slice: SliceNames.Apps,
  initialState: initialAppState,
  reducers: {
    reset: () => initialAppState,
    resetAndInsert: (_state, { payload }) => normalizeApps(payload),
  },
});

export default appsSlice.reducer;

// actions
const { resetAndInsert } = appsSlice.actions;

export const sendAppInstallRequest = (appId, deviceId) => async dispatch => {
  const key = NetworkKeys.InstallApps;
  dispatch({ ...startPost(key, true) });
  try {
    const response = await sspApi.post(`devices/apps/${deviceId}/${key.toLowerCase()}`, {
      headers: {
        accept: 'application/json',
      },
      searchParams: {
        'appIds[]': appId,
      },
    });

    const data = await response.json();
    const { errorData } = checkResponseForError(data);
    const appResponse = { success: data.success, ...errorData };
    const defaultAction = endWithResponse(key, response, appResponse);
    dispatch(defaultAction);
  } catch (e) {
    dispatch({ ...endRejected(key, e) });
  }
};

export const sendAppUninstallRequest = (appId, deviceId) => async dispatch => {
  const key = NetworkKeys.UninstallApp;
  dispatch({ ...startPost(key, true) });
  try {
    const response = await sspApi.post(`devices/apps/${deviceId}/${key.toLowerCase()}`, {
      headers: {
        accept: 'application/json',
      },
      searchParams: {
        appId: appId,
      },
    });

    const data = await response.json();
    const { errorData } = checkResponseForError(data);
    const appResponse = { success: data.success, ...errorData };
    const defaultAction = endWithResponse(key, response, appResponse);
    dispatch(defaultAction);
  } catch (e) {
    dispatch({ ...endRejected(key, e) });
  }
};

export const fetchApps = () => async dispatch => {
  const key = NetworkKeys.FetchApps;
  dispatch(startGet(key, true));
  try {
    const response = await sspApi.get(`devices/apps`);
    const defaultAction = endWithResponse(key, response);

    if (response.ok) {
      const data = await response.json();
      dispatch(defaultAction);
      dispatch(resetAndInsert(data));
    } else {
      dispatch(defaultAction);
    }
  } catch (e) {
    dispatch(endRejected(key, e)); // TODO: what do we do if rejected?
  }
};

// selectors
export const { getApps: getAppsSlice } = appsSlice.selectors;

export const getAllApps = createSelector([getAppsSlice], appsSlice => appsSlice.apps);

export const getAppFromIdProp = createSelector(
  [getAllApps, { path: 'id', argIndex: 1 }],
  (apps, id): App => apps[id] || {},
);
