import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DeviceTypes } from '_redux/constants';
import { TrustedAccessRoot } from './trusted-access';
import { getDevicesLoaded } from '_redux/network';
import { getTrustedAccessDeviceIds, getManagedDeviceIds } from '_redux/devices';
import { MdmRoot } from './managed';
import { NoDevices } from './NoDevices';

export const DevicesPage = ({ match: { params } }) => {
  const devicesLoaded = useSelector(getDevicesLoaded);
  const mdmDeviceIds = useSelector(getManagedDeviceIds);

  const taDeviceIds = useSelector(getTrustedAccessDeviceIds);

  const baseUrl = `/${params.networkId}/${params.userId}`;
  const enrollUrl = `${baseUrl}/enroll`;

  if (!devicesLoaded) return null;

  if (!mdmDeviceIds.length && !taDeviceIds.length) {
    return <NoDevices />;
  }

  const mdmDeviceUrl = mdmDeviceIds.length
    ? `${baseUrl}/devices/${DeviceTypes.MDM}/${mdmDeviceIds[0]}`
    : enrollUrl;
  const taDeviceUrl = taDeviceIds.length
    ? `${baseUrl}/devices/${DeviceTypes.TA}/${taDeviceIds[0]}`
    : enrollUrl;

  return (
    <Switch>
      <Redirect
        exact
        from="/:networkId/:userId/devices"
        to={mdmDeviceIds.length ? mdmDeviceUrl : taDeviceUrl}
      />
      <Redirect exact from={`/:networkId/:userId/devices/${DeviceTypes.MDM}`} to={mdmDeviceUrl} />
      <Redirect exact from={`/:networkId/:userId/devices/${DeviceTypes.TA}`} to={taDeviceUrl} />

      <Route path={`/:networkId/:userId/devices/${DeviceTypes.MDM}`} component={MdmRoot} />
      <Route path={`/:networkId/:userId/devices/${DeviceTypes.TA}`} component={TrustedAccessRoot} />
    </Switch>
  );
};
