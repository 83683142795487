import { NetworkStates, RequestMethods, NetworkKeys } from './constants';
import { createSelector } from 'redux-starter-kit';

//
// reducer logic
const initializeRequest = ({ method }) => ({
  method,
  state: NetworkStates.Pending,
});

const extractResponse = ({ method }, response) => {
  if (response.errorType) {
    return {
      method,
      state: NetworkStates.Rejected,
      ...response,
    };
  }
  return {
    method,
    state: NetworkStates.Resolved,
    ...response,
  };
};

export const networkReducer = (state = {}, { key, request, response, type }) => {
  // If the state for a specific key is missing but a response has come in,
  // then the person logged out in the middle of the a request
  if (!state[key] && response) {
    return state;
  }

  if (key && request) return { ...state, [key]: initializeRequest(request) };
  if (key && response) return { ...state, [key]: extractResponse(state[key], response) };
  if (key && type === 'REMOVE_NETWORK_KEY') {
    const clonedState = { ...state };
    delete clonedState[key];
    return clonedState;
  }

  return state;
};

export default networkReducer;

//
// selectors

// Generate a selector
export const createNetworkSelectorFromKey = (networkKey: NetworkKeys) => state =>
  state.network[networkKey] || {};

export const getDevicesLoaded = createSelector(
  [state => state.network[NetworkKeys.FetchDevices]],
  requestInfo => requestInfo && requestInfo.ok,
);

// Remove Device
export const getRemoveDeviceRequestErrors = createSelector(
  ['network'],
  network => (network[NetworkKeys.RemoveDevice] || {}).error,
);

// Update Device
export const getUpdateDeviceRequestErrors = createSelector(
  ['network'],
  network => (network[NetworkKeys.UpdateDevice] || {}).error,
);

// Delete Device
export const getDeleteDeviceRequestErrors = createSelector(
  ['network'],
  network => (network[NetworkKeys.DeleteDevice] || {}).error,
);

export const getLoginInfo = createSelector(
  ['network'],
  network => network[NetworkKeys.HandleLogin],
);

export const getADGroupSyncInfo = createSelector(
  ['network'],
  network => network[NetworkKeys.ADGroupSync],
);

// User Information
export const getUserInfo = createSelector(['network'], network => network[NetworkKeys.FetchUser]);

// Network Information
export const getNetworkRequestInfo = createSelector(
  ['network'],
  network => network[NetworkKeys.FetchNetwork],
);

// Profile Information
export const getProfileInfo = createSelector(
  ['network'],
  network => network[NetworkKeys.FetchEnrollProfile],
);

// MDM Login Information
export const getMDMLoginInfo = createSelector(
  ['network'],
  network => network[NetworkKeys.MDMLogin],
);

// Logout Information
export const getLogoutInfo = createSelector(
  ['network'],
  network => network[NetworkKeys.HandleLogout],
);

// Pending Counts
export const getPendingCount = createSelector(
  ['network'],
  network =>
    Object.keys(network).filter(key => network[key].state === NetworkStates.Pending).length,
);

export const getUnauthorizedStatus = createSelector(['network'], network => {
  return Object.keys(network).some(key => {
    return network[key].error === 'Unauthorized';
  });
});

//
// actions
export const removeKey = (key: string) => ({
  key,
  type: 'REMOVE_NETWORK_KEY',
});

export const removeKeyFromNetwork = key => dispatch => {
  dispatch(removeKey(key));
};

export const startPost = (key, isBlocking) => start(RequestMethods.Post, key, isBlocking);
export const startPut = (key, isBlocking) => start(RequestMethods.Put, key, isBlocking);
export const startGet = (key, isBlocking) => start(RequestMethods.Get, key, isBlocking);
export const startDelete = (key, isBlocking) => start(RequestMethods.Delete, key, isBlocking);
const start = (method: RequestMethods, key: string, isBlocking: boolean) => ({
  key,
  request: { method },
  blocking: isBlocking ? true : undefined,
  type: `network/start/${key}`,
});

export const endWithResponse = (key: string, response: any, extras?: Object) => {
  const { ok, redirected, status, statusText, type, url, bodyUsed } = response;
  return {
    blocking: false,
    response: { ok, redirected, status, statusText, type, url, bodyUsed, ...extras },
    key,
    type: `network/resolve/${key}`,
  };
};

export const endRejected = (key: string, error) => {
  const { name, message, stack } = error;
  return {
    key,
    blocking: false,
    type: `network/reject/${key}`,
    response: { errorType: name, error: message, stack },
  };
};

// This function looks at the json body of a response to see if there were errors
// Errors can either be in the form of error text or in the form of success: false
export const checkResponseForError = jsonResp => {
  if (jsonResp.error || jsonResp.success === false) {
    return { error: true, errorData: jsonResp };
  }
  return { error: false, errorData: null };
};
