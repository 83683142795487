import React from 'react';
import { SamlLoginForm } from 'shared/login/SamlLoginForm';

export function EnrollmentSamlLogin({ samlEndpoint }) {
  const searchParams = new URLSearchParams(window.location.search);
  const pcc_machine_id = searchParams.get('pcc_machine_id');
  if (pcc_machine_id) {
    // The date at the end adds an expiration to the cookie to five minutes from now
    document.cookie = `pcc_machine_id=${pcc_machine_id};${new Date(Date.now() + 5 * 60 * 1000)}`;
  }

  return <SamlLoginForm authEndpoint={samlEndpoint} />;
}
