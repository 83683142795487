import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'react-bootstrap';

import { getFilteredAppIdsForManagedDevice } from '_redux/devices';

import { AppSearchBar } from './_components/AppSearchBar';
import { AppCard } from './_components/AppCard';

export const AppsRoot = ({ match }) => {
  const [filterTerm, setFilterTerm] = useState('');
  const filteredAppIds = useSelector(state =>
    getFilteredAppIdsForManagedDevice(state, match.params.deviceId, filterTerm),
  );
  return (
    <div className="my-5">
      <AppSearchBar onChange={setFilterTerm} />
      <Row className="mt-3 px-3">
        {filteredAppIds.map(id => (
          <AppCard key={id} appId={id} deviceId={match.params.deviceId} />
        ))}
      </Row>
    </div>
  );
};
