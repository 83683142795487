import React from 'react';

const SECURITY_POLICY_KEYS = {
  MIN_OS_VERSION: 'has_min_os_version',
  DISK_ENCRYPTION: 'has_disk_encryption',
  LOGIN: 'has_login',
  PASSCODE_LOCK: 'has_pass_code_lock',
  ANTIVIRUS: 'has_antivirus',
};

export const SecurityPolicyText = ({ policy, isCompliant }) => {
  switch (policy) {
    case SECURITY_POLICY_KEYS.MIN_OS_VERSION:
      return isCompliant ? (
        <span>This device is running the minimum OS version</span>
      ) : (
        <span>This device is not running the required minimum OS</span>
      );
    case SECURITY_POLICY_KEYS.DISK_ENCRYPTION:
      return isCompliant ? (
        <span>Filesystem is encrypted</span>
      ) : (
        <span>Filesystem is not encrypted</span>
      );
    case SECURITY_POLICY_KEYS.LOGIN:
      return isCompliant ? (
        <span>Login is enabled</span>
      ) : (
        <span>Password is needed or auto login needs to be disabled</span>
      );
    case SECURITY_POLICY_KEYS.PASSCODE_LOCK:
      return isCompliant ? <span>Passcode is enabled</span> : <span>Passcode is not enabled</span>;
    case SECURITY_POLICY_KEYS.ANTIVIRUS:
      return isCompliant ? (
        <span>Antivirus is running</span>
      ) : (
        <span>Antivirus is not running</span>
      );
  }
  return null;
};
