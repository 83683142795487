import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Row, Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';

import { getManagedDevice } from '_redux/devices';
import { NetworkKeys } from '_redux/constants';
import { ToolButton, ToolButtonWithDescription } from '../_components/ToolButton';
import { SuccessDisplay } from '../../../_components/ButtonWithConfirmationModal';
import { ButtonWithModal } from '../../../_components/ButtonWithModal';
import { getToolRequestState, sendToolRequest } from '_redux/tools';
import { removeKeyFromNetwork } from '_redux/network';
import passcode_tool from 'images/tools/passcode_tool.svg';
import { SYSTEM_TYPES } from '_utils/deviceUtils';

const ModalDisplay = props => {
  const { setOpen, success, error, resetPasscode, handleSuccess, match, passcodeText } = props;
  const { deviceId } = match.params;

  return (
    <Fragment>
      {success && <SuccessDisplay successText={`${passcodeText} reset.`} onClose={handleSuccess} />}
      {!success && (
        <Formik
          initialValues={{ passcode: '' }}
          onSubmit={({ passcode }) => resetPasscode(passcode, deviceId)}
        >
          {({ handleChange, handleSubmit, values }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-2" controlId="formResetPasscode">
                <h3 className="mb-3">{`Reset ${passcodeText}`}</h3>
                <Form.Control
                  type="password"
                  name="passcode"
                  value={values.passcode}
                  onChange={handleChange}
                  placeholder={`Enter new ${passcodeText.toLowerCase()}`}
                />
                {error}
              </Form.Group>
              <Row className="justify-content-center mt-3">
                <Button variant="secondary" className="m-2" onClick={() => setOpen(false)}>
                  Close
                </Button>
                <Button variant="primary" className="m-2" type="submit">
                  {`Reset ${passcodeText}`}
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </Fragment>
  );
};

export const ResetWorkProfilePasscode = ({ id, showDescription = false }) => {
  const { system_type, is_profile_owner } = useSelector(state => getManagedDevice(state, id));
  // To be consistent with the text on Dashboard, we will use "password" for AMA devices and
  // "passcode" for legacy Android devices.
  const isGoogleAma = system_type === SYSTEM_TYPES.GOOGLE_AMA;
  const passcodeText = isGoogleAma ? 'Password' : 'Passcode';
  const isWorkProfile = isGoogleAma ? is_profile_owner : true;

  const modalProps = { passcodeText: passcodeText };

  return (
    <ButtonWithModal
      showDescription={showDescription}
      title={`Change ${isWorkProfile ? 'Work ' : ''}${passcodeText}`}
      description={`Change ${isWorkProfile ? 'work profile ' : ''}${passcodeText.toLowerCase()}`}
      icon={passcode_tool}
      buttonComponent={showDescription ? ToolButtonWithDescription : ToolButton}
    >
      {setOpen => <ConnectedModalDisplay {...modalProps} setOpen={setOpen} />}
    </ButtonWithModal>
  );
};

const msp = state => getToolRequestState(state, NetworkKeys.ResetWorkProfilePasscode);

const mdp = (dispatch, { setOpen }) => ({
  resetPasscode: (passcode, deviceId) =>
    dispatch(
      sendToolRequest(NetworkKeys.ResetWorkProfilePasscode, deviceId, { passcode: passcode }),
    ),
  handleSuccess: () => {
    setOpen(false);
    dispatch(removeKeyFromNetwork(NetworkKeys.ResetWorkProfilePasscode));
  },
});

const ConnectedModalDisplay = connect(msp, mdp)(withRouter(ModalDisplay));
