import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavDropdown, Nav, Navbar } from 'react-bootstrap';
import classnames from 'classnames';
import { NavbarBase } from '_components/navbar/Navbar';
import { Icon } from '_components/Icon';
import { handleLogout } from '_redux';
import { LinkContainer } from 'react-router-bootstrap';
import { DeviceTypes } from '_redux/constants';
import {
  getTrustedAccessDevice,
  getTrustedAccessDeviceIds,
  getManagedDeviceIds,
  getManagedDeviceFriendlyName,
} from '_redux/devices';

import styles from './DashNavbar.module.scss';

const MdmDeviceLink = ({ id, baseDeviceUrl }) => {
  const name = useSelector(s => getManagedDeviceFriendlyName(s, id));
  return (
    <LinkContainer to={`${baseDeviceUrl}/${DeviceTypes.MDM}/${id}`} className={styles.deviceLink}>
      <NavDropdown.Item>
        <Icon glyph="mobile" className={classnames(styles.icon, styles.mdm)} />
        <span>{name}</span>
      </NavDropdown.Item>
    </LinkContainer>
  );
};

const TaDeviceLink = ({ id, baseDeviceUrl }) => {
  const { name } = useSelector(s => getTrustedAccessDevice(s, id));
  return (
    <LinkContainer to={`${baseDeviceUrl}/${DeviceTypes.TA}/${id}`} className={styles.deviceLink}>
      <NavDropdown.Item>
        <Icon glyph="wifi" className={classnames(styles.icon, styles.wifi)} />
        <span>{name}</span>
      </NavDropdown.Item>
    </LinkContainer>
  );
};

const AddDevice = ({ baseUrl }) => {
  return (
    <LinkContainer to={`${baseUrl}/enroll`} data-testid="addDeviceNavBtn">
      <Nav.Link>Join Wi-Fi</Nav.Link>
    </LinkContainer>
  );
};

export const DashNavbar = ({ baseUrl }) => {
  const baseDeviceUrl = `${baseUrl}/devices`;
  const dispatch = useDispatch();

  const mdmDeviceIds = useSelector(getManagedDeviceIds);
  const taDeviceIds = useSelector(getTrustedAccessDeviceIds);

  const deviceDisplays = mdmDeviceIds
    .map(id => <MdmDeviceLink key={`mdm_${id}`} id={id} baseDeviceUrl={baseDeviceUrl} />)
    .concat(
      taDeviceIds.map(id => (
        <TaDeviceLink key={`ta_${id}`} id={id} baseDeviceUrl={baseDeviceUrl} />
      )),
    );

  return (
    <NavbarBase homeUrl={baseUrl}>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
        <Nav>
          <AddDevice baseUrl={baseUrl} />
          <NavDropdown title="My devices" id="collapsible-nav-dropdown">
            {deviceDisplays.length > 0 ? deviceDisplays : <div className="p-3">No devices</div>}
          </NavDropdown>
          <LinkContainer to={`${baseUrl}/settings`}>
            <Nav.Link>Settings</Nav.Link>
          </LinkContainer>
          <Nav.Link onClick={() => dispatch(handleLogout())}>Log out</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </NavbarBase>
  );
};
