import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { getToken } from '_utils/network';
import { connect } from 'react-redux';
import { getPendingCount, getUnauthorizedStatus } from '_redux/network';
import { wipeStore } from '_redux';

const LoginConfirmation = ({ match, location, pendingCount, unauthorizedAccess, wipeStore }) => {
  const currentPendingCount = useRef(pendingCount);
  const currentUnauthorizedStatus = useRef(unauthorizedAccess);
  const [loggedIn, setLoggedIn] = useState(getToken());

  const handleLogout = () => {
    setLoggedIn(false);
  };

  useEffect(() => {
    // If the user tries to navigate to a new page and the token
    // is expired, the user should be redirected to the login page
    if (!getToken()) {
      handleLogout();
    }
  }, [location]);

  useEffect(() => {
    // if there is a new pending action and the session has expired,
    // the user should be redirected to the login page
    if (currentPendingCount.current < pendingCount && !getToken()) {
      handleLogout();
    }
    currentPendingCount.current = pendingCount;
  }, [pendingCount]);

  useEffect(() => {
    // if there is an action that returns "Unauthorized", the
    // user should be redirected to the login page
    if (!currentUnauthorizedStatus.current && unauthorizedAccess) {
      currentUnauthorizedStatus.current = unauthorizedAccess;
    }

    if (currentUnauthorizedStatus.current) {
      handleLogout();
    }
  }, [currentUnauthorizedStatus, unauthorizedAccess]);

  if (!loggedIn) {
    wipeStore();
    return (
      <Redirect
        to={{
          pathname: `/${match.params.networkId}`,
          state: { referer: location },
        }}
      />
    );
  }

  return null;
};

export const ConnectedLoginConfirmation = connect(
  state => ({
    pendingCount: getPendingCount(state),
    unauthorizedAccess: getUnauthorizedStatus(state),
  }),
  { wipeStore },
)(LoginConfirmation);
