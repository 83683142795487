import { createSelector } from 'redux-starter-kit';
import { sspApi } from '_utils/network';
import { fetchUser } from '_redux/login';
import { NetworkKeys, NetworkStates } from './constants';
import {
  startPost,
  endRejected,
  endWithResponse,
  checkResponseForError,
  createNetworkSelectorFromKey,
} from '_redux/network';

interface RawUserSettingChanges {
  identityCertPassword?: string;
  identityCertFile?: File;
}

export const updateUserSettings = (rawChanges: RawUserSettingChanges) => async dispatch => {
  const body = new URLSearchParams();

  if (rawChanges.identityCertFile) {
    const { identityCertPassword, identityCertFile } = rawChanges;

    const fileData = await new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.toString());
      };
      reader.readAsDataURL(identityCertFile);
    });

    body.set('identity_cert_password', identityCertPassword);
    body.set('identity_cert_file_name', identityCertFile.name);
    // We want to filter out the unecessary/unreadable portion of the file data
    body.set('identity_cert_file_data', fileData.toString().split(',')[1]);
  }

  const key = NetworkKeys.UpdateUserSettings;
  dispatch(startPost(key, true));
  try {
    const response = await sspApi.post('update_user_settings', { body });

    const data = await response.clone().json();
    const { error, errorData } = checkResponseForError(data);

    if (!error) {
      dispatch(endWithResponse(key, response));
      dispatch(fetchUser());
    } else {
      dispatch(endWithResponse(key, response, errorData));
    }
  } catch (e) {
    dispatch(endRejected(key, e));
  }
};

// selectors
export const getUpdateUserSettingsInfo = createNetworkSelectorFromKey(
  NetworkKeys.UpdateUserSettings,
);

export const getUpdateUserSettingsError = createSelector(
  [getUpdateUserSettingsInfo],
  requestInfo => {
    return requestInfo && requestInfo.error ? requestInfo.error : '';
  },
);

export const getUpdateUserSettingsErrorExists = createSelector(
  [getUpdateUserSettingsInfo],
  requestInfo => requestInfo && requestInfo.state === NetworkStates.Resolved && !requestInfo.ok,
);
