import React from 'react';
import { HomePage } from './HomePage';
import { Switch, Route } from 'react-router-dom';

export const HomePageRoot = () => {
  return (
    <Switch>
      <Route exact path="/:networkId/:userId/homepage" component={HomePage} />
    </Switch>
  );
};
