import React from 'react';
import { useSelector } from 'react-redux';

import { getAppFromIdProp } from '_redux/apps';
import { getAppInstallInfo } from '_redux/devices';
import PlaceholderIcon from 'images/app_placeholder.svg';

import { AppInstallButtonWithModal, AppUninstallButtonWithModal } from './AppActionButton';

export const AppCard = ({ appId, deviceId, withActions = true }) => {
  const { name, icon, version } = useSelector(state => getAppFromIdProp(state, { id: appId }));
  const { installed_version, installed_at } = useSelector(state =>
    getAppInstallInfo(state, deviceId, appId),
  );

  const isInstalled = !!installed_at;

  return (
    <div className="d-flex flex-row my-4 mx-1" style={{ width: '20rem' }}>
      <AppIcon icon={icon} name={name} />
      <div className="pl-4">
        <h3>
          {name || 'Unnamed'} {installed_version}
        </h3>
        {version && <h4>{`Latest version: ${version}`}</h4>}
        {!isInstalled && <h4>Missing</h4>}
        {withActions && (
          <div className="mt-1">
            <AppInstallButtonWithModal
              appId={appId}
              deviceId={deviceId}
              isInstalled={isInstalled}
            />
            {isInstalled && (
              <AppUninstallButtonWithModal
                appId={appId}
                deviceId={deviceId}
                isInstalled={isInstalled}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const AppIcon = ({ icon, name }) => (
  <div
    style={{ width: '4rem', height: '4rem' }}
    className="d-flex align-items-center justify-content-center border rounded p-1"
  >
    <img src={icon || PlaceholderIcon} alt={name} style={{ width: '3rem' }} />
  </div>
);
