import { Redirect } from 'react-router';
import React from 'react';
import { DeviceTypes } from '_redux/constants';

export const EnrollPicker = ({ match }) => {
  // TODO: Enrollment type picker
  return (
    <Redirect
      push
      to={{
        pathname: `${match.url}/${DeviceTypes.TA}`,
        state: { skipFetch: true },
      }}
    />
  );
};
