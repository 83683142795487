import { createSlice, createSelector } from 'redux-starter-kit';
import { SliceNames, NetworkKeys } from './constants';
import { sspApi } from '_utils/network';
import {
  startGet,
  endRejected,
  endWithResponse,
  checkResponseForError,
  getNetworkRequestInfo,
} from './network';
import { useQuery } from 'react-query';
import { fetchSupportedFeatures } from 'portal-dashboard/_queries/fetchSupportedFeatures';

const initialState = {
  networkId: '',
  custom_settings: {},
};

const networkInfoSlice = createSlice({
  slice: SliceNames.NetworkInfo,
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    done: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});
export const {
  actions,
  reducer,
  selectors: { getNetworkInfo },
} = networkInfoSlice;

export const getNetworkId = createSelector([getNetworkInfo], networkInfo => networkInfo.networkId);

export const getAuthType = createSelector([getNetworkInfo], networkInfo => networkInfo.auth_type);

export const getAuthEndpoint = createSelector(
  [getNetworkInfo],
  networkInfo => networkInfo.auth_endpoint,
);

export const getCustomSettings = createSelector(
  [getNetworkInfo],
  networkInfo => networkInfo.custom_settings,
);

export const getCustomColor = createSelector(
  [getCustomSettings],
  customSettings => customSettings.custom_color,
);

export const getCustomLogoUrl = createSelector(
  [getCustomSettings],
  customSettings => customSettings.custom_logo_url,
);

export const getCustomLogoAltText = createSelector(
  [getCustomSettings],
  customSettings => customSettings.custom_logo_alt_text,
);

export const getGoogleMapApiKey = createSelector(
  [getNetworkInfo],
  networkInfo => networkInfo.google_maps_api_key,
);

export const getGooglePremierClientId = createSelector(
  [getNetworkInfo],
  networkInfo => networkInfo.google_map_premier_id,
);

// network request related selectors
export const getNetworkInfoErrorStatus = createSelector([getNetworkRequestInfo], networkInfo => {
  if (networkInfo && networkInfo.success === false) {
    // If success is false, return true to indicate an error
    return !networkInfo.success;
  } else if (networkInfo && networkInfo.ok !== undefined) {
    // If status is not ok, return true to indicate an error
    return !networkInfo.ok;
  } else if (networkInfo && networkInfo.error) {
    return true;
  }

  return false;
});

export const getProvidedNetworkError = createSelector([getNetworkRequestInfo], networkInfo => {
  if (networkInfo && networkInfo.error) {
    return networkInfo.error;
  }
  return null;
});

export const getWindowsTrustedAccessEnabled = createSelector(
  [getNetworkInfo],
  networkInfo => networkInfo?.windows_trusted_access_enabled,
);

const { reset, done } = actions;
export const fetchNetworkRequirements = rawNetworkId => async dispatch => {
  const key = NetworkKeys.FetchNetwork;
  dispatch({ ...startGet(key, true), ...reset() });
  try {
    const networkId = hyphenateNetworkId(rawNetworkId);
    const response = await sspApi.get(`${networkId}/get_ssp_auth_requirements`, {
      headers: {
        accept: 'application/json',
      },
    });

    const data = await response.json();
    const { error, errorData } = checkResponseForError(data);
    const defaultAction = endWithResponse(key, response, errorData);

    if (!error) {
      dispatch({ ...defaultAction, ...done({ ...data, networkId: networkId }) });
    } else {
      dispatch({ ...defaultAction, ...done({ networkId: networkId }) });
    }
  } catch (e) {
    dispatch(endRejected(key, e));
  }
};

export const useSupportedFeaturesQuery = (features: string[], domain: string) => {
  return useQuery(['supportedFeatures', domain, ...features], () =>
    fetchSupportedFeatures(features, domain),
  );
};

export const updateCustomSettings = settings => async dispatch => {
  dispatch({ ...done({ custom_settings: settings }) });
};

export const fetchGoogleApiKey = () => async dispatch => {
  const key = NetworkKeys.FetchGoogleApiKey;
  dispatch({ ...startGet(key, false) });

  try {
    const response = await sspApi.get('get_google_api_key');

    const data = await response.clone().json();
    const { error, errorData } = checkResponseForError(data);
    const defaultAction = endWithResponse(key, response, errorData);

    if (!error) {
      dispatch({ ...defaultAction, ...done({ ...data }) });
    } else {
      dispatch(defaultAction);
    }
  } catch (e) {
    dispatch(endRejected(key, e));
  }
};

// Converts a 10 digit network ID to it's hyphenated form if necessary
export const hyphenateNetworkId = rawNetworkId => {
  return /^\d{10}$/.test(rawNetworkId)
    ? rawNetworkId.slice(0, 3) + '-' + rawNetworkId.slice(3, 6) + '-' + rawNetworkId.slice(6, 11)
    : rawNetworkId;
};

export default reducer;
