import { sspApi } from '_utils/network';

export function fetchSupportedFeatures(features: string[], domain: string): Promise<Object> {
  const params = new URLSearchParams({
    features: JSON.stringify(features),
  });
  const url = `${domain}/supported_features?${params.toString()}`;

  return sspApi.get(url).then(res => {
    if (res.status !== 200) throw new Error('failed');
    return res.json();
  });
}
