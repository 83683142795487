import React, { useEffect } from 'react';
import { LoadingSpinnerFullscreen } from '_components/loadingSpinner/LoadingSpinner';

export function WaitWithSpinner({
  asyncFn,
  onSuccess,
  onFail,
}: {
  asyncFn: () => Promise<any>;
  onSuccess: (any) => void;
  onFail: (Error) => void;
}) {
  useEffect(() => {
    async function wait() {
      try {
        onSuccess(await asyncFn());
      } catch (e) {
        console.error(e);
        onFail(e);
      }
    }
    wait();
  }, [asyncFn, onSuccess, onFail]);
  return <LoadingSpinnerFullscreen show />;
}
