export enum SsidType {
  PskGroup = 'psk_group',
}

export interface PskGroup {
  passphrase: string;
  id: string;
  updated_at: string;
}

export interface Ssid {
  id: string;
  name: string;
  type: SsidType | null;
  psk_group?: PskGroup;
}
