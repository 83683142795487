import * as Yup from 'yup';

// Trusted Access Devices
export const TrustedAccessDeviceName = Yup.string()
  .min(1, 'Device name cannot be empty')
  .required('Device name cannot be empty');

// AD / Meraki Login
export const FormEmail = Yup.string()
  .email('Enter a valid email address')
  .required('Enter an email address');

export const LoginFormUsername = Yup.string().required('Enter a username');

export const FormPassword = Yup.string().required('Enter a password');

export const FormNetworkId = Yup.string().required('Enter a network ID');
