import React from 'react';
import { Table } from 'react-bootstrap';

export const DeviceInfoTableRow = ({ label, children }) => (
  <tr>
    <td className="pl-0">
      <h4 className="mb-0">{label}</h4>
    </td>
    <td>{children}</td>
  </tr>
);

export const DeviceInfoTable = ({ header = null, children }) => (
  <div>
    {header && <h3>{header}</h3>}
    <Table>
      <tbody>{children}</tbody>
    </Table>
  </div>
);
