import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';

export const NetworkIdForm = ({
  onSubmit,
  initialNetworkId = '',
  submitButtonLabel = 'Submit',
}: {
  onSubmit: (networkId: string) => void;
  initialNetworkId?: string;
  submitButtonLabel?: string;
}) => {
  const [networkId, setNetworkId] = useState(initialNetworkId);

  return (
    <Form
      onSubmit={event => {
        event.preventDefault();
        onSubmit(networkId);
      }}
    >
      <Form.Control
        type="text"
        name="networkId"
        value={networkId}
        onChange={event => {
          setNetworkId(event.target.value.trim());
        }}
        data-testid="network-id"
      />
      <Button
        block
        className="mt-3"
        variant="primary"
        disabled={networkId === ''}
        type="submit"
        data-testid="submit-network-id"
      >
        {submitButtonLabel}
      </Button>
    </Form>
  );
};
