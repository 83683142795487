import React from 'react';
import { ToolButtonWithConfirmationModal } from '../_components/ToolButton';
import { NetworkKeys } from '_redux/constants';

import wipe_tool from 'images/tools/wipe_tool.svg';

export const Wipe = ({ showDescription = false }) => (
  <ToolButtonWithConfirmationModal
    toolKey={NetworkKeys.Wipe}
    title="Erase All Content"
    description="Delete all content and settings from this device"
    showDescription={showDescription}
    svgIcon={wipe_tool}
    successTitle="Device Wiped"
    helperDescription="This will wipe your entire device"
    buttonText="Wipe"
  />
);
