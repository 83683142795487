export const TB_STATIC = 'static';
export const TB_DYNAMIC = 'dynamic';

export interface TrustedAccessConnection {
  downloaded_at: string;
  id: string;
  last_connected_at: number;
  scep_completed_at?: number;
  trusted_access_config_id: string;
}

export interface TrustedAccessConfig {
  id: string;
  pcc_mobile_config_id: string;
  ssid_name: string;
  payload_display_name: string;
  timebound_type: typeof TB_STATIC | typeof TB_DYNAMIC;
  timebound_period: number | null;
  access_start_date: string | null;
  access_end_date: string | null;
}
